import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { riskCategory } from "./options";
import { getDataToChart } from "../../../helpers/helper";
// import { useGetObjectRatingQuery } from "../../../middleware/analyticsApi"; /* don't remove this - will need later */
import { useAppSelector } from "../../../hooks/redux";
import { PATHS, ROLES, globalFields, ROLE_TYPES } from "../../../constants";
import { useAction } from "../../../hooks/actions";

import Responsible from "../../components/MapPopUp/Responsible";
import DonutChart from "../../components/DonutChart";
import TaskStateList from "../../components/TaskStateList/TaskStateList";
import RiskCategoryButton from "../../components/ToggleButtonsGroup/RiskCategoryButton";
// import { RatingObjectBlock } from "./RatingObjectBlock"; /* don't remove this - will need later */

export const HoverPopUp = ({ data, width, height, radius, thickness }) => {

  const taskstates = data?.filter((task) => task.name != "totalJobsAmount" && task.name != "total_jobs_amount")
  const text = data?.filter((task) => task.name == "totalJobsAmount" || task.name == "total_jobs_amount")[0]?.value

  return (
    <Box className="donut">
      <DonutChart
        data={taskstates || []}
        width={width}
        height={height}
        radius={radius}
        thickness={thickness}
        isText={text || "0"}
        fontSize={"32px"}
      />
      <TaskStateList height="12px" width="12px" marginLeft="34px" data={taskstates || []} />
    </Box>
  );
};
export const HoverPopUpWithoutTaskList = ({ data, width, height, radius, thickness, style }) => {

  const taskstates = data?.filter((task) => task.name != "totalJobsAmount" && task.name != "total_jobs_amount")

  const text = data?.filter((task) => task.name == "totalJobsAmount" || task.name == "total_jobs_amount")[0]?.value

  return (

    <DonutChart
      data={taskstates || []}
      width={width}
      height={height}
      radius={radius}
      style={style}
      thickness={thickness}
      isText={text || "0"}
      fontSize={"12px"}
    />

  );
};
const ObjectDetails = ({ data, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const { id } = data || {}; /* don't remove this - will need later */
  const { roleId } = useAppSelector((state) => state.users.info);
  const roleUser = ROLES[roleId];
  // const { data: objectRating } = useGetObjectRatingQuery(id, { skip: !id }); /* don't remove this - will need later */
  const { setSortParamsFacilityName } = useAction();

  const { riskCategoryOption, protectionObjectSystemsData, jobChart, name, id } =
    useMemo(() => {
      if (!data) {
        return {
          riskCategoryOption: null,
          protectionObjectSystemsData: null,
        };
      }
      const {
        autoFireAlarmSysAvailable,
        autoFireSuppressionSysAvailable,
        notifAndEvacControlSysAvailable,
      } = data?.protectionObjectSystems;

      const hasProtectionObjectSystemsItems = !![
        autoFireAlarmSysAvailable,
        autoFireAlarmSysAvailable,
        notifAndEvacControlSysAvailable,
      ]?.filter((item) => item)?.length;
      return {
        riskCategoryOption: riskCategory.find(
          ({ value }) => value === data?.riskCategory
        ),
        protectionObjectSystemsData: hasProtectionObjectSystemsItems
          ? {
            autoFireAlarmSysAvailable,
            autoFireSuppressionSysAvailable,
            notifAndEvacControlSysAvailable,
          }
          : null,
        jobChart: data?.jobChart,
        name: data?.name,
      };
    }, [data]);

  return (
    <>
      <Typography className="pop_up_header">
        {data?.integrationNumber} {data?.name}
      </Typography>
      <IconButton className="close_icon" onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box className="main-content_wrapper">
        <Box>
          
          <Box className="button_wrapper" sx={{ width: "180px" }}>
            {roleUser !== ROLE_TYPES.DIGITIZER &&
              roleUser !== ROLE_TYPES.EMPLOYEE && (
                <Button
                  className="btn btn-link-border"
                  sx={{ width: "100%" }}
                  onClick={() => navigate(PATHS.GET_EDIT_OBJECT(data?.id))}
                >
                  {t("button:edit_object")}
                </Button>
              )}
          </Box>
          <Box className="button_wrapper" sx={{ width: "180px"}}>
            <Button
              className="btn btn-link-border"
              sx={{ width: "100%" }}
              onClick={() => navigate(PATHS.GET_EDIT_OBJECT(data?.id) + "?readOnly=true")}
            >
              {t("button:see_object")}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mr: "10px" }}>
          <Box className="box">
            <img
              className="img"
              src={data?.facilityPhotoFileLink || "/unnamed.png"}
              alt={data?.facilityPhotoFileLink || "unnamed"}
            />
          </Box>
          <Box sx={{ display: "flex", marginTop: "24px" }}>
            <Typography sx={{ margin: 0 }} className="data_text">
              {t("label:mainFunctionalPurpose")}: {data?.mainFunctionalPurpose}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              marginTop: "12px",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <Typography sx={{ m: 0 }} className="data_text">
              {t("label:riskCategory")}:
            </Typography>
            {data?.riskCategory && (
              <RiskCategoryButton {...riskCategoryOption} />
            )}
          </Box>
          <HoverPopUp
            data={getDataToChart({
              jobChart,
              action: `?facilityName=${data?.id}`,
            })}
            width={104}
            height={104}
            radius={52}
            thickness={10}
          />
        </Box>
      </Box>

      {/* don't remove this - will need later */}
      {/* {!!objectRating && (
        <RatingObjectBlock isPopUp={true} data={objectRating} />
      )} */}
      {data?.customerOrganizations && (
        <Box sx={{ mt: "28px" }}>
          <Typography className="data_small_headers">
            {t("label:customer")}
          </Typography>
          {data?.customerOrganizations.map(
            ({ jobChart, ...organization }, i) => (
              <Accordion
                key={i}
                sx={{
                  boxShadow: "none",
                  padding: 0,
                }}
              >

                <AccordionSummary
                  style={{ padding: "0" }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography style={{ padding: "0", alignSelf: "center" }}>
                      <span>
                        {organization?.name}: {organization?.systems}
                      </span>
                    </Typography>
                    <HoverPopUpWithoutTaskList
                      data={getDataToChart({
                        jobChart,
                        action: setSortParamsFacilityName.bind(null, name),
                      })}
                      width={36}
                      style={{
                        marginRight: "10px"
                      }}
                      height={36}
                      radius={18}
                      thickness={3}

                    />
                  </Box>
                </AccordionSummary>

                <AccordionDetails style={{ padding: "0" }}>
                  <Responsible data={organization?.contactPersons} />
                </AccordionDetails>
              </Accordion>
            )
          )}
        </Box>
      )}
      {data?.responsibleOrganizationContactPersons && (
        <Box sx={{ mt: "28px" }}>
          <Typography className="data_small_headers">
            {t("label:performers")}
          </Typography>
          {data?.responsibleOrganizationContactPersons.map(
            ({ jobChart, ...organization }, i) => (
              <Accordion
                key={i}
                sx={{
                  boxShadow: "none",
                  padding: 0,
                }}
              >

                <AccordionSummary
                  style={{ padding: "0" }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography style={{ padding: "0", alignSelf: "center" }}>
                      <span>
                        {organization?.name}: {organization?.systems}
                      </span>
                    </Typography>
                    <HoverPopUpWithoutTaskList
                      data={getDataToChart({
                        jobChart,
                        action: setSortParamsFacilityName.bind(null, name),
                      })}
                      width={36}
                      style={{
                        marginRight: "10px"
                      }}
                      height={36}
                      radius={18}
                      thickness={3}

                    />
                  </Box>
                </AccordionSummary>

                <AccordionDetails style={{ padding: "0" }}>
                  <Responsible data={organization?.contactPersons} />
                </AccordionDetails>
              </Accordion>
            )
          )}
        </Box>
      )}

      <Box>
        {data?.protectionObjectSystems && protectionObjectSystemsData && (
          <Box sx={{ mt: "28px" }}>
            <Typography className="data_small_headers">
              {t("label:facilitySystems")}
            </Typography>
            {protectionObjectSystemsData?.autoFireAlarmSysAvailable && (
              <Typography className="data_text">{t("title:SNME")}</Typography>
            )}
            {protectionObjectSystemsData?.autoFireSuppressionSysAvailable && (
              <Typography className="data_text">{t("title:AFA")}</Typography>
            )}
            {protectionObjectSystemsData?.notifAndEvacControlSysAvailable && (
              <Typography className="data_text">{t("title:AFSS")}</Typography>
            )}
          </Box>
        )}
      </Box>
      {/*<Box sx={{ mt: "28px" }}>
        <Typography className="data_small_headers">
          {t("label:powerSupply")}
        </Typography>
        <Typography sx={{ m: "24px 0" }} className="data_text">
          {t(
            `label:${data?.protectionObjectSystems?.electricityAvailable
              ? "has"
              : "doesntHave"
            }`
          )}
        </Typography>
      </Box>
      <Box sx={{ mt: "28px" }}>
        <Typography className="data_small_headers">{t("label:gas")}</Typography>
        <Typography sx={{ m: "24px 0" }} className="data_text">
          {t(
            `label:${data?.protectionObjectSystems?.gasAvailable ? "has" : "doesntHave"
            }`
          )}
        </Typography>
          </Box>*/}
    </>
  );
};

export default ObjectDetails;
