import React, { useEffect, useState,Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  ListItemButton,
  FormControl,
  Select,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";

import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectGroupingSystemPPZ = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  defaultValue = 0,
  bgcolor,
  height = "",
  setValue,
  isSystemPPZ = false,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(defaultValue === 0 ? value : defaultValue);

  useEffect(() => {
    setUpdatedOptions(options);
    console.log("changeValue")
    if (options?.length) {
      if (Number(value)) {

        const selectedItem = options
          ?.reduce((sum, { items }) => [...sum, ...items], [])
          ?.find(({ id }) => id === value);

        setSelectedValue(JSON.stringify(selectedItem) || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      } else {
        console.log("valuee", value)
        setSelectedValue(value || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      }
    }
  }, [options, value, defaultValue]);

  const handleClick = (id) => {
    const updatedOptions = options?.length && options.map((option) => ({
      ...option,
      open: !option.open && option.id === id
    }));
    console.log("updatedOptions", updatedOptions)
    setUpdatedOptions(updatedOptions);
  };
  return (
    <FormControl fullWidth error={!!error && !disabled} sx={{ background: bgcolor ? bgcolor : "inherit", height: height }} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={selectedValue}
        renderValue={(selected) => {
          console.log("selectedEquip1", selected)
          return  <Tooltip title={selected == 0 || selected == "все" ? "все" : selected ? JSON.parse(selected).name : ""}><Box sx={{textOverflow: "ellipsis",width:"170px",    whiteSpace: "nowrap",overflow: "hidden"}}>{selected == 0 || selected == "все" ? "все" : selected ? JSON.parse(selected).name : ""}</Box></Tooltip>;
        }}
        disabled={disabled}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        onClose={() => setSearchValue("")}
        sx={{
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            height: 0,
            color: "var(--additionalGrey)",
            
            fontSize:"14px"
          },
          maxHeight: "56px",
        }}
        {...rest}
      >
        <MyListSubheaderSearch
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
        {updatedOptions?.length ? (
          updatedOptions.map((option) => {
            const { open, items } = option || {};

            if (!items) {
              const { id, name } = option;
              return (
                <MenuItem key={id} value={id}>
                  <ListItemText primary={name || ""} />
                </MenuItem>
              );
            }
            if (items && items.length == 1) {
              const { id, name } = option;
              return (
                <MenuItem

                  key={id} value={JSON.stringify({ id: items[0]?.id, name: `${name}, ${items[0]?.name}` }) || ""}>
                  <ListItemText sx={{
                    paddingLeft: "10px",
                  }} primary={`${name}, ${items[0]?.name}` || ""} />
                </MenuItem>
              );
            }

            const Items = items?.length
              ? items.map((item) => {
                const { id, name } = item;
                return (

                  <MenuItem
                    key={id}
                    sx={{
                      display: `${open ? "block" : "none"}`,
                      paddingLeft: "70px",
                    }}
                    value={JSON.stringify({ ...item, id: id, name: `${option.name}, ${name}` }) || ""}
                  >
                    <Typography>
                      {`${name}`}
                    </Typography>

                  </MenuItem>
                );
              })
              : null;

            return [

                <MenuItem
                  key={option.id}
                  sx={{  verticalAlign: "super !important" }}
                  value={JSON.stringify({ id: option.id, name: `${option.name}`, isType: true }) || ""}
                >
                  <MyListSubheader
                  key="MyListSubheader"
                  setOptions={setUpdatedOptions}
                  options={updatedOptions}
                  isSystemPPZ={isSystemPPZ}
                  setSelectedValue={setSelectedValue}
                  itemOption={option}
                /> 
                  <ListItemText primary={option.name} />
                </MenuItem>
              ,
              Items,
            ];
          })
        ) : (
          <MenuItem disabled={true}>
            <ListItemText primary={t("label:nothingFound")} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectGroupingSystemPPZ;
