import { Box } from "@mui/material";
import React from "react";
import FormItem from "../../components/FormItem";

export const Main = ({ control, errors, config }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {config.map(({ id, name, label, input, ...rest }) => (
          <FormItem
            key={id}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
};
