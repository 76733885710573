import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const InputTextarea = ({
  label,
  type,
  disabled = false,
  InputProps,
  onChange,
  error,
  value,
  firstUpperCase,
  variant,
  step,
  negativeAvailable,
  setValue,
  rows,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      multiline
      rows={rows ? rows : 5}
      maxRows={10}
      type={type === "number" ? "text" : type}
      onChange={onChange}
      label={t(label)}
      variant={variant || "outlined"}
      error={!!error}
      helperText={error?.message ? t(`error_message:${error?.message}`) : ""}
      fullWidth
      value={value}
      
      disabled={disabled}
      InputProps={InputProps ? InputProps : {}}
      sx={{ textarea: { textAlign: "justify" } }}
      {...rest}
    />
  );
};

export default InputTextarea;
