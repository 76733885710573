import React, { useState } from "react";
/* import { useTranslation } from "react-i18next"; */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import telImg from "../../../image/tel.png";
import mailImg from "../../../image/mail.png";
export const Contacts = ({ open, setOpen }) => {
  /*   const { t } = useTranslation(); */
  const [editMode, setEditMode] = useState(false);
  const [phone, setPhone] = useState('+7 977 6644207');
  const [email, setEmail] = useState('sa@vdpopro.agency');
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="details"
      maxWidth="lg"
      fullWidth
      sx={{ "& .MuiDialog-paper": { height: "500px" } }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid var(--borderLightGrey)",
          textAlign: "center",
          backgroundColor: "#F8FAFC",
        }}
        className="details-header"
      >
        <Typography variant="h6" className="title-block">
         
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="details-body">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
            minHeight: "300px",
          }}
        >

          <Typography variant="h2" style={{ marginBottom: "16px",textAlign:"center", fontSize: 25, fontWeight: "bold" }}>
          Контакты
          </Typography>
          <Box sx={{width:"300px"}}>
            <Box
              component="img"
              src={telImg}
            />
            <Link href={`tel:${phone}`} variant="subtitle1" style={{color: "rgba(0, 0, 0, 0.87)",fontWeight: 600,lineHeight: "66px",verticalAlign: "text-bottom",marginLeft: "20px",fontSize: 20}}>
              {phone}
            </Link>
          </Box>
          <Box sx={{width:"300px"}}>
            <Box
              component="img"
              src={mailImg}
            />
            <Link href={`mailto:${email}`} variant="subtitle1" style={{color: "rgba(0, 0, 0, 0.87)",fontWeight: 600,lineHeight: "66px",verticalAlign: "text-bottom",marginLeft: "20px",fontSize: 20}}>
              {email}
            </Link>
          </Box>

          <Box sx={{border: "4px solid var(--main)",borderRadius: "20px",padding: "3px"}}>
            <Box sx={{border: "3px solid white",borderRadius: "20px",backgroundColor:"var(--main)",padding: "15px"}}>
              <QRCodeSVG  bgColor="var(--main)" fgColor="white" value={"Тел: +7(988) 777 77 77 \n Email: sa@vdpopro.agency"} />
            </Box>
            
          </Box>
          
        </Box>
      </DialogContent>
    </Dialog>
  );
};
