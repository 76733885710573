import React from "react";

const ArrowCancelBack = ({ isActive }) => {
  return (
    <svg
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4749 3.25C5.01657 1.90833 7.01657 1.08333 9.2249 1.08333C13.0999 1.08333 16.3749 3.60833 17.5249 7.1L15.5582 7.75C14.6832 5.09167 12.1832 3.16667 9.2249 3.16667C7.5999 3.16667 6.11657 3.76667 4.95824 4.73333L7.9749 7.75H0.474904V0.25L3.4749 3.25Z"
        fill={isActive ? "var(--main)" : "var(--lightGreyText)"}
      />
    </svg>
  );
};

export default ArrowCancelBack;
