import React, { useEffect, useState } from "react";
import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Header from "./Header";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,Backdrop, CircularProgress 
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputBlockField from "../../../components/InputBlockField";
import { formFields } from "../../FireCompartments/form-config";
import FormItemCheckboxControlled from "../../../components/FormItem/FormItemCheckboxControlled";
import { WarningValidationForm } from "../../../components/Notifications";
import {
  useLazyGetEquipmentSelectElementsByFacilityIdQuery,
  useLazyGetEquipmentSelectElementsByFacilityIdSNNMEQuery
} from "../../../../middleware/equipmentsApi";

import { useAppSelector } from "../../../../hooks/redux";
import {
  useLazyActexistsQuery,
} from "../../../../middleware/documentationApi";
import * as afaFields from "../CreateAfaAct/form-config";
import { useAddAFASystemActMutation,useAddSNMESystemActMutation } from "../../../../middleware/documentationApi";
import { useAction } from "../../../../hooks/actions";
const Form = ({ actType, formTitle, formId, formConfig }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeActExist } = useAction();
  
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    register,
    reset,
    unregister,
    formState: { errors },
    watch
  } = useForm({
    mode: "onChange",
  });
  const [isActExist, setisActExist] = useState()
  const [plumeEquip, setplumeEquip] = useState([])
  const [plumeequipexist, setplumeEquipExist] = useState([])
  const [isAct, { isError: isErrorisAct, isLoading: isLoadingisAct }] =
    useLazyActexistsQuery();
  const [getEquipmentSelectElementsByFacilityId, { isError: isEquipmentSelectElements, isLoading: isLoadingEquipmentSelectElements }] =
    useLazyGetEquipmentSelectElementsByFacilityIdQuery();
  const [getEquipmentSelectElementsByFacilityIdSNNME, { isError: isEquipmentSelectElementsSNNME, isLoading: isLoadingEquipmentSelectElementsSNNME }] =
    useLazyGetEquipmentSelectElementsByFacilityIdSNNMEQuery();



  const [addAFASystem, { isSuccess, error, isLoading }] = useAddAFASystemActMutation();
  const [addSNMESystem, { isSuccess:isSuccessSNME, errorSNME, isLoading:isLoadingSNME }] = useAddSNMESystemActMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  useEffect(() => {
    setIsErrorSubmit(error || errorSNME || isEquipmentSelectElements || isErrorisAct || isEquipmentSelectElementsSNNME );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error,errorSNME,isEquipmentSelectElements,isErrorisAct,isEquipmentSelectElementsSNNME]);


  useEffect(() => {
    setIsSuccessSubmit(isSuccess || isSuccessSNME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess,isSuccessSNME]);

  const selectedFacilityId = watch(afaFields.formFields.protectionObjectId);



  useEffect(() => {
    if (selectedFacilityId) {
      setplumeEquipExist([])
      setplumeEquip([])
      isAct({ protectionObjectId: selectedFacilityId, actType: actType }).then((res) => {
        setisActExist(res.data)
        
          actType == 0 ?
            getEquipmentSelectElementsByFacilityId({ searchValue: "", facilityId: selectedFacilityId || "" }).then((ress) => {
             
              setplumeEquip(ress.data)  })
            :
            getEquipmentSelectElementsByFacilityIdSNNME({ searchValue: "", facilityId: selectedFacilityId || "" }).then((ress) => {
             
              setplumeEquip(ress.data)  })
        
   
      })

    }


  }, [selectedFacilityId, isAct,setplumeEquip, getEquipmentSelectElementsByFacilityId, getEquipmentSelectElementsByFacilityIdSNNME]);

  const isWarningValidation = !!Object.keys(errors).length;
  //const { errorFace } = useGetEquipmentSelectElementsByFacilityIdQuery();
  const handleCancel = () => {

    navigate(PATHS.ACTS);
  };

  const onSubmit = (data) => {
   
    Object.keys(data.performanceTests).forEach(key => {
      let innerObject = data.performanceTests[key];
      let addd=[]
      
      if(innerObject?.equipments?.length>0)
          innerObject.equipments.map((itemmm)=>{
            let factVal = {}
            if(itemmm?.factValue)
              factVal = parseFloat(itemmm?.factValue)
            console.log("factVal",itemmm)
            addd.push({...itemmm,...factVal,workingCorrectly:itemmm.workingCorrectly == "true" ? true : false,corresponds:itemmm.corresponds=="true" ? true :false })
        })
        innerObject.equipments =addd
      
    });
    
  actType == 0 ?
    addAFASystem(data)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error?.data?.title);
        }
        navigate(PATHS.ACTS);
      })
      .catch((e) => {
        console.log(e);
      })
      :
      addSNMESystem(data)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error?.data?.title);
        }
        navigate(PATHS.ACTS);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Box className="acts-container">
    <>
      <Header
        title={formTitle}
        formId={formId}
        isSubmitDisabled={false}
        handleCancel={handleCancel}
      />
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        {formConfig?.map(({ id, name: sectionName, label, items }) => (
          <Accordion
            key={id}
            square={true}
            disableGutters={true}
            sx={{ background: "var(--bgLightGrey)" }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                flexDirection: "row-reverse",
                svg: { color: "var(--black)" },
              }}
            >
              <Typography className="form-section-label">
                {t(label)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "var(--bgLightGrey)" }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "97%",
                  }}
                >
                  {items.map(
                    ({
                      id,
                      name,
                      label,
                      input,
                      expended,
                      fieldArrayItem,
                      CustomFormItem,
                      block,
                      title,
                      ...rest
                    }) =>
                      CustomFormItem ? (
                        <CustomFormItem
                          key={
                            sectionName
                              ? `${id}.${sectionName}.${name}`
                              : `${id}.${name}`
                          }
                          control={control}
                          errors={errors}
                          isActExist={isActExist}
                          name={sectionName ? `${sectionName}.${name}` : name}
                          setValue={setValue}
                          plumeequip={plumeEquip || []}
                          plumeequipexist={plumeequipexist || []}
                      
                          getValues={getValues}

                          {...rest}
                        />
                      ) : block ? (
                        <InputBlockField
                          key={sectionName ? `${sectionName}.${name}` : name}
                          control={control}
                          errors={errors}
                          name={name}
                          label={label}
                          input={input}
                          register={register}
                          unregister={unregister}
                          setValue={setValue}
                          getValues={getValues}
                          expended={expended}
                          {...rest}
                          title={title}
                          sectionName={sectionName}
                          formFields={formFields}
                          {...rest}
                        />
                      ) : expended ? (
                        <FormItemCheckboxControlled
                          key={sectionName ? `${sectionName}.${name}` : name}
                          control={control}
                          errors={errors}
                          name={name}
                          label={label}
                          input={input}
                          register={register}
                          unregister={unregister}
                          setValue={setValue}
                          getValues={getValues}
                          expended={expended}
                          {...rest}
                        />
                      ) : (
                        <FormItem
                          key={sectionName ? `${sectionName}.${name}` : name}
                          control={control}
                          register={register}
                          errors={errors}
                          name={sectionName ? `${sectionName}.${name}` : name}
                          label={label}
                          input={input}
                          setValue={setValue}
                          {...rest}
                        />
                      )
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </form>
      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </>
     <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoadingSNME || isLoading || isLoadingisAct || isLoadingEquipmentSelectElements || isLoadingEquipmentSelectElementsSNNME }>
     <CircularProgress color="inherit" />
   </Backdrop>
 </Box>
  );
};

export default Form;
