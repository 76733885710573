import React from "react";

const LogoIconSm = () => {
    return (
        <svg viewBox="0 0 300 524" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 449.348C0.0978274 393.663 0.0733699 337.99 0.0733699 282.305V282.28V116.154C0.0815222 113.266 1.5041 111.822 4.34109 111.822C30.0086 111.822 55.6883 111.858 81.368 111.761C84.1194 111.748 85.1221 112.421 85.0243 115.322L84.9631 134.904C84.9631 137.816 83.5528 139.273 80.7321 139.273C64.2237 139.273 47.7153 139.334 31.2069 139.212C28.3455 139.199 27.4039 139.872 27.4039 142.908C27.4773 239.384 27.4773 335.848 27.4161 432.324C27.4161 434.809 28.1621 436.057 30.4488 437.134C56.691 449.495 82.8721 461.966 109.065 474.425C121.93 480.544 134.757 486.737 147.683 492.734C149.016 493.358 151.131 493.358 152.464 492.734C169.437 484.84 186.349 476.812 203.249 468.759C225.566 458.123 247.858 447.415 270.212 436.84C272.131 435.935 272.755 434.821 272.669 432.875C272.584 430.941 272.657 428.996 272.657 427.062V144.682C272.657 138.343 272.963 139.334 267.228 139.309C254.278 139.26 241.34 139.224 228.403 139.358C225.651 139.383 224.942 138.502 225.003 135.858L225.015 114.747C224.966 112.434 225.761 111.797 228.011 111.797C250.927 111.871 273.856 111.871 296.784 111.797C299.181 111.785 300.196 112.519 299.914 114.918C299.853 115.518 299.914 116.142 299.914 116.754C299.914 227.305 299.89 337.843 300 448.394C300 452.053 298.765 453.705 295.561 455.198C277.585 463.569 259.72 472.222 241.805 480.74C228.023 487.288 214.205 493.786 200.412 500.322C184.417 507.91 168.447 515.559 152.403 523.036C151.07 523.648 148.954 523.661 147.634 523.036C129.572 514.58 111.597 505.964 93.5842 497.397C73.6979 487.94 53.8115 478.495 33.9252 469.05L33.7505 468.967C23.6375 464.169 13.5491 459.31 3.38727 454.623C0.966045 453.509 0 452.175 0 449.348Z"/>
            <path d="M140.492 462.162C139.502 462.639 138.462 463.019 136.946 463.643L136.934 463.655C136.604 459.8 136.31 456.471 136.053 453.154C134.28 429.901 132.556 406.636 130.722 383.383C129.12 363.177 127.335 342.995 125.733 322.79C124.62 308.776 123.739 294.739 122.627 280.726C121.282 263.665 119.777 246.629 118.408 229.569C117.968 223.988 117.637 218.383 117.515 212.79C117.491 211.664 118.359 209.914 119.301 209.473C140.162 199.56 161.085 189.793 182.583 179.721C182.583 179.721 180.382 214.564 179.232 230.107C178.119 245.246 176.896 260.373 175.796 275.512C175.013 286.282 174.365 297.064 173.607 307.834C172.775 319.62 171.883 331.405 171.002 343.191C170.122 354.977 169.217 366.75 168.349 378.536C167.212 394.076 166.087 409.616 164.962 425.156L164.961 425.165C164.387 433.083 163.775 441.014 163.347 448.944C163.25 450.817 162.699 451.784 160.926 452.579C154.066 455.651 147.304 458.956 140.492 462.162Z"/>
            <path d="M161.195 0.512239C161.194 0.515537 161.193 0.518836 161.192 0.522134C171.349 26.0563 177.955 51.0524 161.281 76.1953C170.88 75.8404 183.598 60.3832 185.664 45.905C186.386 48.316 186.948 50.0294 187.413 51.7795C187.89 53.5418 188.354 55.3286 188.672 57.1277C189.003 59.0369 189.247 60.9584 189.394 62.892C190.421 76.5135 187.425 89.0213 178.816 99.8524C177.606 101.382 175.881 102.692 174.121 103.548C166.441 107.293 158.627 110.757 150.96 114.526C148.221 115.873 146.081 115.53 143.708 113.804C136.885 108.836 134.77 101.994 135.467 93.9535C135.54 93.1579 135.699 92.3747 135.833 91.5057C128.264 94.3084 126.809 108.933 132.776 122.824C128.875 124.623 124.987 126.52 120.988 128.172C120.34 128.442 119.032 127.854 118.383 127.267C104.92 115.273 101.044 94.186 108.662 77.8598C114.079 66.2454 122.455 57.091 131.125 48.01C140.982 37.6807 150.361 26.9108 156.499 13.84C158.381 9.84066 159.482 5.47466 161.192 0.522134C161.189 0.514756 161.186 0.507378 161.183 0.5L161.195 0.512239Z"/>
            <path d="M204.863 133.863C205.756 133.435 204.704 133.856 206.281 133.33V155.171C206.184 155.991 204.679 156.847 203.652 157.325C186.997 165.145 170.33 172.892 153.662 180.676C134.415 189.659 115.192 198.654 95.9442 207.637L92.0312 209.473V187.652C91.9945 187.04 93.1073 186.134 93.8776 185.767L154.09 157.655L204.863 133.863Z"/>
            <path d="M191.974 108.407C192.855 107.991 193.967 107.587 193.967 107.587L194.077 122.359C194.077 126.557 192.414 128.234 189.051 129.776L104.957 168.523V150.116C104.957 149.198 106.424 148.39 107.415 147.925C118.542 142.663 129.695 137.486 140.847 132.285L191.974 108.407Z"/>
        </svg>
    );
};

export default LogoIconSm;
