import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";

export const PreviewFileBlock = ({ file }) => {
  var isFile = file instanceof File;

  return (
    <Box>
      {isFile ? (
        <CardMedia
          component="img"
          image={URL.createObjectURL(file)}
          alt="preview image"
          sx={{ width: "56px", height: "56px" }}
        />
      ) : (
        !!file.fileLink && (
          <CardMedia
            component="img"
            image={file.fileLink}
            alt="preview image"
            sx={{ width: "56px", height: "56px" }}
          />
        )
      )}
    </Box>
  );
};
