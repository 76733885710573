import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
    Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormItem from "../../components/FormItem";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { useLinkFacilitiesWithConsoleMutation } from "../../../middleware/facilitiesApi";
import {
    useAddControllerObjectMutation,
    useDeleteControllerObjectMutation, useLazyGetControllersConsoleTableQuery
} from "../../../middleware/controllersApi";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { formFields } from "./form-config";
import TableBasic from "../../components/TableBasic";
import { SimpleTextCell } from "../TasksList/Components";
import { ClickableNameCell } from "../MyOrganizations/Components";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {

    SimpleHeader,
} from "../TasksList/Components";


export const ObjectDialog = ({ open, setOpen, selectedName, config, selectedId, refetch }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
   
    const { allConsoleControllers } = useAppSelector((state) => state.controllers);
    const { control, handleSubmit, resetField } = useForm();
    const [pageNumber, changePageNumber] = useState(0);
    const [searchName, setSearchName] = useState("");
    const [searchIntegrationNumber, setSearchIntegrationNumber] = useState("");
    const params = useWatch({
        control,
        name: "filters",
    });

    const [
        AddControllerObject,
        { isError: isErrorAdd, isSuccess: isSuccessAdd },
    ] = useAddControllerObjectMutation();
    const [
        DeleteControllerObject,
        { isError: isErrorDelete, isSuccess: isSuccessDelete },
    ] = useDeleteControllerObjectMutation();
    const [getControllersConsoleTable, { isLoading, error }] =
        useLazyGetControllersConsoleTableQuery();
    useEffect(() => {
        let dd = {}
        let res = ""

        if (searchName != "") {
            dd["searchName"] = searchName
        }
        if (searchIntegrationNumber != "") {
            dd["searchIntegrationNumber"] = searchIntegrationNumber
        }

        if (dd != {}) {
            res = "&" + Object.keys(
                dd
            )
                .map((key) => `${key}=${dd[key]}`)
                .join("&")
        }

        getControllersConsoleTable(
            {
                pageNumber: 0,
                consoleId: selectedId,
                filters: res
            });
    }, [searchName, searchIntegrationNumber]);
    useEffect(() => {
        let dd = {}
        let res = ""

        if (searchName != "") {
            dd["searchName"] = searchName
        }
        if (searchIntegrationNumber != "") {
            dd["searchIntegrationNumber"] = searchIntegrationNumber
        }
        if (dd != {}) {
            res = "&" + Object.keys(
                dd
            )
                .map((key) => `${key}=${dd[key]}`)
                .join("&")
        }
        getControllersConsoleTable(
            {
                pageNumber: pageNumber,
                consoleId: selectedId,
                filters: res
            });
    }, [pageNumber]);


    const handleClose = () => {
        resetField(formFields.facilitiesIds);

        refetch({ pageNumber: 0 });
        setOpen(false);

    };

    const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

    useEffect(() => {
        setIsErrorSubmit(isErrorAdd || isErrorDelete);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isErrorAdd, isErrorDelete]);

    useEffect(() => {
        setIsSuccessSubmit(isSuccessAdd || isSuccessDelete);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessAdd, isSuccessDelete]);
    const columns = [
        {
            id: "name",
            label: t("tableHeader:facility"),
            numeric: false,
            clickable: false,
            enableSorting: false,
            size: "250px",
            RowCell: (props) => <SimpleTextCell {...props} />,
            HeaderCell: () => (
                <Box sx={{ width: "250px" }}>
                    <SimpleHeader name={t("tableHeader:facilityName")} />
                    <FormItem
                        control={control}
                        name={`filters.searchName`}
                        sx={{ padding: "12px 0" }}
                        input={(props) => (
                            <SearchInbuilt
                                className="select_in_built small-light"
                                bgcolor={"var(--bgLightGrey)"}
                                searchValue={searchName}
                                changeSearchValue={setSearchName}
                                {...props}
                            />
                        )}
                    />
                </Box>
            ),
        },
        {
            id: "integrationNumber",
            label: t("tableHeader:facilityIntegrationNumber"),
            numeric: false,
            clickable: false,
            enableSorting: false,
            size: "200px",
            RowCell: (props) => <SimpleTextCell {...props} />,
            HeaderCell: () => (
                <Box sx={{ width: "200px" }}>
                    <SimpleHeader name={t("tableHeader:facilityIntegrationNumber")} />
                    <FormItem
                        control={control}
                        name={`filters.searchIntegrationNumber`}
                        sx={{ padding: "12px 0" }}
                        input={(props) => (
                            <SearchInbuilt
                                className="select_in_built small-light"
                                bgcolor={"var(--bgLightGrey)"}
                                searchValue={searchIntegrationNumber}
                                changeSearchValue={setSearchIntegrationNumber}
                                {...props}
                            />
                        )}
                    />
                </Box>
            ),
        },
        {
            id: "messageTemplatesSettings",

            numeric: false,
            enableSorting: false,
            size: "50px",
            RowCell: (props) => <ClickableNameCell {...props} />,
        },
        {
            id: "isBelongsToConsole",
            numeric: false,
            enableSorting: false,
            size: "200px",

        },
    ];

    const addObject = (objectId) => (e) => {
        let data = {
            controlConsoleId: selectedId,
            protectionObjectId: objectId,
        }
        AddControllerObject(data).then(({ error }) => {
            !error && getControllersConsoleTable(
                {
                    pageNumber: 0,
                    consoleId: selectedId,
                    filters: ""
                });
        });
    }
    const deleteObject = (objectId) => (e) => {
        let data = {
            controlConsoleId: selectedId,
            protectionObjectId: objectId,
        }
        DeleteControllerObject(data).then(({ error }) => {
            !error && getControllersConsoleTable(
                {
                    pageNumber: 0,
                    consoleId: selectedId,
                    filters: ""
                });
            // handleClose();
        });

    }
    const rows = useMemo(() => {
        const { data } = allConsoleControllers;

        return data?.length
            ? data.map(({ id, name, integrationNumber, isBelongsToConsole }) => {
                return {
                    integrationNumber,
                    name,
                    messageTemplatesSettings: {
                        name: (
                            <Button
                                className="table-row-cell-link"
                                sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                                onClick={() =>
                                    navigate(PATHS.GET_CONTROLLER_MESSAGE_TEMPLATE(selectedId)+`?protectionObjectId=${id}`)
                                }
                                startIcon={<SettingsOutlinedIcon />}
                            >

                            </Button>
                        ),
                    },
                    isBelongsToConsole:
                        !isBelongsToConsole ?
                            <Button
                                form="formModalSelect"
                                className="btn btn-primary-fill"
                                onClick={addObject(id)}
                                autoFocus
                            >
                                {t("button:controller_enable_object")}
                            </Button>

                            :
                            <Button
                                onClick={deleteObject(id)}
                                className="btn btn-remove"
                               
                                variant="outlined"
                               
                            >
                                {t("button:controller_disable_object")}
                            </Button>

                };
            })
            : [];
    }, [allConsoleControllers]);

    const recordsCount = useMemo(
        () => allConsoleControllers && allConsoleControllers?.recordsCount,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allConsoleControllers]
    );
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal select_columns"
                maxWidth="lg"
            >
                <DialogTitle className="modal-title-between border_bottom">
                    <Typography className="modal-title" >
                        {`Пульт: ${selectedName}`}
                    </Typography>
                    <Typography className="modal-title" >
                        {`ID: ${selectedId}`}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ padding: "0 36px" }}>
                    <TableBasic
                        withSearchField={false}
                        columns={columns}
                        rows={rows}
                        rowsPerPageOptions={10}
                        handleClickCell={() => { }}
                        pageNumber={pageNumber}
                        changePageNumber={changePageNumber}
                        isLoading={isLoading}
                        count={recordsCount}
                        minHeight={"59vh"}
                        maxHeight={"70vh"}
                    />
                </DialogContent>

            </Dialog>
        </div>
    );
};
