import React from "react";

const VdpoLogoText = () => {
  return (
    <svg
      width="1395"
      height="197"
      viewBox="0 0 1395 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.318 84.122L106.111 77.8987C118.856 78.0418 129.238 79.9731 137.257 83.6928C145.419 87.4125 151.362 92.4197 155.085 98.7146C158.951 105.009 160.885 112.091 160.885 119.96C160.885 128.544 158.808 136.126 154.655 142.707C150.646 149.288 144.703 154.51 136.827 158.373C128.951 162.092 119.142 163.952 107.4 163.952H0V81.3322V3.00436H103.533C118.426 3.00436 129.882 6.50944 137.901 13.5196C146.063 20.3867 150.145 29.9005 150.145 42.061C150.145 48.4989 148.569 54.7938 145.419 60.9455C142.412 66.9542 137.471 72.1046 130.598 76.3965C123.868 80.5454 114.774 83.1206 103.318 84.122ZM28.5683 156.012L17.6135 141.205H104.178C113.056 141.205 119.93 139.202 124.798 135.196C129.81 131.047 132.316 125.182 132.316 117.599C132.316 109.588 129.381 103.364 123.51 98.9292C117.782 94.4942 109.261 92.2767 97.9484 92.2767H28.1387V70.3878H95.1561C102.746 70.3878 109.046 68.3134 114.058 64.1645C119.07 59.8725 121.576 53.7923 121.576 45.9238C121.576 40.0581 119.5 35.2654 115.347 31.5458C111.194 27.683 105.18 25.7516 97.304 25.7516H17.6135L28.5683 10.9444L28.1387 81.3322L28.5683 156.012Z"
        fill="white"
      />
      <path
        d="M169.688 197V139.059H195.034L211.789 139.488H331.647L338.305 139.059H378.258V197H351.838V163.952H196.108V197H169.688ZM205.13 3.00436H349.905V153.008H321.551V15.0218L334.439 27.8976H220.381L232.624 18.8845C233.34 26.7531 233.841 34.5501 234.128 42.2756C234.414 50.0011 234.557 57.2974 234.557 64.1645C234.557 82.1906 233.269 97.5701 230.691 110.303C228.113 123.036 224.319 132.764 219.307 139.488C214.295 146.212 208.209 149.574 201.049 149.574L189.235 139.488C195.679 132.335 200.404 122.106 203.411 108.801C206.562 95.4956 208.137 78.7571 208.137 58.585C208.137 49.858 207.851 40.7734 207.278 31.3312C206.705 21.8889 205.989 12.4466 205.13 3.00436Z"
        fill="white"
      />
      <path
        d="M560.143 3.00436V163.952H532.004V15.2364L544.892 28.1122H417.086L429.974 15.2364V163.952H401.621V3.00436H560.143Z"
        fill="white"
      />
      <path
        d="M680.054 166.956C661.725 166.956 645.615 163.451 631.724 156.441C617.834 149.431 607.023 139.703 599.29 127.256C591.557 114.666 587.691 100.074 587.691 83.4782C587.691 66.8827 591.557 52.3617 599.29 39.915C607.023 27.3254 617.834 17.5254 631.724 10.5153C645.615 3.50509 661.725 0 680.054 0C698.384 0 714.494 3.50509 728.384 10.5153C742.274 17.5254 753.086 27.3254 760.819 39.915C768.552 52.3617 772.418 66.8827 772.418 83.4782C772.418 100.074 768.552 114.666 760.819 127.256C753.086 139.703 742.274 149.431 728.384 156.441C714.494 163.451 698.384 166.956 680.054 166.956ZM680.054 141.634C693.085 141.634 704.327 139.273 713.778 134.552C723.229 129.831 730.532 123.107 735.687 114.38C740.986 105.653 743.635 95.3526 743.635 83.4782C743.635 71.6039 740.986 61.3032 735.687 52.5762C730.532 43.8493 723.229 37.1253 713.778 32.4041C704.327 27.683 693.085 25.3224 680.054 25.3224C667.166 25.3224 655.925 27.683 646.331 32.4041C636.88 37.1253 629.505 43.8493 624.206 52.5762C619.051 61.3032 616.474 71.6039 616.474 83.4782C616.474 95.3526 619.051 105.653 624.206 114.38C629.505 123.107 636.88 129.831 646.331 134.552C655.925 139.273 667.166 141.634 680.054 141.634Z"
        fill="white"
      />
      <path
        d="M1015.33 3.00436V163.952H987.194V15.2364L1000.08 28.1122H872.276L885.164 15.2364V163.952H856.81V3.00436H1015.33Z"
        fill="white"
      />
      <path
        d="M1136.75 3.00436C1148.78 3.00436 1159.16 5.15033 1167.89 9.44227C1176.77 13.7342 1183.57 19.7429 1188.3 27.4684C1193.17 35.0508 1195.6 43.8493 1195.6 53.8638C1195.6 63.8784 1193.17 72.7484 1188.3 80.4738C1183.57 88.0563 1176.77 93.9935 1167.89 98.2854C1159.16 102.577 1148.78 104.723 1136.75 104.723H1064.36V80.2593H1135.67C1145.41 80.2593 1153 77.8987 1158.44 73.1776C1164.03 68.4564 1166.82 62.0185 1166.82 53.8638C1166.82 45.7091 1164.03 39.2712 1158.44 34.5501C1153 29.829 1145.41 27.4684 1135.67 27.4684H1067.15L1079.83 13.305V163.952H1051.47V3.00436H1136.75Z"
        fill="white"
      />
      <path
        d="M1302.64 166.956C1284.31 166.956 1268.2 163.451 1254.31 156.441C1240.42 149.431 1229.6 139.703 1221.87 127.256C1214.14 114.666 1210.27 100.074 1210.27 83.4782C1210.27 66.8827 1214.14 52.3617 1221.87 39.915C1229.6 27.3254 1240.42 17.5254 1254.31 10.5153C1268.2 3.50509 1284.31 0 1302.64 0C1320.97 0 1337.08 3.50509 1350.97 10.5153C1364.86 17.5254 1375.67 27.3254 1383.4 39.915C1391.13 52.3617 1395 66.8827 1395 83.4782C1395 100.074 1391.13 114.666 1383.4 127.256C1375.67 139.703 1364.86 149.431 1350.97 156.441C1337.08 163.451 1320.97 166.956 1302.64 166.956ZM1302.64 141.634C1315.67 141.634 1326.91 139.273 1336.36 134.552C1345.81 129.831 1353.11 123.107 1358.27 114.38C1363.57 105.653 1366.22 95.3526 1366.22 83.4782C1366.22 71.6039 1363.57 61.3032 1358.27 52.5762C1353.11 43.8493 1345.81 37.1253 1336.36 32.4041C1326.91 27.683 1315.67 25.3224 1302.64 25.3224C1289.75 25.3224 1278.51 27.683 1268.91 32.4041C1259.46 37.1253 1252.09 43.8493 1246.79 52.5762C1241.63 61.3032 1239.06 71.6039 1239.06 83.4782C1239.06 95.3526 1241.63 105.653 1246.79 114.38C1252.09 123.107 1259.46 129.831 1268.91 134.552C1278.51 139.273 1289.75 141.634 1302.64 141.634Z"
        fill="white"
      />
    </svg>
  );
};

export default VdpoLogoText;
