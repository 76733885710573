import React from "react";
import { Box } from "@mui/material";

import "./styles/style.css";
import { LeftBlockLogin } from "../LogIn/LeftBlockLogin";
import { RegistrationForm } from "./RegistrationForm";

export const Registration = () => {
  return (
    <Box className="login-wrapper">
      <LeftBlockLogin />
      <Box className="right_block">
        <RegistrationForm />
      </Box>
    </Box>
  );
};
