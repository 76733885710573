import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useGetOrganizationsSelectWithTypesQuery, useGetOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import SelectSimple from "../../../components/SelectSimple";
import { useFormContext } from "react-hook-form";

import { useAppSelector } from "../../../../hooks/redux";
import { formFields } from "../form-config";

import FormItem from "../../../components/FormItem";
import { SelectMultipleChipDeletableWithSearchField } from "../../../components/SelectMultipleChipDeletableWithSearchField";
import InputBlockFieldRecursive from "../../../components/InputBlockFieldRecursive";

export const ExecutiveUserNameSelectSimple = (props) => {

  const { data: executives } = useGetOrganizationsSelectWithTypesQuery();

  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (

    <SelectSimple
      {...props}
      options={options}
    />

  );
};



export const OrganizationForm = ({ config, readonly }) => {
  const { control, errors } = useFormContext();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {config.map(({ id, name, label, input, ...rest }) => (
          <FormItem
            key={id}
            control={control}
            errors={errors}
            name={name}
            disabled={readonly}
            label={label}
            input={input}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
};

export const BuildingPassportFrom = ({ config, sectionName, readonly }) => {
  const { control, errors, watch } = useFormContext();
  const isReadySection = !!watch(`${sectionName}`);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {isReadySection && config.map(({ id, name, label, input, CustomFormItem, type, ...rest }, i) => (
          CustomFormItem ? (
            <CustomFormItem
              key={i}
              control={control}
              errors={errors}
              name={name}
              disabled={readonly}
              sectionName={sectionName}
              {...rest}
            />
          ) : (
            <FormItem
              key={id}
              control={control}
              errors={errors}
              disabled={readonly}
              name={
                type === "file"
                  ? `${[formFields.files]}.${name}`
                  : `${sectionName}.${name}`
              }
              label={label}
              input={input}
              {...rest}
            />
          )
        ))}
      </Box>
    </Box>
  );
};

export const BuildingCharacteristicsFrom = ({ config, sectionName, readonly }) => {
  const { control, errors } = useFormContext();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {config.map(({ id, name, label, input, CustomFormItem, ...rest }, i) =>
          CustomFormItem ? (
            <CustomFormItem
              key={i}
              control={control}
              errors={errors}
              name={name}
              disabled={readonly}
              sectionName={sectionName}
              {...rest}
            />
          ) : (
            <FormItem
              key={i}
              control={control}
              errors={errors}
              name={`${sectionName}.${name}`}
              label={label}
              disabled={readonly}
              input={input}
              {...rest}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export const FacilitySystemsForm = ({ config, sectionName, readonly }) => {
  const { control, errors, register, unregister, setValue, getValues } =
    useFormContext();


  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {config.map(
          ({ id, name, label, input, expended, title, ...rest }) => (

            <InputBlockFieldRecursive
              key={name}
              name={name}
              label={label}
              input={input}
              disabled={readonly}
              expended={expended}
              title={title}
              controlExpandedCheck={id == 0 ? formFields.notifAndEvacControlSysMaintenanceContract : id == 1 ? formFields.autoFireAlarmSysMaintenanceContract : id == 2 ? formFields.autoFireSuppressionSysMaintenanceContract : null}
              control={control}
              errors={errors}
              register={register}
              unregister={unregister}
              sectionName={sectionName}
              setValue={setValue}
              getValues={getValues}
              formFields={formFields}
              {...rest}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export const OrganizationsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } =
    useGetOrganizationsSelectQuery(searchValue);

  const options = useMemo(() => {
    return organizationsSelect
      ? organizationsSelect
      : [];
  }, [organizationsSelect]);
  console.log("options",options)
  return <SelectMultipleChipDeletableWithSearchField
    setSearchValue={setSearchValue} isIds={true}
    searchValue={searchValue} options={options} {...props} />;
};
