import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAction } from "../../../../hooks/actions";

import { WarningValidationForm } from "../../../components/Notifications";
import { sections, formFields, formConfigSwitcher } from "./form-config";
import AccordionSection from "../../../components/AccordionSection";
import { DEFAULT_VALUE } from "../constants";
import { useEditUserMutation, useGetUserQuery, useConfirmVerificationMutation } from "../../../../middleware/usersApi";
import { formatPhoneNumber } from "../../ResponsibleOrganizationsList/helpers";
import { PATHS } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/redux";
import FormItem from "../../../components/FormItem";
import { checkboxValues, ReversecheckboxValues } from "./constants";
import { useGetAllOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const EditEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAppSelector((state) => state.users);
  const { roleId } = useAppSelector((state) => state.users.info);
  const [pass, setPass] = useState("")
  const { changeTitlePage, setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
  });

  const [EditEmployee, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useEditUserMutation();

  const [verifyUser, { error: errorverify, isSuccess: isSuccessverify }] =
    useConfirmVerificationMutation();



  const { data: profileInfo = [], refetch: refetchEmployee, } =
    useGetUserQuery({ token: token, employeeId: id, });
  console.log("profileInfo", profileInfo)
  const { data: organizationsSelect = [] } =
    useGetAllOrganizationsSelectQuery();

  const isWarningValidation = !!Object.keys(errors).length;
  const error = errorAdd || errorverify;
  const isSuccess = isSuccessAdd || isSuccessverify;

  useEffect(() => {
    if (profileInfo) {


      const updatedprofileInfo =
      {

        ...profileInfo,
        organizationWorkingFor: profileInfo?.organizationWorkingFor?.id,

        fullName: `${profileInfo?.lastName} ${profileInfo?.firstName} ${profileInfo?.surname}`,
      }


      reset({
        ...updatedprofileInfo,

      });
      setPass(profileInfo?.password)
      profileInfo?.permissionsIds?.map((item) => setValue(`${[formFields.permissionsIds]}.${ReversecheckboxValues[item]}`, true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo, reset]);
  const onSubmit = async (data) => {

    const {
      fullName,
      permissionsIds,
      organizationWorkingFor,
      equipmentKindsIds,
      ...rest
    } = data || {};

    const trueElements = Object.keys(data[formFields.permissionsIds]).filter(
      (key) => data[formFields.permissionsIds][key] === true
    );



    const fio = fullName.split(" ");
    const firstName = fio[1] || "";
    const surname = fio[2] || "";
    const lastName = fio[0] || "";

    const requestData = {
      permissionsIds: [...trueElements.map((item) => checkboxValues[item])],
      ...rest,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      PasswordRepeat: data.password,
      organizationWorkingFor: organizationsSelect.find(
        (item) => item.id === organizationWorkingFor
      ),
      firstName: firstName,
      surname: surname,
      lastName: lastName,
      equipmentKindsIds:
        equipmentKindsIds
    };
    
    EditEmployee({ token, data: requestData }).then((result) => {
      if ("error" in result) {
        return;
      }
      reset({})
      refetchEmployee()
      navigate(PATHS.LIST_EMPLOYEES);
    });
  };

  const verifyEmployee = () => {
    verifyUser({ id: profileInfo.id }).then((result) => {
      if ("error" in result) {
        return;
      }
      reset({})
      refetchEmployee()
    })
  }

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    changeTitlePage(`${profileInfo?.lastName} ${profileInfo?.firstName} ${profileInfo?.surname}`);
  }, [profileInfo, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  return (
    <Box className="template_page employee">
      <form
        className="scrolled-form"
        id="myform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className="template_page-header">

          <Box>

            <Box sx={{ display: "flex" }}>
              <Typography className="template_page-header-title" sx={{ color: getValues(formFields.isActive) ? "var(--main) !important" : "red !important" }}>
                {`${profileInfo?.lastName} ${profileInfo?.firstName} ${profileInfo?.surname}`}
              </Typography>
              <Box sx={{ marginLeft: "30px", display: "flex" }}>
                {profileInfo?.isVerified ?
                  <CheckCircleOutlineIcon sx={{ marginTop: "15px", color: "green" }} />
                  :
                  <HighlightOffIcon sx={{ marginTop: "15px", color: "red" }} />
                }
                <Typography sx={{ marginTop: "16px", marginLeft: "5px", fontWeight: "600", color: profileInfo?.isVerified ? "green" : "red" }}>
                  {profileInfo?.isVerified ? "Подтвержден" : "Не подтвержден"}
                </Typography>
              </Box>
            </Box>
            {/* <Button
            className="btn btn-remove"
            variant="outlined"
         
          >
            {t("button:remove")}
          </Button>
  */}

            {(roleId == 1 || roleId == 2) && formConfigSwitcher.map(({ name, label, input, ...rest }, i) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </Box>

          <Box className="template_page-header-buttons">
            {profileInfo?.enableConfirmVerification &&
              <Button
                className="btn btn-primary-fill"
                variant="contained"
                disabled={profileInfo?.isVerified}
                sx={{ backgroundColor: "green !important" }}
                onClick={verifyEmployee}>
                {t("button:confirm")}
              </Button>
            }
            <Button className="btn btn-outlined-grey" onClick={handleCancel}>
              {t("button:comeback")}
            </Button>

            <Button
              form="myform"
              className="btn btn-primary-fill"
              type="submit"
              disabled={isSubmitSuccessful && !error}
            >
              {t("button:save")}
            </Button>
          </Box>
        </Box>

        {sections.map(({ id, title, Component, config }) =>
          title ? (
            <AccordionSection key={id} title={title}>
              {Component && (
                <Component data={profileInfo} password={pass} setValue={setValue} control={control} errors={errors} config={config} />
              )}
            </AccordionSection>
          ) : (
            <Box key={id}>
              {Component && (
                <Component data={profileInfo} password={pass} setValue={setValue} control={control} errors={errors} config={config} />
              )}
            </Box>
          )
        )}
      </form>

      {
        isWarningValidation && (
          <WarningValidationForm isWarning={isWarningValidation} />
        )
      }
    </Box >
  );
};
