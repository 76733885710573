import React from "react";
import { Typography, Box, Avatar} from "@mui/material";
import "./styles-profile/styles.css";
import { getMaskPhone } from "../../../helpers/helper";
import { useAppSelector } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";
export const EmployeeProfile = () => {
  const {
    firstName,
    lastName,
    surname,
    jobTitle,
    email,
    role,
    id,
    phone,
    avatarLink,
  } = useAppSelector((state) => state.users.employee);
  const { t } = useTranslation();
 
  return (
    <Box className="profile_contact">
      <Avatar
        src={avatarLink}
        style={{ height: "152px", width: "152px", marginTop: "10px" }}
      />
      <Box className="profile_contact-contacts">
        <Typography className="profile_contact-fio">{`${lastName} ${firstName} ${surname}`}</Typography>
        <Box className="profile_contact-job_employments_block">
          <Typography>{role}</Typography>
          <Typography>{jobTitle}</Typography>
        </Box>
        <Box className="profile_contact-connection">
          <Typography className="profile_contact-connection-text">
            {getMaskPhone(phone)}
          </Typography>
          <Typography className="profile_contact-connection-text">
            {email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
