import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    ListItemText,
    FormControl,
    Select, Checkbox
} from "@mui/material";

import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    autoFocus: false,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
        },
    },
};

const SelectGroupingMultipleCheckmarks = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  firstdata,
  setValue,
  withSearch=true,
  ...rest
}) => {
    const { t } = useTranslation();

    const [updatedOptions, setUpdatedOptions] = useState([]);

    useEffect(() => {
        setUpdatedOptions(options.map(option => ({
            ...option
        })))
    }, [options])

    const allOptionItems = useMemo(() => {
        return updatedOptions?.reduce(
            (accumulator, {items}) => items?.length ? [...accumulator, ...items] : accumulator,
            []
        );
    }, [updatedOptions])

    return (
        <FormControl fullWidth error={!!error} {...rest}>
            <InputLabel>{t(label)}</InputLabel>
            <Select
                value={value}
                input={<OutlinedInput label={t(label)} />}
                onChange={(e) => onChange(e.target.value)}
                multiple
                MenuProps={MenuProps}
                onClose={() => withSearch && setSearchValue("")}
                sx={{maxHeight: "56px"}}
                renderValue={(selected) => (
                    firstdata &&  selected?.length ? selected.map((value) => (
                        firstdata.find(({id}) => id === value)?.name
                    )).join(", ") : ""
                )}
                {...rest}
            >
                {withSearch && (
                  <MyListSubheaderSearch
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                  />
                )}
                {updatedOptions?.length ? updatedOptions.map((option) => {
                        const { open, items } = option || {};

                        if (!items) {
                            const {id, name} = option
                            return (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={false} disabled={true} />
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            )
                        }

                        const Items = items?.length ? items.map((item) => {
                            const { id, name } = item
                            return (
                                <MenuItem
                                    key={id}
                                    value={id}
                                    sx={{
                                        display: `${open ? "flex" : "none"}`,
                                        paddingLeft: "55px"
                                    }}
                                >
                                    <Checkbox checked={value.includes(id)}/>
                                    <ListItemText primary={name} />
                                </MenuItem>
                            )
                        }) : null;

                        return [
                            <MyListSubheader
                                key="MyListSubheader"
                                setOptions={setUpdatedOptions}
                                options={updatedOptions}
                                itemOption={option}
                                selectValue={value}
                                onChange={onChange}
                            />,
                            Items,
                        ];
                    })
                    : <MenuItem disabled={true}>
                        <ListItemText primary={t("label:nothingFound")}/>
                    </MenuItem>
                }
            </Select>
        </FormControl>
    );
};

export default SelectGroupingMultipleCheckmarks;
