import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useState } from "react";

export const MenuItemLink = ({ label, link, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button
      href={link}
      target="_blank"
      {...rest}
    >
      {t(label)}
    </Button>
  );
};

export const MenuItemPopupHandler = ({label, Popup, ...rest}) => {
  const {t} = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);

  const handleOnClick = () => setOpenPopup(!openPopup);

  return (
    <>
      <Button
        onClick={handleOnClick}
        {...rest}
      >
        {t(label)}
      </Button>
      <Popup open={openPopup} setOpen={setOpenPopup}/>
    </>
  );
}