import { ClickableNameCell } from "./Components";
import {

  SimpleHeader,
} from "../TasksList/Components";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { formFields } from "./form-config";
import { typeOptions } from "./options";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import { t } from "i18next";
export const getColumns = ({
  control,
  columnsFromStorage,
  orgTypeFilter,
  handleChange,
  searchInn,
  setsearchInn,
  searchName,
  setsearchName,
  searchAddress,
  setsearchAddress,
}) =>
  [
    {
      id: formFields.innNumber,
      numeric: false,
      enableSorting: false,
      size: "100px",
      clickable: false,
      isVisible: true,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:innNumber")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.innNumber}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchInn}
                changeSearchValue={setsearchInn}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.typeName,
      numeric: false,
      enableSorting: false,
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.typeName],
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:organizationType")} />
          <Box sx={{ padding: "12px 0px" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={orgTypeFilter}
              onChange={handleChange}
              
              options={typeOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>

      ),
    },
    {
      id: formFields.name,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:name"),
      size: 150,
      isVisible: columnsFromStorage[formFields.name],
      clickable: true,
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:name")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.name}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchName}
                changeSearchValue={setsearchName}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.organizationAddresses,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:address"),
      size: 368,
      isVisible: columnsFromStorage[formFields.organizationAddresses],
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:address")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}>
            <AddressInputInbuilt
              className="select_in_built small-light"
              value={searchAddress}
              onChange={setsearchAddress}

              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>

      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      label: t("tableHeader:tasks"),
      size: 80,
      isVisible: columnsFromStorage[formFields.tasks],
      mode: "diagram",
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "80px" }}>
          <SimpleHeader name={t("tableHeader:tasks")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </Box>

      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);
