import { ClickableNameCell } from "./Components";
import {

  SimpleHeader,
} from "../TasksList/Components";
import { Box } from "@mui/material";
import { formFieldsTable as formFields } from "./form-config";
import { rolesOptions, typeOptions, activeOptions, verifiedOptions } from "./options";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import {
  BlockAvatar,
  ClickableActionNameCell,
  ClickableActionListCell,
  SimpleTextCell,
  SimpleActiveTextCell,
  SelectSimpleEquipmentKinds,
  SimpleBlockTextCell,
} from "./Components";
import { t } from "i18next";
import FormItem from "../../components/FormItem";
import { Verified } from "@mui/icons-material";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { SelectSimpleObject } from "../EquipmentList/Components";

export const getColumns = ({
  control,
  columnsFromStorage,
  organizationTypeId,
  handleChange,
  activeValue,
  changeactiveValue,
  verifiedValue,
  changeverifiedValue,
  searchName,
  setsearchName,
  organizationSearch,
  setorganizationSearch,
  employeePosition,
  setemployeePosition,
  login,
  setLogin,
  email,
  setEmail,
  searchRole,
  setsearchRole,
  rows
}) =>
  [
    {
      id: formFields.avatarLink,
      numeric: false,
      enableSorting: false,
      label: "",
      styleTableCell: {
        verticalAlign: "top",
      },
      clickable: false,
      isVisible: columnsFromStorage[formFields.avatarLink],
      RowCell: ({ row }) => <BlockAvatar data={row[formFields.avatarLink]} />,
    },
    {
      id: formFields.employeeFullName,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("label:FIO_employee")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeFullName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchName}
                changeSearchValue={setsearchName}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.isActive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isActive"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isActive],
      RowCell: (props) => <SimpleActiveTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "140px" }}>
          <SimpleHeader name={t("tableHeader:isActive")} />
          <Box sx={{ padding: "12px 0" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={activeValue}
              onChange={changeactiveValue}
              sx={{ padding: "12px 0" }}
              options={activeOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>
      ),
    },
    {
      id: formFields.employeeLogin,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeLogin"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: (columnsFromStorage[formFields.employeeLogin] && !rows?.hideEmployeeLogin),
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:employeeLogin")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeLogin}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={login}
                changeSearchValue={setLogin}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.employeeEmail,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeEmail"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeEmail],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:employeeEmail")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeeEmail}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={email}
                changeSearchValue={setEmail}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.employeeRoleName,
      numeric: false,
      enableSorting: false,
      label: t("label:role"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeRoleName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "150px" }}>
          <SimpleHeader name={t("label:role")} />
          <Box sx={{ padding: "12px 0" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={searchRole}
              onChange={setsearchRole}
              sx={{ padding: "12px 0" }}
              options={rolesOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>
      ),
    },
    {
      id: formFields.employeePosition,
      numeric: false,
      enableSorting: false,
      label: t("label:position"),
      styleTableCell: {
        verticalAlign: "top",
      },
      clickable: false,
      isVisible: columnsFromStorage[formFields.employeePosition],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("label:position")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.employeePosition}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={employeePosition}
                changeSearchValue={setemployeePosition}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.employeeFacilities,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "225px",
      isVisible: columnsFromStorage[formFields.employeeFacilities],
      RowCell: (props) => <ClickableActionListCell {...props} />,
      HeaderCell: () => (
        <Box >
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.FacilityId`}
            sx={{ padding: "12px 0" }}
            defaultValue={"все"}
            input={(props) => (
              <SelectSimpleObject
                {...props}
                className="select_in_built small-light"
                sx={{ width: "200px", backgroundColor: "var(--bgLightGrey)" }}
              //bgcolor={}

              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.employeeEquipmentKinds,
      numeric: false,
      enableSorting: false,
      label: t("label:objectsTypes"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.employeeEquipmentKinds],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{width:"250px"}}>
          <SimpleHeader name={t("label:objectsTypes")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.equipKinds`}
            sx={{ padding: "12px 0" }}
            defaultValue={"все"}
            input={(props) => (
              <SelectSimpleEquipmentKinds
                {...props}
                className="select_in_built small-light"
                sx={{ width: "200px", backgroundColor: "var(--bgLightGrey)" }}
              //bgcolor={}

              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.organizationType,
      numeric: false,
      enableSorting: false,
      label: t("label:organizationType"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "120px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.organizationType],
      RowCell: (props) => <SimpleBlockTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:organizationType")} />
          <Box sx={{ padding: "12px 0" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={organizationTypeId}
              onChange={handleChange}
              sx={{ padding: "12px 0" }}
              options={typeOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>
      ),
    },
    {
      id: formFields.organizations,
      numeric: false,
      enableSorting: false,
      label: t("label:organizationName"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "250px",
      isVisible: columnsFromStorage[formFields.organizations],
      RowCell: (props) => <ClickableActionListCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("label:organizationName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.organizations}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={organizationSearch}
                changeSearchValue={setorganizationSearch}
                {...props}
              />
            )}
          />
        </Box>

      ),
    },
    
    {
      id: formFields.isVerified,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isVerified"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isVerified],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "160px" }}>
          <SimpleHeader name={t("tableHeader:isVerified")} />
          <Box sx={{ padding: "12px 0" }}>
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              value={verifiedValue}
              onChange={changeverifiedValue}

              options={verifiedOptions}
              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>


        </Box>
      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      label: t("tableHeader:tasks"),
      size: 80,
      isVisible: columnsFromStorage[formFields.tasks],
      mode: "diagram",
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "80px" }}>
          <SimpleHeader name={t("tableHeader:tasks")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </Box>

      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);
