import React from "react";
import "./styles-profile/styles.css";
import { useAppSelector } from "../../../hooks/redux";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ReadOnlyEmployeePersonalData = () => {
  const { t } = useTranslation();

  const {
    role,
    jobTitle,
    userEmployers,
    lastName,
    firstName,
    surname,
    facilities,
  } = useAppSelector((state) => state.users.employee);

  return (
    <Box
      style={{ display: "flex", flexWrap: "wrap" }}
      className="read_only_personal_data_container"
    >
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:lastName")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {lastName ? lastName : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:firstName")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {firstName ? firstName : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:surname")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {surname ? surname : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:role")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {role ? role : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:position")}
        </Typography>
        <Typography className="read_only_personal_data_container-item-content">
          {jobTitle ? jobTitle : t("label:doesntHave")}
        </Typography>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:organization")}
        </Typography>
        <Box sx={{maxHeight:"200px",overflowY:"auto",marginTop:"16.5px"}}>
        {userEmployers?.length ? (
          userEmployers.map((item) => (
            <Typography
              key={item}
              className="read_only_personal_data_container-item-content" sx={{height:"auto",paddingTop:0}}
            >
              {item}
            </Typography>
          ))
        ) : (
          <Typography className="read_only_personal_data_container-item-content">
            {t("label:doesntHave")}
          </Typography>
        )}
        </Box>
      </Box>
      <Box className="read_only_personal_data_container-item">
        <Typography className="read_only_personal_data_container-item-label">
          {t("label:objects")}
        </Typography>
        <Box sx={{maxHeight:"200px",overflowY:"auto",marginTop:"16.5px"}}>
        {facilities?.length ? (
          facilities.map((item) => (
            <Typography
              key={item.facilityId}
              className="read_only_personal_data_container-item-content" sx={{height:"auto",paddingTop:0}}
            >
              {item.facilityName}
            </Typography>
          ))
        ) : (
          <Typography className="read_only_personal_data_container-item-content">
            {t("label:doesntHave")}
          </Typography>
        )}
        </Box>
      </Box>
    </Box>
  );
};
