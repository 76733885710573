import React from "react";
import { t } from "i18next";
import { Typography, Box, Button } from "@mui/material";
import { formFields } from "./table-config";
import { ClickableEquipmentTextCell, FacilityAndFireCompartmentSelectGroupingMultipleCheckmarks, SimpleTextWithTooltipCell } from "./Components";
import { SimpleHeader } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { SimpleHHeader, SignatureCell, SimpleTextWithTooltipCellDetailes, DateFormatCell } from "./Components";
import { ClickableEquipmentCell } from "../EquipmentList/Components"
import { SelectSimpleObject } from "../EquipmentList/Components"
export const getColumns = ({
  control,
  columnsFromStorage,
  jobName,
  setJobName,
  searchworkScopeName,
  setsearchworkScopeName,
  searchworkScopeDetails,
  setsearchworkScopeDetails,
  equipName,
  setequipName,
  roomPlume,
  setroomPlume,
  executiveUser,
  facilities,
  setFacilities,
  setexecutiveUser,
  searchValue,
  changeSearchValue,
  responsibleUser,
  setresponsibleUser,
}) =>
  [

    {
      id: formFields.completedAt,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:dateAndTime"),
      size: "170px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.completedAt],
      RowCell: (props) => <DateFormatCell {...props} type="dateTime" />,
      HeaderCell: () => (
        <>
          <SimpleHHeader name={t("tableHeader:dateTime")} />

          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.CompletedDateRange.MinValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.CompletedDateRange.MaxValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      )
    },
    {
      id: formFields.facilityName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityName"),
      size: "164px",
      clickable: false,
      HeaderCell: () => (
        <Box >
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.FacilityId}`}
            sx={{ padding: "12px 0" }}
            defaultValue={"все"}
            input={(props) => (
              <SelectSimpleObject
                {...props}
                className="select_in_built small-light"
                sx={{width:"200px",backgroundColor:"var(--bgLightGrey)"}}
                //bgcolor={}
               
              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </Box>
      ),
      isVisible: columnsFromStorage[formFields.facilityName]

    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "164px",
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:task")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.TaskName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (

              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={jobName}
                changeSearchValue={setJobName}
                {...props}
              />
            )}
          />
        </Box>
      ),
      isVisible: columnsFromStorage[formFields.jobName]

    },
    {
      id: formFields.workScopeName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:kindWorks"),
      size: "228px",
      isVisible: columnsFromStorage[formFields.workScopeName],
      color: "#4339F2",
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:kindWorks")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.workScopeName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchworkScopeName}
                changeSearchValue={setsearchworkScopeName}
                {...props}
              />
            )}
          />
        </Box>
      ),
      RowCell: (props) => <SimpleTextWithTooltipCell {...props} />,
    },
    {
      id: formFields.workScopeDetails,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:workScopeDetails"),
      size: "228px",
      isVisible: columnsFromStorage[formFields.workScopeDetails],
      color: "#4339F2",
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:workScopeDetails")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.workScopeDetails}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchworkScopeDetails}
                changeSearchValue={setsearchworkScopeDetails}
                {...props}
              />
            )}
          />
        </Box>
      ),
      RowCell: (props) => <SimpleTextWithTooltipCellDetailes {...props} />,
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "170px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentName],
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:equipment")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.EquipmentNameAndNumber`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={equipName}
                changeSearchValue={setequipName}
                {...props}
              />

            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.roomAddressPlume,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:roomAddressPlume"),
      size: "220px",
      clickable: false,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:roomAddressPlume")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RoomPlume`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={roomPlume}
                changeSearchValue={setroomPlume}
                {...props}
              />

            )}
          />
        </>

      ),
      isVisible: columnsFromStorage[formFields.roomAddressPlume],
    },
    {
      id: formFields.executiveUser,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executive"),
      size: "205px",
      clickable: false,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:executive")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ExecutiveUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={executiveUser}
                changeSearchValue={setexecutiveUser}
                {...props}
              />

            )}
          />
        </>

      ),
      isVisible: columnsFromStorage[formFields.executiveUser],
    },
    {
      id: formFields.executiveUserSignature,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executiveUserSignature"),
      size: "160px",
      clickable: false,
      RowCell: (props) => <SignatureCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:executiveUserSignature")} />
          <Box sx={{ padding: "12px 0" }}></Box>
          <Box sx={{ padding: "12px 0" }}></Box>
        </>
      ),
      isVisible: columnsFromStorage[formFields.executiveUserSignature],
    },
    {
      id: formFields.responsibleCustomer,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:responsibleCustomer"),
      size: "205px",
      clickable: false,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:responsibleCustomer")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ResponsibleCustomerUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={responsibleUser}
                changeSearchValue={setresponsibleUser}
                {...props}
              />

            )}
            />
        </>

      ),
      isVisible: columnsFromStorage[formFields.responsibleCustomer],
    },
    {
      id: formFields.responsibleCustomerSignature,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:responsibleCustomerSignature"),
      size: "135px",
      clickable: false,
      RowCell: (props) => <SignatureCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:responsibleCustomerSignature")} />
          <Box sx={{ padding: "12px 0" }}></Box>
          <Box sx={{ padding: "12px 0" }}></Box>
        </>
      ),
      isVisible: columnsFromStorage[formFields.responsibleCustomerSignature],
    },
    {
      id: formFields.taskNumber,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.taskNumber],
    },
  ].filter(({ isVisible }) => isVisible);