export const DEFAULT_VALUE1 = {
  isBlocked: true,
  billingRateId: 0,
  billingPeriod: "",
};

export const DEFAULT_VALUE2 = {
  organizationBillingId: "",
  amount: null,
};
