import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Box,
  Chip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectMultipleChipDeletable = ({
  options,
  label,
  onChange,
  error,
  onClickEquip,
  value,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const requestData = value?.map(({ id }) => id) || [];
    console.log("requestData",requestData)
    setSelectedValues(value);
    onChange(requestData);
  };

  const handleDelete = (e, value) => {
    e.preventDefault();

    const requestData = selectedValues?.filter((e) => e?.id !== value?.id);
    setSelectedValues(requestData);
    onChange(requestData?.map(({ id }) => id));
  };

  useEffect(() => {
    if (options?.length && value?.length) {
      const requestData =
        value?.reduce((acc, v) => {
          const item = options?.find(({ id }) => id === v); 
          return item ? [ ...acc, item] : acc;
        }, []);

      setSelectedValues(requestData);
    } else if (!options?.length && value?.length) {
      setSelectedValues(value);
    }
  }, [value, options]);

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        disabled={disabled}
        onChange={handleChange}
        input={<OutlinedInput label={t(label)} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value, i) =>
              !disabled ? (
                <Chip
                  key={value?.id || i}
                  label={value?.name}
                  clickable
                  onClick={() => { onClickEquip && navigate(PATHS.GET_EQUIPMENT_DETAILS(value?.id))}}
                  deleteIcon={
                    <ClearIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              ) : (
                <Chip
                  key={value?.id || i}
                  label={value?.name || value?.standard}
                  clickable
                  onClick={() => { onClickEquip && navigate(PATHS.GET_EQUIPMENT_DETAILS(value?.id))}}
                />
              )
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options?.map((option, i) => (
          <MenuItem
            key={option?.id || i}
            value={option}
            sx={{ height: ITEM_HEIGHT }}
          >
            <Checkbox checked={selectedValues?.indexOf(option) > -1} />
            <ListItemText primary={option?.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMultipleChipDeletable;
