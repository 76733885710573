import {useAppSelector} from "../../../../hooks/redux";
import CheckboxSimple from "../../../components/CheckboxSimple";
import {telegramNotificationsAvailableRole} from "./form-config";

const TelegramNotificationsAvailability= (props) => {
  const {role} = useAppSelector(state => state.users.info);

  if (role !== telegramNotificationsAvailableRole) {
      return null;
  }
  return <CheckboxSimple {...props}/>;
}

export default TelegramNotificationsAvailability;