import React from "react";
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { FloorsConfig, templateFloor } from "./form-config.jsx";

import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";

export const FloorShortFields = ({ name, control, errors, rules, setValue,disabled }) => {
  const { t } = useTranslation();

  const {
    fields: floorFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {floorFields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            flexBasis: "100%",
            display: "grid",
            gridTemplateColumns: "9fr 1fr",
            gridColumnGap: "20px",
            alignItems: "flex-start",
            paddingBottom: "15px",
            ".error-text": {
              whiteSpace: "normal",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {FloorsConfig.map(({ name : itemName, label, input, type, ...rest }) => (
              <FormItem
                key={`${name}.${index}.${itemName}`}
                control={control}
                errors={errors}
                disabled={disabled}
                name={`${name}.${index}.${itemName}`}
                label={label}
                input={input}
                rules={rules}
                type={type}
                setValue={setValue}
                {...rest}
              />
            ))}
          </Box>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {index !== 0 && (
              <Button
                className="btn btn-remove btn-basket-small"
                onClick={() => remove(index)}
              >
                <DeleteForeverIcon />
              </Button>
            )}
          </Box>
        </Box>
      ))}

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => append(templateFloor)}
        >
          {t("button:add")}
        </Button>
      </Box>
    </>
  );
};
