import React from "react";
import { Box } from "@mui/material";

import SideBar from "../SideBar";
import Header from "../Header";
import PageContent from "../PageContent";

const Layout = ({ children }) => {
  return (
    <Box sx={{
      display: "flex",
      backgroundColor: "var(--bgLightGrey)",
    }}>
      <SideBar />
      <Box sx={{ width: "100%", overflowX: "hidden", height: "100vh" }}>
        <Header/>
        <PageContent>
          { children }
        </PageContent>
      </Box>
    </Box>
  );
};

export default Layout;