import React from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, OutlinedInput, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Search from "./Search";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectSimpleSearch = ({
  options,
  label,
  onChange,
  error,
  value,
  labelKey,
  searchValue,
  displayEmpty = false,
  setSearchValue,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error} {...rest}>
      {displayEmpty ?
      <InputLabel shrink={true}>{t(label)}</InputLabel>
        
        :
        <InputLabel >{t(label)}</InputLabel>
      }
      <Select
        value={(value || "")}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        displayEmpty={displayEmpty}
        sx={{ ".MuiSelect-select": { height: 0 } }}
      >
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        {displayEmpty &&
          <MenuItem value="">
            Нет
          </MenuItem>

        }
        {options && options?.length > 0 && options?.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value || option || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option?.[labelKey] || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimpleSearch;
