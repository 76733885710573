import React from "react";
import {
    ListSubheader,
    ListItemButton,
    ListItemText,
    TextField, Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useTranslation} from "react-i18next";

export const MyListSubheader = ({options, setOptions, itemOption, selectValue, onChange}) => {
    const {id, name, open, checked, items} = itemOption || {};

    const handleClick = () => {
        const updatedOptions = options?.length && options.map((option) => ({
            ...option,
            open: !option.open && option.id === id,
        }));
        setOptions(updatedOptions);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        const updatedOptions = options?.length && options.map((option) => ({
            ...option,
            checked: option.id === id ? !option.checked : option.checked,
            open: option.id === id ? !option.checked : option.open ,
        }));
        const uniqueItems = checked ?
            selectValue.filter(value => !items.find(({id}) => value === id))
            : [...new Set([...selectValue, ...items.map(({id}) => id)])];
        onChange(uniqueItems);
        setOptions(updatedOptions);
    }

    return (
        <ListSubheader sx={{position: "inherit"}}>
            <ListItemButton onClick={handleClick} sx={{padding: 0}}>
                {open ?
                    <ExpandMoreIcon/>
                    : <ChevronRightIcon/>
                }
                <Checkbox onClick={handleCheckboxClick} checked={checked}/>
                <ListItemText primary={name}/>
            </ListItemButton>
        </ListSubheader>
    );
};

MyListSubheader.muiSkipListHighlight = true;

export const MyListSubheaderSearch = ({searchValue, setSearchValue}) => {
    const {t} = useTranslation();

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key !== "Escape") {
            e.stopPropagation();
        }
    }

    return (
        <ListSubheader
            sx={{
                zIndex: 5,
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <TextField
                fullWidth
                autoFocus
                placeholder={t("placeholders:search_select")}
                value={searchValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </ListSubheader>
    );
};

MyListSubheaderSearch.muiSkipListHighlight = true;
