import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Typography } from "@mui/material";

const CheckboxSimple = ({
  label,
  onChange,
  value,
  disabled,
  tLabel = true,
  className = "",
 
  shouldTriggerValidation,
  trigger,
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      className="checkbox"
      label={
        <Typography className={`checkbox-label ${className}`}>
          {tLabel ? t(label) : label}
        </Typography>
      }
      control={
        <Checkbox checked={!!value}  onChange={onChange} disabled={disabled} />
      }
    />
  );
};

export default CheckboxSimple;
