import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import ProfileDropdown from "./ProfileDropdown";
import { NotificationPopUp } from "../../components/NotificationPopUp/NotificationPopUp";
import AdditionalInfo from "./AdditionalInfo";

const Header = () => {

  return (
    <AppBar className="app_header" position="absolute">
      <Toolbar
        sx={{
          height: "var(--heighHeader)",
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "var(--bgHeader)",
        }}
      >
        <AdditionalInfo/>
        <NotificationPopUp />
        <ProfileDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
