import React, { useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import InputText from "../InputText";
import TextField from '@mui/material/TextField';
import { useLazyGetAddressSelectQuery } from "../../../middleware/addressDataApi";
import { useAppSelector } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";
import { Box} from "@mui/material";
export const AddressInputInbuilt = ({
  label,
  disabled = false,
  onChange,
  error,
  value,
  bgcolor,
  shouldSetCoordinates,
  className
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");

  const [getAddressSelect] = useLazyGetAddressSelectQuery();
  const { addressData } = useAppSelector(state => state.addressData);
  const getRequestNewValue = (newValue) => {
    const { data } = newValue || {};
    const requestData = {
      city: data?.city || "",
      street: data?.street || "",
      building: data?.house || "",
    };
    if (shouldSetCoordinates) {
      requestData.coordinates = {
        longitude: data?.geo_lon,
        latitude: data?.geo_lat,
      };
    }
    return requestData;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      getAddressSelect("");
      return undefined;
    }

    if (active) {
      getAddressSelect(inputValue)
    }

    return () => {
      active = false;
    };
  }, [inputValue, getAddressSelect]);

  
  return (
   
      <Autocomplete
        id="address"
        getOptionLabel={(option) => option.value}
        ListboxProps={{ style: { maxHeight: '100px' } }}
        options={addressData?.suggestions || []}
        size= "small"
        filterOptions={(x) => x}
       // sx={{maxHeight: "24px", background: bgcolor ? bgcolor : "inherit", height: "24px !mportant" }}
       // InputProps={{ style: { height: 10 } }}
        autoComplete
        
        filterSelectedOptions
        disabled={disabled}
        defaultValue={() => (
          value?.city ? (
            {
              data: {},
              unrestricted_value: "",
              value: `${value.city} ${value.street} ${value.building}`
            }
          ) : null
        )}
        onChange={(event, newValue) => {
          onChange(getRequestNewValue(newValue));
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (

          <TextField
            {...params}
            sx={{ "& .MuiInputBase-root": { height: "24px !important",lineHeight:"24px",backgroundColor:"var(--bgLightGrey)",fontSize:"14px" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E3E7" // this is the original border color
              },
              "&:hover fieldset": {
                borderColor: "#E0E3E7" // use the original border color on hover
              },
            },
            "& .MuiOutlinedInput-input": { height: "24px !important",lineHeight:"24px",marginLeft :"-6px !important",marginTop :"-8px !important",fontSize:"14px",color:"var(--additionalGrey)" },
          }}
            
            label={t(label)}
            
            error={!!error}
          />
        )}
      />
  
  );
};