import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const AccordionSection = ({expandedAccordions,handleAccordionChange,idd, title, children }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const handleExpandAll = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      square={true}
      disableGutters={true}
      expanded={(expandedAccordions) ? expandedAccordions.includes(idd) : expanded}
      onChange={handleAccordionChange ? handleAccordionChange(idd) : handleExpandAll}
      sx={{ 
        background: "var(--bgLightGrey)",
        "& .MuiAccordionSummary-expandIconWrapper svg": { color: "var(--black)" },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography className="form-section-label">{t(`title:${title}`)}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "16px", background: "var(--bgLightGrey)" }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;