import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";

const Header = ({ handleModalDebbuging }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleId } = useAppSelector((state) => state.users.info);
  console.log("role",roleId)
  return (
    <Box className="controllers-header">
      <Typography className="title-page">
        {t("title_page:controllers")}
      </Typography>
      <Box className="controllers-header-btns_field">
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={handleModalDebbuging.bind(null, true)}
          sx={{ height: "49px" }}
        >
          {t("button:debugging")}
        </Button>
        {(roleId == 1 || roleId == 2) && <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => navigate(PATHS.ADD_CONTROLLER)}
          sx={{ height: "49px", marginRight: "16.8px" }}
        >
          {t("button:add_controller")}
        </Button>
        }
      </Box>
    </Box>
  );
};

export default Header;
