import React from "react";
import { Box } from "@mui/material";

import "./styles/style.css";

import { LeftBlockLogin } from "./LeftBlockLogin";

const LogIn = ({ children }) => {
  return (
    <Box className="login_wrapper">
      <LeftBlockLogin />
      <Box className="right_block">
        {children}
      </Box>
    </Box>
  );
};

export default LogIn;
