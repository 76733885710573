import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../hooks/redux";
import "./styles/styles.css";
import { useLazyGetFacilitiesForTestQuery, useUpdateFacilityInTestMutation } from "../../../../middleware/facilitiesApi";
import { useAction } from "../../../../hooks/actions";
import { columns } from "./form-config";

import TableBasic from "../../../components/TableBasic";

export const ModalTableSetOnTo = ({
  open,
  setOpen,
  protectionObjectId
}) => {
  const { t } = useTranslation();
  const {
    facilitiesForTest,
  } = useAppSelector((state) => state.facilities);
  const [pageNumber, changePageNumber] = useState(0);
  const [
    getFacilitiesForTest,
    { isLoading, isError, refetch  },
  ] = useLazyGetFacilitiesForTestQuery({ pageNumber, protectionObjectId });
  const [updateFacilityInTest, { error: errorAdd, isSuccess: isSuccessAdd }] = useUpdateFacilityInTestMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest } = useAction();

  const handleFacilityInTest = ({ id, isInTest }) => {
    updateFacilityInTest({ id, isInTest });
  };

  const rows = useMemo(
    () => {
      return facilitiesForTest?.data?.length 
        ? facilitiesForTest.data.map(({ id, name, isInTest }) => ({
          name,
          actionField: {
            id,
            isInTest,
            action: (data) => handleFacilityInTest({ ...data }), 
          }
        }))
        : [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facilitiesForTest]
  );

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (!protectionObjectId || protectionObjectId == "все")
      protectionObjectId = "";

    getFacilitiesForTest({
      pageNumber,
      protectionObjectId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber], [protectionObjectId]);
  /*useEffect(() => {
    refetch({ pageNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);*/

  const recordsCount = useMemo(
    () => facilitiesForTest && facilitiesForTest?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facilitiesForTest]
  );

  useEffect(() => {
    setIsErrorRequest(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsErrorSubmit(errorAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAdd]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal_table modal select_columns"
        maxWidth="md"
      >
        <DialogTitle className="modal-title-between">
          <Typography className="modal-title">
            {t("title:staging_to_TO")}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 36px 15px" }}>
          <TableBasic
            columns={columns}
            rows={rows}
            withSearchField={false}
            isLoading={isLoading}
            pageNumber={pageNumber}
            changePageNumber={changePageNumber}
            count={recordsCount}
            minHeight="56vh"
            maxHeight="56vh"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};