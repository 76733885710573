import React from "react";
import { Box, Container, Breadcrumbs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { excludeInBreadcrumb, rootPaths } from "../../AppRoutes";

import "./styles/style.css";

import ModalClosePage from "../../components/Modals/ModalClosePage";
import {
  ErrorRequest,
  ErrorSubmit,
  WarningRequest,
  SuccessSubmit,
  ErrorPermissionRequest,
  ErrorSimpleNotificationModal,
} from "../../components/Notifications";

const PageContent = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { titlePage } = useAppSelector((state) => state.settings);
  const { isChangeForm } = useAppSelector((state) => state.organizations);
  const { setPathForModalClosePage } = useAction();

  const handleChangePage = (to) => {
    isChangeForm ? setPathForModalClosePage(to) : navigate(to);
  };
  const pathSnippets = pathname.split("/").filter((i) => i);
  if (pathSnippets.length > 0) {
    const lastSegment = pathSnippets[pathSnippets.length - 1];
    if (lastSegment.match(/^\d+$/)) {
      pathSnippets.pop();
    }
  }
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const result = _.split(/(?=[A-Z][a-z])/).join(" ");
    const isInclude = excludeInBreadcrumb.includes(`/${result}`);
    const isRootPath = rootPaths.includes(`/${result}`);
    if (isInclude) return "";

    if (isRootPath)
      return (
        <Typography key="3" className="breadcrumbs-root">
          {t(`title_page:${result}`)}
        </Typography>
      );

    return (
      <span key={url}>
        {index === pathSnippets.length - 1 ? (
          <Typography
            key="3"
            color="text.primary"
            className="breadcrumbs-current"
          >
            {titlePage || t(`title_page:${result}`)}
          </Typography>
        ) : (
          <Typography
            key="3"
            onClick={() => handleChangePage(url)}
            className="breadcrumbs-link"
          >
            {t(`title_page:${result}`)}
          </Typography>
        )}
      </span>
    );
  });

  return (
    <Box className="page-content">
      <Container maxWidth={false} sx={{ paddingTop: "80px" }}>
        <Breadcrumbs
          className="breadcrumbs"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {extraBreadcrumbItems}
        </Breadcrumbs>
        <Box>{children}</Box>
      </Container>
      <ModalClosePage />
      <ErrorRequest />
      <WarningRequest/>
      <ErrorSubmit />
      <SuccessSubmit />
      <ErrorPermissionRequest />
      <ErrorSimpleNotificationModal />
    </Box>
  );
};

export default PageContent;
