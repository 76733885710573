import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { SetSMSCodeDialog } from "../SetSMSCodeDialog";
import { useLazySendPhoneVerificationCodeQuery } from "../../../../middleware/signingApi";
import { useAction } from "../../../../hooks/actions";

export const ConfirmPhoneNumber = ({ isConfirmed }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { setIsErrorRequest } = useAction();
  const [sendPhoneVerificationCode, { error }] = useLazySendPhoneVerificationCodeQuery();

  const handleOpen = () => {
    setIsDisabled(true);
    sendPhoneVerificationCode().then(({ error }) => {
      !error && setOpen(true);
      setIsDisabled(false);
    });
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Box>
      <Button
        className="btn btn-outlined-primary"
        variant="outlined"
        onClick={handleOpen}
        disabled={isConfirmed || isDisabled}
      >
        {t(`button:${isConfirmed ? "confirmed" : "confirm_phone_number"}`)}
      </Button>
      <Typography className="default-label-wrap" sx={{ mb: 3, mt: 1 }}>
        {t("label:confirm_phone_number")}
      </Typography>

      {!isConfirmed &&
        <SetSMSCodeDialog
          open={open}
          onClose={handleClose}
          actionRequest={sendPhoneVerificationCode}
        />
      }
    </Box>
  );
};
