import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItemText } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectSimpleInbuilt = ({
  options,
  label,
  onChange,
  error,
  value,
  withEmptyField = false,
  bgcolor,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState(
    withEmptyField ? value : value || t(label)
  );

  const handleChange = (v) => {
    const updatedValue = v === t(label) ? (withEmptyField ? "" : v) : v;

    setCurrentValue(updatedValue);
    onChange(v === t(label) ? "" : v);
  };

  return (
    <FormControl fullWidth {...rest} sx={{ background: bgcolor ? bgcolor : "inherit", height: "24px" }}>
      {!withEmptyField && <InputLabel>{t(label)}</InputLabel>}
      <Select
        value={currentValue}
        onChange={({ target }) => handleChange(target?.value)}
        renderValue={(value) => {
          const { name } =
            options.find((option) => option.value === value) || {};
          return (
            <ListItemText
              primary={
                value === t(label)
                  ? withEmptyField && !currentValue
                    ? name
                    : t(label)
                  : name
              }
            />
          );
        }}
        MenuProps={MenuProps}
        sx={{ ".MuiSelect-select": { height: 0 } }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value} sx={{ height: ITEM_HEIGHT }}>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimpleInbuilt;
