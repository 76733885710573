import React, {useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const InputPassword = ({label, disabled = false, onChange, error, value, setValue, ...rest}) => {

    const {t} = useTranslation();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleChangePassword = (e) => {
        onChange(e.target.value.replace(/\s/g, ""));
    };

    return (
        <TextField
            onChange={handleChangePassword}
            label={t(label)}
            variant="outlined"
            error={!!error}
            helperText={error?.message ? t(`error_message:${error?.message}`) : ""}
            fullWidth
            value={value}
            disabled={disabled}
            sx={{
                input: {
                    "&::-ms-reveal": {display: "none"},
                    "&::-ms-clear": {display: "none"}
                },
            }}
            InputProps={{
                type: showPassword ? "text" : "password",
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    );
};

export default InputPassword;
