import React from "react";
import {
  Box,
  CardMedia,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import newLogo from "../../../image/logoVDPO_blue.png";

import VdpoLogoText from "../../components/icons/VdpoLogoText";

export const LeftBlockLogin = () => {
  const { t } = useTranslation();

  return (
    <Box className="left_block">
      <Box  className="left_block-logo">
        <CardMedia
          component="img"
          image={newLogo}
          alt="ВДПО"
        />
      </Box>

      <Box className="left_block-content">
        <Box className="left_block-content-logo_text_field">
          <VdpoLogoText />
        </Box>
        <Typography className="left_block-content-text">
          {t("login_page:auth_title_v2")}
        </Typography>
      </Box>
    </Box>
  );
};
