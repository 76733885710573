import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import {Typography} from "@mui/material";
import { dateFormat, dateFormatForSubmit } from "../../../constants";

const DatePickerInbuild = ({ placeholder, onChange, value, bgcolor, className }) => {
  const style = {
    width: "100%",
    backgroundColor: bgcolor ? bgcolor : "inherit",
    height: "24px",
    "& .MuiInputLabel-root": {
      color: "var(--black)",
      fontFamily: "var(--IBM)",
      fontSize: "var(--standart)",
      fontWeight: "500",
      top: className ? "-11px" : "-16px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "& .MuiOutlinedInput": className ? className : {
        color: "var(--black)",
        fontFamily: "var(--IBM)",
        fontSize: "var(--standart)",
        fontWeight: "500",
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    "& .MuiFormLabel-filled.MuiInputLabel-shrink": {
      display: "none",
      position: "absolute",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& .MuiOutlinedInput-input": { padding:"0 8px" },
    "& .MuiInputBase-root": {
      paddingRight: "0",
      height: className ? "24px" : "inherit",
    },
    "& .MuiButtonBase-root": {
      padding: "0",
      marginRight: "2px",
      "& svg": {
        width: "20px",
      }
    }
  };

  const handleChange = (e) => {
    if(!e) {
        onChange(null)
        return
    }
    const date = e.$d
    const newValue = isNaN(Date.parse(date))
      ? ""
      : dayjs(date).format(dateFormatForSubmit);
    onChange(newValue);
  }

  const slots = value ? {
    inputAdornment: () => (
      <IconButton
        type="button"
        aria-label="clean search"
        onClick={() => {
          onChange("");
        }}
        sx={{ padding: "0" }}
      >
        <ClearIcon sx={{ fontSize: 22 }} />
      </IconButton>
    )
  } : {};

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ru"
      localeText={
        ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
     
      <DatePicker
        className={`date-picker ${className}`}
        sx={style}
        label={placeholder}
        format={dateFormat}
        fullWidth
        value={value ? dayjs(value) : null}
        onAccept={handleChange}
        slots={slots}
      />

    </LocalizationProvider>
  );
};

export default DatePickerInbuild;
