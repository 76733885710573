import { useTranslation } from "react-i18next";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  formConfigBasicInf,
  formConfigBank,
  formConfigOrganization,
  formFields, formConfigAddresses,
} from "./form-config";

import FormItem from "../../components/FormItem";

const BasicInformation = ({ control, errors, indCheck, setValue, addressFromDadata, setaddressFromDadata }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      square={true}
      className="basic_information"
      disableGutters={true}
      sx={{
        "& .MuiAccordionSummary-expandIconWrapper svg": {
          color: "var(--black)",
        },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography className="form-section-label">
          {t("title:basic_information")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "16px" }}>
        <>
          <Box className="organization_form_field">
            {formConfigOrganization.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={`${[formFields.organizationRegistrationInfo]}.${name}`}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </Box>

          {formConfigBasicInf.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={`${[formFields.organizationRegistrationInfo]}.${name}`}
              label={label}
              input={input}
              {...rest}
            />
          ))}

          <Box
            sx={{
              p: "20px",
              m: "20px 0",
              border: "1px solid var(--borderLightGrey)"
            }}
          >
            {formConfigAddresses.map(({ name, CustomFormItem, ...rest }) => {
              const Component = CustomFormItem || FormItem;
              return (
                <Component
                  key={name}
                  name={`${[formFields.organizationRegistrationInfo]}.${name}`}
                  control={control}
                  indCheck={indCheck}
                  addressFromDadata={addressFromDadata}
                  setaddressFromDadata={setaddressFromDadata}
                  errors={errors}
                  {...rest}
                />
              )
            })}
          </Box>

          <Box>
            {formConfigBank.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={`${[formFields.organizationRegistrationInfo]}.${[
                  formFields.bankDetails,
                ]}.${name}`}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicInformation;
