import React from "react";
import { Typography, Box,Button } from "@mui/material";
import "./styles/styles.css";
import { AvatarFormItemConfig, profileContactConnectionFormConfig } from "./form-config";
import FormItem from "../../../components/FormItem";
import { useAppSelector } from "../../../../hooks/redux";
import { ConfirmPhoneNumber } from "../ConfirmPhoneNumber";


const ProfileContactData = ({ control, errors }) => {
  const { firstName, lastName, surname, role, phoneVerified } =
    useAppSelector((state) => state.users.info);
   
  
  return (
    <>
      <Box className="profile_contact">
        {AvatarFormItemConfig.map(
          ({
             id,
             name,
             label,
             input,
             expended,
             block,
             title,
             Component,
             ...rest
           }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              expended={expended}
              {...rest}
            />
          ),
        )}
        <Box className="profile_contact-contacts">
          <Typography className="profile_contact-fio">{`${lastName} ${firstName} ${surname}`}</Typography>
          <Box className="profile_contact-job_employments_block">
            <Typography>{role}</Typography>
          </Box>
          <Box className="profile_contact-connection">
            {profileContactConnectionFormConfig.map(
              ({
                 id,
                 name,
                 label,
                 input,
                 expended,
                 block,
                 title,
                 Component,
                 ...rest
               }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  expended={expended}
                  {...rest}
                />
              ),
            )}
          </Box>
          
        </Box>
      </Box>
      <ConfirmPhoneNumber isConfirmed={phoneVerified} />
    </>
  );
};

export default ProfileContactData;
