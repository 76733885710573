import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectSimple from "../../components/SelectSimple";
import { SelectSimpleObject, SelectSimpleEquipKinds } from "./Components";
import "./styles/style.css";
import FormItem from "../../components/FormItem";
import { useAppSelector } from "../../../hooks/redux";
import { DragDropContext, Droppable, Draggable as DraggableListItem } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAction } from "../../../hooks/actions";
import {
  useAddEquipForPeriodsMutation,
  useDeleteEquipForPeriodsMutation,
  useMoveEquipBetweenPeriodsMutation,
  useHasfacilitiesforplaningQuery,
  useLazyGetEquipmentKindsFacilityQuery,
  useResetPlanningMutation,
  useLazyGetPlanPeriodsQuery
} from "../../../middleware/facilitiesApi";
import {
  useDistributeEquipmentsForPlanMutation,
  useLazyGetEquipForPlanPeriodQuery,
  useLazyGetAvailableEquipmentsForPlanningQuery,
} from "../../../middleware/equipmentsApi";
import dayjs from "dayjs";
import Draggable from "react-draggable";
import { ModalFullPlan } from '../../components/Modals/ModalFullPlan';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RefreshIcon from '@mui/icons-material/Refresh';

const Acts = () => {
  const { t } = useTranslation();
  const dateFormat = "DD.MM.YYYY";
  const containerRef = useRef(null);
  const { planPeriods } = useAppSelector((state) => state.facilities);

  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest, setIsWarningRequest, setEquipForPlan } = useAction();
  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    setValue,
    getValues,
    watch,
    resetField,
    reset,
  } = useForm();

  const formFields = {
    object: "object",
    equipKinds: "equipKinds",
  };
  const { data: hasfacil } = useHasfacilitiesforplaningQuery();


  const [
    getEquipKinds,
    { isError: isErrorgetEquipKinds, isLoading: isLoadinggetEquipKinds },
  ] = useLazyGetEquipmentKindsFacilityQuery();

  const [getPlanPeriods, { isLoading: isLoadingGetPlanPeriods, error: isErrorpanPeriods }] =
    useLazyGetPlanPeriodsQuery();

  const [getAvailEquipForPlan, { isLoading: isLoadinggetAvailEquipForPlan, error: isErrorAvailequip }] =
    useLazyGetAvailableEquipmentsForPlanningQuery();
  const [getEquipForPlanPeriod, { isLoading: isLoadinggetEquipForPlanPeriod, error: isErrorequip }] =
    useLazyGetEquipForPlanPeriodQuery();
  const [
    distributeEquipments,
    { isError: isErrordistribute, isSuccess: isSuccessdistribute },
  ] = useDistributeEquipmentsForPlanMutation();

  const [
    addEquipForPeriod,
    { isError: isErroraddEquip, isSuccess: isSuccessaddEquip },
  ] = useAddEquipForPeriodsMutation();
  const [
    deleteEquipForPeriod,
    { isError: isErrordeleteEquip, isSuccess: isSuccessdeleteEquip },
  ] = useDeleteEquipForPeriodsMutation();
  const [
    moveEquipBetweenPeriods,
    { isError: isErrormoveEquip, isSuccess: isSuccessmoveEquip },
  ] = useMoveEquipBetweenPeriodsMutation();

  const [resetPlanning, { error: errorresetPlanning /* isLoading */ }] =
    useResetPlanningMutation();




  const config = [
    {
      id: 1,
      name: formFields.object,
      label: `label:${formFields.object}`,
      type: "select-text",
      rules: {},
      width: "320px",
      sx: { padding: "0px" },
      isCanDisabled: false,
      input: (props) => <SelectSimpleObject {...props} />,
    },
    {
      id: 2,
      name: formFields.equipKinds,
      label: `label:${formFields.equipKinds}`,
      type: "select-number",
      rules: {},
      width: "500px",
      sx: { padding: "0px" },
      isCanDisabled: true,
      input: (props) => <SelectSimpleEquipKinds {...props} />,
    },
  ];


  const objectId = watch(formFields.object);
  const equipKinds = watch(formFields.equipKinds);
  const [periodsData, setperiodsData] = useState([]);
  const [availperiodsData, setavailperiodsData] = useState([]);
  const [isDistributed, setisDistributed] = useState(false);
  const [isDropped, setisDropped] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selectedItemsListId, setSelectedItemsListId] = useState('');
  const [searchValues, setSearchValues] = useState({});
  const [searchAvailValues, setsearchAvailValues] = useState("");
  const [openFullPlan, setOpenFullPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleSearchChange = (event, planId) => {
    setSearchValues({
      ...searchValues,
      [planId]: event.target.value,
    });
  };
  const handleAvailChange = (event) => {
    setsearchAvailValues(event.target.value)
  };
  const handleKeyPress = async (event, planId) => {
    if (event.key === 'Enter') {
      console.log("planId", searchValues[planId])
      let res = periodsData
      await getEquipForPlanPeriod({ pageNumber: 0, protectionObjectId: objectId, equipmentKindIds: equipKinds, planPeriodId: planId, filters: `&searchName=${searchValues[planId]}` }).then(({ data, isSuccess }) => {
        let index = res.findIndex(obj => obj.id === planId);
        res[index] = { ...res[index], pageNumber: data.pageNumber, hasMore: data.data.length < data.recordsCount ? true : false, recordsCount: data.recordsCount, equipment: data.data }
        setperiodsData(res)
      })
      setperiodsData([...periodsData])
    }
  };

  const handleAvailKeyPress = (event) => {
    if (event.key === 'Enter') {
      getAvailEquipForPlan({
        pageNumber: 0,
        protectionObjectId: objectId,
        equipmentKindIds: equipKinds,
        filters: `&searchName=${searchAvailValues}`
      }).then(({ data, isSuccess }) => {
        setavailperiodsData([{ pageNumber: data.pageNumber, hasMore: data.data.length < data.recordsCount ? true : false, recordsCount: data.recordsCount, equipment: data.data }])
      })
    }
  };

  useEffect(() => {
    setIsErrorRequest(isErrorpanPeriods || isErrorgetEquipKinds || isErrorAvailequip || isErrorequip || isErrordistribute || isErroraddEquip || isErrordeleteEquip || isErrormoveEquip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorpanPeriods, isErrorgetEquipKinds, isErrorAvailequip, isErrorequip, isErrordistribute, isErroraddEquip, isErrordeleteEquip, isErrormoveEquip]);

  useEffect(() => {
    setIsErrorSubmit(isErrorpanPeriods || isErrorgetEquipKinds || isErrorAvailequip || isErrorequip || isErrordistribute || isErroraddEquip || isErrordeleteEquip || isErrormoveEquip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorpanPeriods, isErrorgetEquipKinds, isErrorAvailequip, isErrorequip, isErrordistribute, isErroraddEquip, isErrordeleteEquip, isErrormoveEquip]);

  useEffect(() => {
    if (objectId) {
      setisDistributed(false)
      setperiodsData([])
      setavailperiodsData([])
      setSelectedItemIds([])
      setSelectedItemsListId('')
      getEquipKinds(objectId).then(
        ({ data, isSuccess }) =>
          isSuccess &&
          !!data?.length &&
          setValue(formFields.equipKinds, data[0].value)
      );
    }

  }, [objectId, getEquipKinds, setValue]);
  useEffect(() => {
    if (objectId) {
      setisDistributed(false)
      setperiodsData([])
      setavailperiodsData([])
      setSelectedItemIds([])
      setSelectedItemsListId('')
      getEquipKinds(objectId).then(
        ({ data, isSuccess }) =>
          isSuccess &&
          !!data?.length &&
          setValue(formFields.equipKinds, data[0].value)
      );
    }

  }, [objectId, getEquipKinds, setValue]);

  const InitPeriods = () =>{

    setisDistributed(false)
    setperiodsData([])
    setSelectedItemIds([])
    setSelectedItemsListId('')
    setavailperiodsData([])
    getPlanPeriods({ protectionObjectId: objectId, equipmentKindId: equipKinds }).then(({ data, isSuccess }) => {
      if (isSuccess && data?.length > 0) {
        distributeEquipments({
          equipmentKindId: equipKinds,
          protectionObjectId: objectId
        }).then(({ data, isSuccessdistribute }) => {
          setisDistributed(true)
        })
      }
    });
  }
  useEffect(() => {
    if (equipKinds) {
      InitPeriods()
    }
  }, [equipKinds]);

  const fetchFirstData = async () => {

    for (let i = 0; i < planPeriods.length; i++) {
      const response = await getEquipForPlanPeriod({ pageNumber: 0, protectionObjectId: objectId, equipmentKindIds: equipKinds, planPeriodId: planPeriods[i].id, filters: "" })
      setperiodsData(prevItems => [...prevItems, { ...planPeriods[i], pageNumber: response.data.pageNumber, hasMore: response.data.data.length < response.data.recordsCount ? true : false, recordsCount: response.data.recordsCount, equipment: response.data.data }])
    }
    getAvailEquipForPlan(
      {
        pageNumber: 0,
        protectionObjectId: objectId,
        equipmentKindIds: equipKinds,
        filters: ""
      }).then(({ data, isSuccess }) => {
        if (data.recordsCount != 0) {
          setavailperiodsData([{ pageNumber: data.pageNumber, hasMore: data.data.length < data.recordsCount ? true : false, recordsCount: data.recordsCount, equipment: data.data }])
        }
        else {
          setavailperiodsData([{ pageNumber: data.pageNumber, hasMore: false, recordsCount: data.recordsCount, equipment: [] }])
        }
      })
  };
  useEffect(() => {
    if (isDistributed) {
      setperiodsData([])
      setSelectedItemIds([])
      setSelectedItemsListId('')
      setavailperiodsData([])
      fetchFirstData();
    }
  }, [isDistributed]);

  useEffect(() => {
    if (hasfacil == false) {
      setIsWarningRequest(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasfacil]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccessdistribute || isSuccessaddEquip || isSuccessdeleteEquip || isSuccessmoveEquip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessdistribute, isSuccessaddEquip, isSuccessdeleteEquip, isSuccessmoveEquip]);

  const availfetchData = (e) => {

    let filter = ""
    if (searchAvailValues) {
      filter = `&searchName=${searchAvailValues}`
    }
    getAvailEquipForPlan({ pageNumber: availperiodsData[0].pageNumber + 1, protectionObjectId: objectId, equipmentKindIds: equipKinds, filters: filter }).then(({ data, isSuccess }) => {
      let newavailPeriod = [{ pageNumber: data.pageNumber, hasMore: data.data.length + availperiodsData[0].equipment.length < data.recordsCount ? true : false, equipment: [...availperiodsData[0].equipment, ...data.data] }];

      setavailperiodsData(newavailPeriod)
    })
  }
  const fetchData = (planId) => {
    let pagenum = 0
    periodsData.map(obj => {
      if (obj.id === planId) {
        pagenum = obj.pageNumber;
      }
    })
    let filter = ""
    if (searchValues[planId]) {
      filter = `&searchName=${searchValues[planId]}`
    }
    getEquipForPlanPeriod({ pageNumber: pagenum + 1, protectionObjectId: objectId, equipmentKindIds: equipKinds, planPeriodId: planId, filters: filter }).then(({ data, isSuccess }) => {
      let newPeriod = periodsData.map(obj => {
        if (obj.id === planId) {
          return { ...obj, pageNumber: data.pageNumber, hasMore: data.data.length + obj.equipment.length < data.recordsCount ? true : false, equipment: [...obj.equipment, ...data.data] };
        }
        return obj;
      })

      setperiodsData(newPeriod)
    })
  }

  const toggleSelection = (itemId, listId, allowMove) => {
    console.log("gg", selectedItemsListId, listId)
    if (allowMove) {
      if (selectedItemsListId != listId && selectedItemsListId != '' && !isDropped) {
        setSelectedItemIds([]);
        setSelectedItemsListId('');
      } else {

        if (selectedItemIds.includes(itemId)) {
          setSelectedItemIds(selectedItemIds.filter((id) => id !== itemId));
          setSelectedItemsListId(listId);
        } else {
          setSelectedItemIds([...selectedItemIds, itemId]);
          setSelectedItemsListId(listId);
        }
      }
      setisDropped(false)
    }


  };
  const handleMultiSelection = (itemId, listId, items, allowMove) => {
    const itemIds = items.map((item) => item.id);
    if (selectedItemIds.length === 0 || selectedItemsListId !== listId) {
      // No selected items yet or different list, update selected item list
      setSelectedItemIds([itemId]);
      setSelectedItemsListId(listId);
    } else {
      const startIndex = itemIds.indexOf(selectedItemIds[selectedItemIds.length - 1]);
      const endIndex = itemIds.indexOf(itemId);
      const newSelection = itemIds.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
      setSelectedItemIds(newSelection);
    }
  };
  const isItemSelected = (itemId, listId) => {
    return selectedItemIds.includes(itemId) && selectedItemsListId === listId;
  };
  const onContainerClick = () => {
    setSelectedItemIds([]); // Сброс выбранных элементов при клике за пределами списка
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    //console.log("peer", source[0].droppableId,destination[0].droppableId)
    if (source.droppableId != destination.droppableId) {
      if (source.droppableId === "availDrag") {
        const sourceList = availperiodsData[0].equipment;
        const destinationList = periodsData.find(object => object.id === destination.droppableId).equipment;
        const sourceItems = sourceList;
        const destinationItems = destinationList;

        const movedItems = selectedItemIds.map(id => {
          return {
            id,
            from: source.droppableId,
            to: destination.droppableId
          };
        });

        const updatedSourceItems = sourceItems.filter(item => !selectedItemIds.includes(item.id));
        const updatedDestinationItems = destinationItems.slice();
        const destTo = sourceItems.filter(item => selectedItemIds.includes(item.id))
        const destIds = destTo.map(obj => obj.id);
        console.log("gg", movedItems, sourceItems)

        updatedDestinationItems.splice(0, 0, ...destTo);


        let data = periodsData
        let availData = availperiodsData
        availData[0].equipment = updatedSourceItems
        data.find(object => object.id === destination.droppableId).equipment = updatedDestinationItems
        addEquipForPeriod({
          planPeriodId: destination.droppableId,
          equipmentIds: destIds
        })
        setavailperiodsData(availData)
        setperiodsData(data)
      } else if (destination.droppableId === "availDrag") {
        const sourceList = periodsData.find(object => object.id === source.droppableId).equipment;
        const destinationList = availperiodsData[0].equipment;
        const sourceItems = sourceList;
        const destinationItems = destinationList;

        const movedItems = selectedItemIds.map(id => {
          return {
            id,
            from: source.droppableId,
            to: destination.droppableId
          };
        });

        const updatedSourceItems = sourceItems.filter(item => !selectedItemIds.includes(item.id));
        const updatedDestinationItems = destinationItems.slice();
        const destTo = sourceItems.filter(item => selectedItemIds.includes(item.id))
        const destIds = destTo.map(obj => obj.id);

        updatedDestinationItems.splice(0, 0, ...destTo);


        let data = periodsData
        let availData = availperiodsData
        availData[0].equipment = updatedDestinationItems
        data.find(object => object.id === source.droppableId).equipment = updatedSourceItems
        console.log("gg",)

        deleteEquipForPeriod({
          planPeriodId: source.droppableId,
          equipmentIds: destIds
        })
        setavailperiodsData(availData)
        setperiodsData(data)
      }

      else {
        const sourceList = periodsData.find(object => object.id === source.droppableId).equipment;
        const destinationList = periodsData.find(object => object.id === destination.droppableId).equipment;
        const sourceItems = sourceList;
        const destinationItems = destinationList;

        const movedItems = selectedItemIds.map(id => {
          return {
            id,
            from: source.droppableId,
            to: destination.droppableId
          };
        });

        const updatedSourceItems = sourceItems.filter(item => !selectedItemIds.includes(item.id));
        const updatedDestinationItems = destinationItems.slice();
        const destTo = sourceItems.filter(item => selectedItemIds.includes(item.id))
        const destIds = destTo.map(obj => obj.id);

        updatedDestinationItems.splice(0, 0, ...destTo);

        let data = periodsData
        data.find(object => object.id === source.droppableId).equipment = updatedSourceItems
        data.find(object => object.id === destination.droppableId).equipment = updatedDestinationItems
        moveEquipBetweenPeriods({
          planPeriodId: source.droppableId,
          equipmentIds: destIds,
          newPlanPeriodId: destination.droppableId
        })
        setperiodsData(data)


      }
    }
    setisDropped(true)
    setSelectedItemIds([])
  };
  const handleOpenFullPlan = (id) => {
    setOpenFullPlan(true)
    setSelectedPlan(id)
  }
  const resetActPlanning = () => {
    resetPlanning({
      equipmentKindId: equipKinds,
      protectionObjectId: objectId

    }).then(() => {
      InitPeriods()
    })
  }

  return (

    <Box className="acts-container" >
      {openFullPlan &&
        <ModalFullPlan
          planId={selectedPlan}
          planData={periodsData}
          setOpen={setOpenFullPlan}
          handleKeyPress={handleKeyPress}
          open={openFullPlan}
          handleSearchChange={handleSearchChange}
          searchValues={searchValues}
        />

      }
      <Box className="plume-form_wrapper-select_container">
        {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
          <FormItem
            key={id}
            name={name}
            control={control}
            label={label}
            type={type}

            disabled={isCanDisabled ? !objectId || !hasfacil : false}
            input={(props) => <SelectSimple {...props} />}
            {...rest}
          />
        ))}
        <Box className="full_screen_icon-wrapper">
          <RefreshIcon
            className="full_screen_icon"
            sx={{
              marginTop: "7px",
              marginLeft: "30px"
            }}
            onClick={() => resetActPlanning()}
          />
        </Box>
      </Box>
      <Box className="disable-text-selection"  >
        <DragDropContext onDragEnd={onDragEnd}>
          <Box sx={{ display: "-webkit-box", marginTop: "40px" }}>
            <Box className="leaderPlans">
              {periodsData.map((item, index) => (
                <Draggable handle={`#TextBoard`}>
                  <Box className="leaderboard">
                    <Box sx={{ display: "flex" }}>
                      <OpenWithIcon id="TextBoard" className="openWithIcon" />
                      <Typography sx={{
                        height: "40px",
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontWeight: 600
                      }}>
                        {`${item.name}`}
                      </Typography>
                      <Typography sx={{
                        height: "40px",
                        marginLeft: "15px",
                        marginTop: "10px",
                        color: item.isBlocked ? "#ff0033" : "black",
                        fontWeight: 600
                      }}>
                        {`${dayjs(item.startDate).format(dateFormat)}-${dayjs(item.endDate).format(dateFormat)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", margin: "10px" }}>
                      <TextField
                        label="Поиск"
                        value={searchValues[item.id] || ''}
                        sx={{ width: "300px" }}
                        onChange={(event) => handleSearchChange(event, item.id)}
                        onKeyPress={(event) => handleKeyPress(event, item.id)}
                      />
                      <Box className="full_screen_icon-wrapper">
                        <FullscreenIcon
                          className="full_screen_icon"
                          sx={{
                            marginTop: "7px",
                            marginLeft: "30px"
                          }}
                          onClick={() => handleOpenFullPlan(item.id)}
                        />
                      </Box>
                    </Box>
                    <Droppable key={item.id} droppableId={item.id} isDropDisabled={item.isBlocked}>

                      {(provided, snapshot) => (
                        <Box id={`scrollableDiv${index}`}
                          className="leaderboard__profiles"
                          {...provided.droppableProps}
                          sx={{
                            background: item.isBlocked ? "rgba(2,2,2,0.3)" : snapshot.isDraggingOver ? 'transparent' : 'transparent',
                            transition: 'filter 0.3s ease-in-out',
                            filter: snapshot.isDraggingOver ? 'blur(5px)' : 'none', // применяем размытие к списку при перетаскивании
                          }}
                          ref={provided.innerRef}>

                          <InfiniteScroll
                            key={`infinite${index}`}
                            dataLength={(item.pageNumber + 1) * 15}
                            next={() => { fetchData(item.id) }}
                            style={{ overflow: "hidden" }}
                            hasMore={item.hasMore}
                            loader={<Box sx={{ textAlign: "center" }}><CircularProgress /></Box>}

                            scrollableTarget={`scrollableDiv${index}`}
                          >
                            {item?.equipment.map((equipItem, index) => (
                              <DraggableListItem key={equipItem.id} draggableId={equipItem.id} isDragDisabled={!isItemSelected(equipItem.id, item.id)} index={index}>
                                {(provided, snapshot) => (
                                  <Box
                                    className="leaderboard__profile"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    onClick={(e) => {

                                      if (e.shiftKey) {
                                        handleMultiSelection(equipItem.id, item.id, item?.equipment, equipItem.allowMove);
                                      } else {
                                        toggleSelection(equipItem.id, item.id, equipItem.allowMove);
                                      }
                                    }}

                                    sx={{
                                      ...provided.draggableProps.style,
                                      backgroundColor: isItemSelected(equipItem.id, item.id)
                                        ? 'rgb(25,118,210,0.1)'
                                        : snapshot.isDragging
                                          ? 'lightgrey'
                                          : 'white',
                                      zIndex: snapshot.isDragging ? '999999999' : 1000,
                                    }}
                                  >
                                    <Box>

                                      {snapshot.isDragging && isItemSelected(equipItem.id, item.id) && (
                                        <Box style={{
                                          border: "2px solid var(--main)",
                                          padding: "2px",
                                          borderRadius: "50px",
                                          width: "15px",
                                          position: "absolute",
                                          right: "5px",
                                          zIndex: '999999999',
                                          top: "5px",
                                          height: "15px",
                                          textAlign: "center",
                                        }}>
                                          <Typography sx={{ fontWeight: "bold", lineHeight: "15px", fontSize: "14px" }}>{selectedItemIds.length}</Typography>
                                        </Box>
                                      )}
                                      <Typography sx={{ width: "300px" }}>{`${equipItem.name} ${equipItem.plume ? "шлейф 0"+equipItem.plume : ""}`} </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </DraggableListItem>
                            ))}
                            {provided.placeholder}
                          </InfiniteScroll>
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </Draggable>




              ))}
            </Box>

            {(equipKinds) &&
              availperiodsData?.map((item, index) => (
                <Draggable handle="#availTextBoard">
                  <Box className="leaderboard">
                    <Box sx={{ display: "flex" }}>
                      <OpenWithIcon id="availTextBoard" className="openWithIcon" />
                      <Typography sx={{
                        height: "40px",
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontWeight: 600
                      }}>
                        {"Нераспределенное оборудование"}
                      </Typography>
                    </Box>
                    <Box sx={{ margin: "10px" }}>
                      <TextField
                        label="Поиск"
                        value={searchAvailValues}
                        sx={{ width: "350px" }}
                        onChange={handleAvailChange}
                        onKeyPress={(event) => handleAvailKeyPress(event)}
                      />
                    </Box>
                    <Droppable key={"availDrag"} droppableId={"availDrag"}>

                      {(provided, snapshot) => (
                        <Box id={"availscrollableDiv"}
                          className="leaderboard__profiles"
                          {...provided.droppableProps}
                          sx={{
                            background: item.isBlocked ? "rgba(2,2,2,0.3)" : snapshot.isDraggingOver ? 'transparent' : 'transparent',
                            transition: 'filter 0.3s ease-in-out',
                            filter: snapshot.isDraggingOver ? 'blur(5px)' : 'none', // применяем размытие к списку при перетаскивании
                          }}
                          ref={provided.innerRef}>

                          <InfiniteScroll
                            key={`infinite`}
                            dataLength={(Number(item.pageNumber + 1)) * 15}
                            next={(e) => { availfetchData(e) }}
                            style={{ overflow: "hidden" }}
                            hasMore={item?.hasMore}
                            loader={<Box sx={{ textAlign: "center" }}><CircularProgress /></Box>}
                            scrollableTarget={"availscrollableDiv"}
                          >
                            {item?.equipment?.map((equipItem, index) => (
                              <DraggableListItem key={equipItem.id} draggableId={equipItem.id} isDragDisabled={!isItemSelected(equipItem.id, item.id)} index={index}>
                                {(provided, snapshot) => (
                                  <Box
                                    className="leaderboard__profile"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    onClick={(e) => {
                                      if (e.shiftKey) {
                                        handleMultiSelection(equipItem.id, item.id, item?.equipment, true);
                                      } else {
                                        toggleSelection(equipItem.id, item.id, true);
                                      }
                                    }}

                                    sx={{
                                      ...provided.draggableProps.style,
                                      backgroundColor: isItemSelected(equipItem.id, item.id)
                                        ? 'rgb(25,118,210,0.1)'
                                        : snapshot.isDragging
                                          ? 'lightgrey'
                                          : 'white',
                                      zIndex: snapshot.isDragging ? '999999999' : 1000,
                                    }}
                                  >
                                    <Box>

                                      {snapshot.isDragging && isItemSelected(equipItem.id, item.id) && (
                                        <Box style={{
                                          border: "2px solid var(--main)",
                                          padding: "2px",
                                          borderRadius: "50px",
                                          width: "15px",
                                          position: "absolute",
                                          right: "5px",
                                          zIndex: '999999999',
                                          top: "5px",
                                          height: "15px",
                                          textAlign: "center",
                                        }}>
                                          <Typography sx={{ fontWeight: "bold", lineHeight: "15px", fontSize: "14px" }}>{selectedItemIds.length}</Typography>
                                        </Box>
                                      )}
                                      <Typography sx={{ width: "300px" }}>{`${equipItem.name} ${equipItem.plume ? "шлейф 0"+equipItem.plume : ""}`} </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </DraggableListItem>
                            ))}
                            {provided.placeholder}
                          </InfiniteScroll>
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </Draggable>
              ))}
          </Box>
        </DragDropContext>
      </Box >
    </Box >

  );
};

export default Acts;
