import React, { useEffect } from "react";
import FormItem from "../../components/FormItem";
import { useFieldArray, useWatch } from "react-hook-form";
import { formFields } from "./form-config";
import { Box } from "@mui/material";
import { fireCompartmentShortConfig, templateCompartment } from "../FireCompartments/FireCompartmentFields/form-config";

const FireCompartments = ({ control, errors, sectionName, name, rules,disabled }) => {

  const updatedFireCompartmentsCount = useWatch({
    control,
    name: `${sectionName}.${formFields.fireCompartmentsCount}`,
  });

  const { fields: fireCompartments, append, remove } = useFieldArray({
    control,
    name: `${sectionName}.${name}`,
  });

  useEffect(() => {
    const currentFireCompartmentsCount = fireCompartments.length;
    const updatedFireCompartmentsCountNumber = updatedFireCompartmentsCount ?
      Number(updatedFireCompartmentsCount)
      : currentFireCompartmentsCount;

    if (updatedFireCompartmentsCountNumber > 10) {
      return;
    }
    const countDifference = updatedFireCompartmentsCountNumber - currentFireCompartmentsCount;
    if (countDifference > 0) {
      append(Array(countDifference).fill(templateCompartment));
    } else {
      const itemsToRemoveIndexes = [...Array(currentFireCompartmentsCount - updatedFireCompartmentsCountNumber + 1).keys()]
        .map(x => x + updatedFireCompartmentsCountNumber);
      remove(itemsToRemoveIndexes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFireCompartmentsCount, append, remove]);

  return fireCompartments.map((item, index) => (
      <Box
        key={item.id}
        sx={{
          flexBasis: "100%",
          alignItems: "flex-start",
        }}
      >
        {fireCompartmentShortConfig.map(({ name: itemName, CustomFormItem, label, input, type, ...rest }) => (
          CustomFormItem ?
            <CustomFormItem
              key={`${sectionName}.${name}.${index}.${itemName}`}
              control={control}
              errors={errors}
              disabled={disabled}
              name={`${sectionName}.${name}.${index}.${itemName}`}
              {...rest}
            /> :
            <FormItem
              key={`${sectionName}.${name}.${index}.${itemName}`}
              control={control}
              errors={errors}
              disabled={disabled}
              name={`${sectionName}.${name}.${index}.${itemName}`}
              label={label}
              input={input}
              rules={rules}
              type={type}
              {...rest}
            />
        ))}
      </Box>
    ),
  );
};

export default FireCompartments;
