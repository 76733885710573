import React, { useEffect, useState } from "react";
import { Box, Modal, Button, CircularProgress, Typography, IconButton,TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable as DraggableListItem } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component'
import dayjs from "dayjs";

export const ModalFullPlan = ({ planId, planData, setOpen, open,handleKeyPress,handleSearchChange,searchValues
}) => {
    const data  = planData.find(item => item.id === planId)
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [selectedItemsListId, setSelectedItemsListId] = useState('');
    const [isDropped, setisDropped] = useState(false);
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };
    const dateFormat = "DD.MM.YYYY";
    
    const toggleSelection = (itemId, listId, allowMove) => {
        console.log("gg", selectedItemsListId, listId)
        if (allowMove) {
          if (selectedItemsListId != listId && selectedItemsListId != '' && !isDropped) {
            setSelectedItemIds([]);
            setSelectedItemsListId('');
          } else {
    
            if (selectedItemIds.includes(itemId)) {
              setSelectedItemIds(selectedItemIds.filter((id) => id !== itemId));
              setSelectedItemsListId(listId);
            } else {
              setSelectedItemIds([...selectedItemIds, itemId]);
              setSelectedItemsListId(listId);
            }
          }
          setisDropped(false)
        }
    
    
      };
      const handleMultiSelection = (itemId, listId, items, allowMove) => {
        const itemIds = items.map((item) => item.id);
        if (selectedItemIds.length === 0 || selectedItemsListId !== listId) {
          // No selected items yet or different list, update selected item list
          setSelectedItemIds([itemId]);
          setSelectedItemsListId(listId);
        } else {
          const startIndex = itemIds.indexOf(selectedItemIds[selectedItemIds.length - 1]);
          const endIndex = itemIds.indexOf(itemId);
          const newSelection = itemIds.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
          setSelectedItemIds(newSelection);
        }
      };
      const isItemSelected = (itemId, listId) => {
        return selectedItemIds.includes(itemId) && selectedItemsListId === listId;
      };
      console.log("FFFFF",searchValues)
    return (
        <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modal_address"
        >
            <Box className="modal_equipdialog-body">
                <Box className="disable-text-selection">
                    <Box className="modal_address-header_right">
                        <Box sx={{ display: "flex" }}>

                            <Typography sx={{
                                height: "40px",
                                marginLeft: "10px",
                                marginTop: "10px",
                                fontWeight: 600
                            }}>
                                {`${data.name}`}
                            </Typography>
                            <Typography sx={{
                                height: "40px",
                                marginLeft: "15px",
                                marginTop: "10px",
                                color: "black",
                                fontWeight: 600
                            }}>
                                {`${dayjs(data.startDate).format(dateFormat)}-${dayjs(data.endDate).format(dateFormat)}`}
                            </Typography>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        label="Поиск"
                        value={searchValues[planId] || ''}
                        sx={{ width: "300px",marginBottom:"15px"}}
                        onChange={(event) => handleSearchChange(event, planId)}
                        onKeyPress={(event) => handleKeyPress(event, planId)}
                      />
                    <Box
                        className="leaderboard__profiles-fullplan"   
                    >

                        {data?.equipment.map((equipItem, index) => (
                            <Box
                                className="leaderboard__profile"
                                onClick={(e) => {

                                    if (e.shiftKey) {
                                        handleMultiSelection(equipItem.id, planId, data?.equipment, equipItem.allowMove);
                                    } else {
                                        toggleSelection(equipItem.id, planId, equipItem.allowMove);
                                    }
                                }}
                                sx={{
                                    backgroundColor: isItemSelected(equipItem.id, planId)
                                      ? 'rgb(25,118,210,0.1)'
                                      
                                        : 'white',
                                  }}
                            >
                                <Box>
                                    <Typography sx={{ width: "300px" }}>{`${equipItem.name} ${equipItem.plume ? "шлейф 0"+equipItem.plume : ""}`} </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                </Box>
            </Box>
        </Modal>
    );
};