import React from "react";
import { sections } from "./form-config";
import AccordionSection from "../../../components/AccordionSection";

export const BasicInformationForm = ({ expandedAccordions,handleAccordionChange,equipmentSystems,readonly }) => {
  return sections.map(({ id,idd, title, Component, config }) => (
    <AccordionSection expandedAccordions={expandedAccordions}
    handleAccordionChange={handleAccordionChange} key={id} idd={idd} title={title}>

      {Component && (
        <Component
          equipmentSystems={equipmentSystems}
          readonly={readonly}
          config={config}
          sectionName={title}
        />
      )}
    </AccordionSection>
  ));
};