// libs imports
import React from "react";
import InputText from "../../../components/InputText";
import ReadOnlyPersonalData from "./ReadOnlyPersonalData";
import ProfileContactData from "../ProfileContactData";
import TelegramNotificationsAvailability from "./Components";
import InputHandleEditAvailability from "../../../components/InputHandleEditAvailability/InputHandleEditAvailability";
import { EmployeeProfile } from "../../EmployeeList/EmployeeProfile";
import { ReadOnlyEmployeePersonalData } from "../../EmployeeList/ReadOnlyEmployeePersonalData";
import { ReadOnlyEmployeeContactInfo } from "../../EmployeeList/ReadOnlyEmployeeContactInfo";
import Switcher from "../../../components/Switcher";
import SelectSimple from "../../../components/SelectSimple";
import { t } from "i18next";

export const formFields = {
  id: "id",
  firstName: "firstName",
  surname: "surname",
  lastName: "lastName",
  email: "email",
  phone: "phone",
  telegramNotificationsAvailability: "telegramNotificationsAvailability",
  readOnlyPersonalData: "readOnlyPersonalData",
  sendMessagesByEmail: "sendMessagesByEmail",
  messageKindToSendByEmail: "messageKindToSendByEmail"
};

export const notificationByEmailOptions = [
  { status: true, name: t("label:yes") },
  { status: false, name: t("label:no") }
];

export const messageKindToSendByEmailOptions = [
  { value: -1, name: t("label:all") },
  { value: 1, name: t("label:alertNotifications") },
  { value: 2, name: t("label:mulfunctionNotifications") },
  { value: 3, name: t("label:automaticNotifications") }
];


export const telegramNotificationsAvailableRole = "Администратор системы";

export const getDefaultValues = (profileData) => {
  const {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink,
    sendMessagesByEmail,
    messageKindToSendByEmail,
    telegramNotificationsAvailability = false,
  } = profileData;
  return {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink: {
      filesPrev: avatarLink ? [avatarLink] : [],
      filesToAdd: [],
      filesToDelete: [],
    },
    sendMessagesByEmail,
    messageKindToSendByEmail,
    telegramNotificationsAvailability,
  };
};

export const ProfileMainDataFormFormConfig = [
  {
    id: 0,
    CustomFormItem: ProfileContactData,
  },
  {
    id: 1,
    name: formFields.sendMessagesByEmail,
    label: "label:sendMessagesByEmail",
    type: "switch",
    options: notificationByEmailOptions,
    input: (props) => <Switcher {...props} />
  },
  {
    id: 2,
    name: formFields.messageKindToSendByEmail,
    label: "label:messageKindToSendByEmail",
    type: "select-number",
    flexBasis: "33%",
    width: "30%",
    options: messageKindToSendByEmailOptions,
    input: (props) => <SelectSimple {...props} />
  },
  {
    id: 3,
    title: "label:personalData",
    block: true,
    expended: [
      {
        name: formFields.lastName,
        label: "label:lastName",
        type: "text",
        rules: {
          required: true,
        },
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability
            disabled={true}
            {...props}
            Component={InputText}
          />
        ),
      },
      {
        name: formFields.firstName,
        label: "label:firstName",
        type: "text",
        rules: {
          required: true,
        },
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability {...props} Component={InputText} />
        ),
      },
      {
        name: formFields.surname,
        label: "label:surname",
        type: "text",
        rules: {},
        flexBasis: "33%",
        input: (props) => (
          <InputHandleEditAvailability {...props} Component={InputText} />
        ),
      },
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyPersonalData,
      },
    ],
  },
  {
    id: 4,
    name: formFields.telegramNotificationsAvailability,
    label: "label:telegramNotificationsAvailability",
    type: "checkbox",
    rules: {},
    input: (props) => <TelegramNotificationsAvailability {...props} />,
  },
];

export const ProfileEmployeeFormFormConfig = [
  {
    id: 0,
    mode: "employee",
    CustomFormItem: EmployeeProfile,
  },
  {
    id: 1,
    title: "label:personalData",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeePersonalData,
      },
    ],
  },
  {
    id: 2,
    title: "label:contactInformation",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeeContactInfo,
      },
    ],
  },
  {
    id: 3,
    name: formFields.telegramNotificationsAvailability,
    label: "label:telegramNotificationsAvailability",
    type: "checkbox",
    rules: {},
    input: (props) => <TelegramNotificationsAvailability {...props} />,
  },
];
