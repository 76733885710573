import React from "react";

const PoligonIcon = ({ isActive }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58333 9.08333L9.66667 5.58333M14.3333 4.41667L17.8333 6.16667M19.5833 9.66667L16.6667 17.8333M13.75 18.4167L5.58333 12.5833M9.66667 3.83333C9.66667 4.45217 9.9125 5.04566 10.3501 5.48325C10.7877 5.92083 11.3812 6.16667 12 6.16667C12.6188 6.16667 13.2123 5.92083 13.6499 5.48325C14.0875 5.04566 14.3333 4.45217 14.3333 3.83333C14.3333 3.21449 14.0875 2.621 13.6499 2.18342C13.2123 1.74583 12.6188 1.5 12 1.5C11.3812 1.5 10.7877 1.74583 10.3501 2.18342C9.9125 2.621 9.66667 3.21449 9.66667 3.83333ZM17.8333 7.33333C17.8333 7.95217 18.0792 8.54566 18.5168 8.98325C18.9543 9.42083 19.5478 9.66667 20.1667 9.66667C20.7855 9.66667 21.379 9.42083 21.8166 8.98325C22.2542 8.54566 22.5 7.95217 22.5 7.33333C22.5 6.71449 22.2542 6.121 21.8166 5.68342C21.379 5.24583 20.7855 5 20.1667 5C19.5478 5 18.9543 5.24583 18.5168 5.68342C18.0792 6.121 17.8333 6.71449 17.8333 7.33333ZM1.5 10.8333C1.5 11.4522 1.74583 12.0457 2.18342 12.4832C2.621 12.9208 3.21449 13.1667 3.83333 13.1667C4.45217 13.1667 5.04566 12.9208 5.48325 12.4832C5.92083 12.0457 6.16667 11.4522 6.16667 10.8333C6.16667 10.2145 5.92083 9.621 5.48325 9.18342C5.04566 8.74583 4.45217 8.5 3.83333 8.5C3.21449 8.5 2.621 8.74583 2.18342 9.18342C1.74583 9.621 1.5 10.2145 1.5 10.8333ZM13.1667 20.1667C13.1667 20.7855 13.4125 21.379 13.8501 21.8166C14.2877 22.2542 14.8812 22.5 15.5 22.5C16.1188 22.5 16.7123 22.2542 17.1499 21.8166C17.5875 21.379 17.8333 20.7855 17.8333 20.1667C17.8333 19.5478 17.5875 18.9543 17.1499 18.5168C16.7123 18.0792 16.1188 17.8333 15.5 17.8333C14.8812 17.8333 14.2877 18.0792 13.8501 18.5168C13.4125 18.9543 13.1667 19.5478 13.1667 20.1667Z"
        stroke={isActive ? "var(--lightGreyText)" : "var(--main)"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PoligonIcon;
