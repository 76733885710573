import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  formFields,
  allColumns,
  getConfigFormModalSelect,
} from "./form-config";
import { getColumns } from "./columns";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import TableBasic from "../../components/TableBasic";
import { useLazyGetTasksListQuery } from "../../../middleware/tasksApi";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import TaskDialog from "./TaskDialog/TaskDialog";
import { ModalSelectColumns } from "../../components/Modals/ModalSelectColumns";
import { globalFields,CHART_DATA } from "../../../constants";
import SetNewPasswordDialog from "../Profile/SetNewPasswordDialog";
import { STATUS_ID, fields } from "./constants";
import { PATHS } from '../../../constants';
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
const TasksList = () => {
  const { t } = useTranslation();
  const [getParams, setSearchParams] = useSearchParams();
 
  const  equip  =  getParams.has("equip") ? getParams.get("equip") : null;
  const  notif  = getParams.has("notif") ? getParams.get("notif") : null;
  const  facilityName  =  getParams.has("facilityName") ? getParams.get("facilityName") : "";
  const  status  =  getParams.has("status") ? getParams.get("status") : "";
  const  equipmentId  =  getParams.has("equipmentId") ? getParams.get("equipmentId") : "";
  const  equipmentName  =  getParams.has("equipmentName") ? getParams.get("equipmentName") : "";
  const  executiveUser  =  getParams.has("executiveUser") ? getParams.get("executiveUser") : "";
  const  executiveUserId  =  getParams.has("executiveUserId") ? getParams.get("executiveUserId") : "";
  const  responsibleUser  =  getParams.has("responsibleUser") ? getParams.get("responsibleUser") : "";
  const  responsibleUserId  =  getParams.has("responsibleUserId") ? getParams.get("responsibleUserId") : "";
  const  OnlyOpenTasks  =  getParams.has("OnlyOpenTasks") ? false : true;
  const navigate = useNavigate();
  console.log("facilityName",facilityName)
  const {
    tasks,
  } = useAppSelector((state) => state.tasks);
  const [PageNumber, changePageNumber] = useState(0);
  const [jobName, setJobName] = useState("");
  const [equipmentNumberAndName, setEquipmentNumberAndName] = useState(equipmentName ? equipmentName : "");
  const [responsibleCustomer, setResponsibleCustomer] = useState("");
  const [executiveCustomer, setExecutiveCustomer] = useState("");
  const [searchAddress, setsearchAddress] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [ranOnce, setRanOnce] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const token = useAppSelector((state) => state.users.token);

  const isLoggedBefore = localStorage.getItem("isLoggedBefore");
  const [passwordDialog, setPasswordDialog] = useState(
    isLoggedBefore === "false" ? false : true
  );
 
  const { control, setValue, getValues, reset } = useForm({
    defaultValues: {
      [formFields.searchParams]: {
        [formFields.deadlineStatus]: status != ""
          ? CHART_DATA[status]
          : 0,
        [formFields.equipmentType]: 0,
        [formFields.executiveUser]: executiveUser,
        [formFields.ProtectionObjectId]: facilityName,
        [formFields.status]:
          status === globalFields.completed_intime_jobs_amount
            ? STATUS_ID[fields.completely]
            : "",
        [formFields.jobName]: "",
        [formFields.priority]: "",
        [formFields.equipmentNumberAndName]: equipmentName,
        [formFields.ResponsibleCustomerUserName]: responsibleUser,

        [formFields.deadLineDate]: "",
      },
    },
  });

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "JobList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();
  useEffect(() => {

    if (!ranOnce) {
      if (notif) {
        setOpenTaskDialog(true);
        setSelectedTaskId(notif);
      }

      setRanOnce(true);
    }



  }, []);
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const {
    setIsErrorRequest,
    setSortParamsExecutiveUser: setExecutiveUser,
    setSortParamsFacilityName: setFacilityName,
  } = useAction();

  const [
    getTasksListTable,
    { error, isLoading: isLoadingTaskList, isFetching,refetch:refetchtasks },
  ] = useLazyGetTasksListQuery();

  const handlePasswordDialog = () => setPasswordDialog(!passwordDialog);

  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        navigate,
        columnsFromStorage,
        jobName,
        setJobName,
        equipmentNumberAndName,
        setEquipmentNumberAndName,
        executiveCustomer,
        setExecutiveCustomer,
        responsibleCustomer,
        setResponsibleCustomer,
        facilityName,
        setFacilityName,
        searchAddress, 
        setsearchAddress
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const resetHeaderTableForm = () => {
    reset();
    setJobName("");
    setEquipmentNumberAndName("");
    setExecutiveUser("");
    setResponsibleCustomer("");
    setFacilityName("");
  };

  const rows = useMemo(() => {
    const { data } = tasks;
    return data?.length
      ? data.map(({ taskId, taskName, facilityAddress, jobEquipments, ...task }) => {
        const { city, street, building, extension } = facilityAddress || {};
        const formattedAddress = [city, street, building, extension]
          .filter((item) => !!item)
          .join(", ");
        let equipment = {}
        if (jobEquipments.length > 0)
          equipment = { id: jobEquipments[0].id, name: `${jobEquipments[0].integrationNumber ? `${jobEquipments[0].integrationNumber},` : ""} ${jobEquipments[0].name}`, action: () => handleOpenEquipment(jobEquipments[0].id) }
        return {
          jobName: {
            taskId,
            taskName,
            action: () => handleOpenTaskDialogState(taskId),
          },
          jobEquipments: equipment,
          facilityAddress: formattedAddress,
          ...task,
        };
      })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const recordsCount = useMemo(() => tasks && tasks?.recordsCount, [tasks]);
  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.executiveUser}`,
      executiveUser
    );
  }, [executiveUser, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.equipmentNumberAndName}`,
      equipmentNumberAndName
    );
  }, [equipmentNumberAndName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ResponsibleCustomerUserName}`,
      responsibleCustomer
    );
  }, [responsibleCustomer, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ExecutiveUserName}`,
      executiveCustomer
    );
  }, [executiveCustomer, setValue]);
  
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ProtectionObjectId}`,
      facilityName
    );
  }, [facilityName, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.equipmentNumberAndName}`,
      equipmentName
    );
  }, [equipmentName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.jobName}`, `${jobName}`);
  }, [jobName, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.ProtectionObjectAddressFilter`, searchAddress);
  }, [searchAddress, setValue]);
  useEffect(() => {
    if(status)
      setValue(`${formFields.searchParams}.${formFields.deadlineStatus}`, CHART_DATA[status]);
  }, [status,setValue]);
  useEffect(() => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentType}`
    );
    let equipmentType= ""
    let equipmentKind= ""
    const createdDate = getValues(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`
    );
    delete params.CreatedDateRange
    delete params.ProtectionObjectAddressFilter
    delete params.equipmentType
    const city = searchAddress?.city ? `&ProtectionObjectAddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&ProtectionObjectAddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&ProtectionObjectAddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CreatedDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["CreatedDateRange.MinValue"] = createdDate.MinValue
    }
    
    if(equipmentTypeorKind){
        if(JSON.parse(equipmentTypeorKind).id != 0){
          if(JSON.parse(equipmentTypeorKind)?.isType){
            equipmentKind = Number(JSON.parse(equipmentTypeorKind).id.replace("parent","")) 
          }else{
            equipmentType = JSON.parse(equipmentTypeorKind).id
          }
        }
    }
    if (params.ProtectionObjectId == "все")
    {
        delete params.ProtectionObjectId;
    }
    getTasksListTable({
      ...params,
      EquipmentKindId: equipmentKind,
      EquipmentType: equipmentType,
      ...dd,
      EquipmentId: equip ? equip : equipmentId != "" ? equipmentId : "",
      OnlyOpenTasks : OnlyOpenTasks,
      OrganizationResponsibleId: responsibleUserId != "" ? responsibleUserId : "",
      ExecutiveUserId : executiveUserId != "" ? executiveUserId : "",
      address:address,
      PageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentType}`
    );
    let equipmentType= ""
    let equipmentKind= ""
    const createdDate = getValues(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`
    );
    delete params.CreatedDateRange
    delete params.ProtectionObjectAddressFilter
    delete params.equipmentType
    const city = searchAddress?.city ? `&ProtectionObjectAddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&ProtectionObjectAddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&ProtectionObjectAddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    console.log("adress",address)
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CreatedDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["CreatedDateRange.MinValue"] = createdDate.MinValue
    }
    if(equipmentTypeorKind){
      if(JSON.parse(equipmentTypeorKind).id != 0){
        if(JSON.parse(equipmentTypeorKind)?.isType){
          equipmentKind = Number(JSON.parse(equipmentTypeorKind).id.replace("parent","")) 
        }else{
          equipmentType = JSON.parse(equipmentTypeorKind).id
        }
      }
    }
    if (params.ProtectionObjectId == "все")
    {
        delete params.ProtectionObjectId;
    }  
    
    getTasksListTable({
      ...params,
      EquipmentKindId: equipmentKind,
      EquipmentType: equipmentType,
      ...dd,
      EquipmentId: equip ? equip : equipmentId != "" ? equipmentId : "",
      OnlyOpenTasks : OnlyOpenTasks,
      OrganizationResponsibleId: responsibleUserId != "" ? responsibleUserId : "",
      ExecutiveUserId : executiveUserId != "" ? executiveUserId : "",
      address:address,
      PageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageNumber]);



  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(
    () => () => {
      resetHeaderTableForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box className="template_page">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => { }}

        />
      )}
      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={selectedTaskId}
          refetch={() => getTasksListTable({ PageNumber:PageNumber,address:"" })}
          resetForm={resetHeaderTableForm}
        />
      )}
      {!passwordDialog && (
        <SetNewPasswordDialog
          token={token}
          open={!passwordDialog}
          firstEntered={true}
          onClose={handlePasswordDialog}
        />
      )}
      <Box className="template_page-header">
        <Typography className="title-page">
          {t("title_page:list_tasks")}
        </Typography>
        <Box>
          <Button
              className="btn btn-outlined-primary"
              variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_TASK);
            }}
          >
            {t("button:add_task")}
          </Button>

          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        isLoading={isLoadingTaskList || isFetching}
        pageNumber={PageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={"60vh"}
        maxHeight={"70vh"}
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "JobList" })}
        nameStorage={"JobList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

export default TasksList;
