import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Box,
  Chip,
  InputAdornment,
  ListSubheader,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SubheaderSearch = ({ searchValue, setSearchValue }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Escape") {
      e.stopPropagation();
    }
  };

  return (
    <ListSubheader
      sx={{
        zIndex: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <TextField
        fullWidth
        autoFocus
        placeholder={t("placeholders:search_select")}
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </ListSubheader>
  );
};

export const SelectMultipleChipDeletableWithSearchField = ({
  options,
  label,
  value,
  onChange,
  error,
  disabled = false,
  searchValue,
  setSearchValue,
  isForEdit,
  isIds = false,
}) => {
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState([]);
  console.log("selectedValues",selectedValues)

  const handleChange = (event) => {

    const {
      target: { value },
    } = event;
    let targetValue = value
    if (isIds) {
      targetValue = value?.map(({ id }) => id) || [];
    }
    setSelectedValues(value);
    onChange(targetValue);
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
  
    let requestData = selectedValues?.filter((ee) => ee?.id !== value?.id);
    if (isIds) {
      requestData = requestData?.map(({ id }) => id) || [];
    }
    setSelectedValues(requestData);
    onChange(requestData);
  };
 
  useEffect(() => {
    if(!value?.length) return;
    if(isForEdit) {
      setSelectedValues(options.filter(o => value.includes(o?.id) ))
    }
  }, [value, options])

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        disabled={disabled}
        onChange={handleChange}
        input={<OutlinedInput label={t(label)} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value, i) =>
              !disabled ? (
                <Chip
                  key={i}
                  label={value?.name}
                  clickable
                  deleteIcon={
                    <ClearIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              ) : (
                <Chip
                  key={i}
                  label={value?.name || value?.standard}
                  clickable
                />
              )
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        <SubheaderSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {options?.filter(({ name }) => new RegExp(searchValue, "gi").test(name)).map((option, i) => (
          <MenuItem
            key={i}
            value={option}
            sx={{ height: ITEM_HEIGHT }}
          >
            <Checkbox checked={selectedValues?.indexOf(option) > -1} />
            <ListItemText primary={option?.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
