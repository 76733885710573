import React, { useEffect, useMemo, useState } from "react";
import "./styles/style.css";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { SimpleTextCell } from "../TasksList/Components";
import { ClickableNameCell } from "../MyOrganizations/Components";
import TableBasic from "../../components/TableBasic";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ControllerStatusCell } from "./Components";
import { PATHS } from "../../../constants";
import EditButton from "../../components/EditButton";
import { useLazyGetControllersTableQuery } from "../../../middleware/controllersApi";
import { ModalAddObject } from "./ModalAddObject";
import { modalConfig } from "./form-config";
import { ModalDebugging } from "./ModalDebugging";
import { ObjectDialog } from "./ObjectDialog";

const Controllers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pageNumber, changePageNumber] = useState(0);
  const { roleId } = useAppSelector((state) => state.users.info);
  const { allControllers } = useAppSelector((state) => state.controllers);
  const { setIsErrorRequest } = useAction();
  const [openModalSetObject, setOpenModalSetObject] = useState(false);
  const [openModalDebbuging, setOpenModalDebbuging] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedNsme] = useState(null);
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [getControllersTable, { isLoading, error }] =
    useLazyGetControllersTableQuery();

  useEffect(() => {
    getControllersTable({ pageNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const recordsCount = useMemo(
    () => allControllers && allControllers?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenSelectColumnsModal = (id) => {
    setOpenModalSetObject(true);
    setSelectedId(id);
  };

  const columns = [
    {
      id: "name",
      label: t("tableHeader:controllerName"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      size: "150px",
      RowCell: (props) => <SimpleTextCell {...props} />,
    },
    {
      id: "id",
      label: t("tableHeader:controllerId"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      size: "150px",
      RowCell: (props) => <SimpleTextCell {...props} />,
    },
    {
      id: "facilities",
      label: t("tableHeader:facilities"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      size: "150px",
    },
    {
      id: "messageTemplatesSettings",
      label: t("tableHeader:messageTemplatesSettings"),
      numeric: false,
      enableSorting: false,
      size: "210px",
      RowCell: (props) => <ClickableNameCell {...props} />,
    },
    {
      id: "status",
      label: t("tableHeader:controllerStatus"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      size: "50px",
      RowCell: (props) => <ControllerStatusCell {...props} />,
    },
    {
      id: "edit",
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      clickable: false,
    },
  ];

  const rows = useMemo(() => {
    const { data } = allControllers;
    return data?.length
      ? data.map(({ id, name, facilities, status }) => {
        return {
          id,
          name,
          messageTemplatesSettings: {
            name: (
              <Button
                className="table-row-cell-link"
                sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                onClick={() =>
                  navigate(PATHS.GET_CONTROLLER_MESSAGE_TEMPLATE(id))
                }
                startIcon={<SettingsOutlinedIcon />}
              >
                {t("button:messageTemplatesSettings")}
              </Button>
            ),
          },
          facilities: (
            <>
              <List>
                {facilities.map(({ id, integrationNumber, description, name, isInTest }) => {
                  return (
                    <ListItem key={id} sx={{ p: 0 }}>
                      <ListItemText primary={`${description || name}${integrationNumber !== '' ? ', ' + integrationNumber : ''}${isInTest ? ', ' + t("label:isInTest") : ''}`} />
                    </ListItem>
                  );
                })}
              </List>
              {/*<Button
                  onClick={() => handleOpenSelectColumnsModal(id)}
                  size={"small"}
                >
                  {t("button:addObject")}
                </Button>*/}
            </>
          ),
          status,
          edit: roleId == 1 || roleId == 2 ? <EditButton handleClick={() => handleLink(id, name)} /> : <></>,
        };
      })
      : [];
    // eslint-disable-next-line
  }, [allControllers]);
  const handleLink = (id, name) => {
    setOpenObjectDialog(true);
    setSelectedId(id);
    setSelectedNsme(name);
  }

  const handleOpenObjectDialogState = (id) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedId(id);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedId(null);
  };
  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Box className="controllers-container">
      <Header
        handleModalDebbuging={setOpenModalDebbuging}
      />
      {openObjectDialog && (
        <ObjectDialog
          open={openObjectDialog}
          setOpen={setOpenObjectDialog}
          config={modalConfig}
          selectedId={selectedId}
          selectedName={selectedName}
          refetch={getControllersTable}
        />
      )}
      <Box sx={{ mt: "32px" }}>
        <TableBasic
          withSearchField={false}
          columns={columns}
          rows={rows}
          rowsPerPageOptions={10}
          handleClickCell={() => { }}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          isLoading={isLoading}
          count={recordsCount}
          minHeight={"59vh"}
          maxHeight={"70vh"}
        />
      </Box>
      <ModalAddObject
        open={openModalSetObject}
        setOpen={setOpenModalSetObject}
        config={modalConfig}
        selectedId={selectedId}
        refetch={getControllersTable}
      />
      {openModalDebbuging && (
        <ModalDebugging
          open={openModalDebbuging}
          setOpen={setOpenModalDebbuging}
        />
      )}
    </Box>
  );
};

export default Controllers;
