import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const InputFileBtn = ({
  onChange,
  uploadMode,
  label,
  disabled,
  accept = "",
  isError = false,
}) => {
  const { t } = useTranslation();

  const filePicker = useRef(null);

  const handlePick = () => {
    filePicker.current.value = "";
    filePicker.current.click();
  };
  return (
    <Box sx={{ display: "inline-block", position: "relative" }}>
      <input
        type="file"
        className="hidden"
        ref={filePicker}
        multiple={uploadMode}
        onChange={onChange}
        disabled={disabled}
        accept={accept}
      />
      <Button
        className="btn btn-upload"
        variant="outlined"
        startIcon={<FileUploadIcon />}
        onClick={handlePick}
        disabled={disabled}
        sx={{ height: "56px", whiteSpace: "nowrap" }}
      >
        {t(label)}
      </Button>
      {!isError && <Typography className="input_block_label-helper" sx={{ position: "absolute" }}>
        {accept ? `( ${accept.split(",").join("")} )` : ""}
      </Typography>}
    </Box>
  );
};

export default InputFileBtn;
