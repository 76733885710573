import React from "react";

export const ArrowIconLeftBlue = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 4.66675H5.99992V0.666748L0.666584 6.00008L5.99992 11.3334V7.33342H11.3333V4.66675Z"
        fill="#4339F2"
      />
    </svg>
  );
};
