import React from "react";
import { t } from "i18next";
import { Typography, Box, Button } from "@mui/material";

import { formFields } from "./form-config";
import { SimpleHeader } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { SimpleHHeader } from "../MagazineFinishedTasks/Components";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { eventsOptions, bgColors } from "./options";
import { SelectSimpleObject } from "../EquipmentList/Components"
import {
  DateFormatCell,
  ClickableJustFullNameCell,
  SimpleTextCell,
  ClickableTaskTextCell,
  ClickableEquipmentTextCell,
  JustFullNameCell,
} from "./Components";

export const getColumns = ({
  control,
  facilitySearch,
  setFacilitySearch,
  messageClassSearch,
  setMessageClassSearch,
  jobName,
  setJobName,
  equipName,
  setequipName,
  roomPlume,
  setroomPlume,
  triggerCause,
  settriggerCause,
  executiveUser,
  setexecutiveUser,
  responsibleUser,
  setresponsibleUser,
  columnsFromStorage,
  handleOpenEquipment,
  pageNumber,
}) => {
  return [
    {
      id: formFields.reactionDateTime,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:dateAndTime"),
      size: "130px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.reactionDateTime],
      RowCell: (props) => <DateFormatCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHHeader name={t("tableHeader:dateTime")} />

          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.ReactionDateRange.MinValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.ReactionDateRange.MaxValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      )
    },
    {
      id: formFields.facility,
      numeric: false,
      enableSorting: false,
      size: "130px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facility],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.FacilityId}`}
            sx={{ padding: "12px 0" }}
            defaultValue={"все"}
            input={(props) => (
              <SelectSimpleObject
                {...props}
                className="select_in_built small-light"
                sx={{width:"200px",backgroundColor:"var(--bgLightGrey)"}}
                //bgcolor={}
               
              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </>
      ),
    },
    {
      id: formFields.messageClass,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.messageClass],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:event_class")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.MessageClass`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={messageClassSearch}
                changeSearchValue={setMessageClassSearch}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.roomAddressPlume,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:roomAddressPlume"),
      size: "220px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.roomAddressPlume],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:roomAddressPlume")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RoomPlume`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={roomPlume}
                changeSearchValue={setroomPlume}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "170px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentName],
      RowCell: (props) => <Button
        className="table-row-cell-link"
        sx={{ verticalAlign: "initial",textAlign: "left" }}
        onClick={() => handleOpenEquipment(props.row.equipmentId)}
      >
        {props.row.equipmentName}
      </Button>,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:equipment")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.EquipmentNameAndNumber`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={equipName}
                changeSearchValue={setequipName}
                {...props}
              />

            )}
          />
        </Box>

      ),
    },
    {
      id: formFields.triggerCause,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.triggerCause],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:triggerCause")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.TriggerCause`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={triggerCause}
                changeSearchValue={settriggerCause}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.reportingPlace,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.reportingPlace],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:kind_of_message")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimpleInbuilt
                  className="select_in_built small-light"
                  sx={{ padding: "12px 0" }}
                  bgcolor={"var(--bgLightGrey)"}
                  {...props}
                />
              </Box>
            )}
          />

        </>

      ),

    },
    {
      id: formFields.responsible,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:r_organization"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.responsible],
      RowCell: (props) => <JustFullNameCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:r_organization")} />
          
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ResponsibleCustomerUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={responsibleUser}
                changeSearchValue={setresponsibleUser}
                {...props}
              />

            )}
            />
        </>

      ),
    },
    {
      id: formFields.executive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executive"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.executive],
      RowCell: (props) => <ClickableJustFullNameCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:executive")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ExecutiveUser`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={executiveUser}
                changeSearchValue={setexecutiveUser}
                {...props}
              />

            )}
          />
        </>

      ),
    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "175px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.jobName],
     /* RowCell: (props) => (
        <ClickableTaskTextCell
          {...props}
          pageNumber={pageNumber}
          control={control}
        />
      ),*/
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:task")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.TaskName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={jobName}
                changeSearchValue={setJobName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
};
