import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import { ClickableSchemaCell, SelectSimpleObject, SelectSimpleFloor,ClickableEquipmentCell } from "./Components";
import { StatusCell, SimpleHeader, SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { SimpleHHeader } from "../TasksList/Components";

const statusOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Да" },
  { value: "false", name: "Нет" }
 
]
const lifeTimeDateOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Превышен" },
  { value: "false", name: "Не превышен" }
]

export const getColumns = ({
  control,
  columnsFromStorage,
  searchNumber,
  setSearchNumber,
  searchIntegrationNumber,
  setSearchIntegrationNumber,
  searchRoomAddressName,
  setSearchRoomAddressName,
  searchObjectIntegrationNumber,
  setSearchObjectIntegrationNumber,
  searchName,
  setSearchName,
  objectId,
  searchPlume,
  setSearchPlume
}) =>
  [
    {
      id: formFields.number,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.number],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:number")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchNumber}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchNumber}
                changeSearchValue={setSearchNumber}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.integrationNumber,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.integrationNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:intNumber")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchIntegrationNumber}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchIntegrationNumber}
                changeSearchValue={setSearchIntegrationNumber}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "200px" }}>
          <SimpleHeader name={t("tableHeader:protectionObjectName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.protectionObjectId}`}

            sx={{ padding: "12px 0" }}
            width="200px"
            input={(props) => (
              <SelectSimpleObject
                {...props}
                objectId={objectId || ""}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.floorNumber,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:floorNumber"),
      size: 150,
      clickable: true,
      isVisible: columnsFromStorage[formFields.floorNumber],
      RowCell: (props) => <ClickableSchemaCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "150px" }}>
          <SimpleHeader name={t("tableHeader:floorNumber")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.floorId}`}
            sx={{ padding: "12px 0" }}
            width="150px"
            input={(props) => (
              <SelectSimpleFloor
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey) !important"}
                sx={{ backgroundColor: "var(--bgLightGrey)",lineHeight: "24px" }}
                {...props} />
            )}
          />

        </Box>
      ),
    },
    {
      id: formFields.roomAddressName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.roomAddressName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:roomAddressName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchRoomAddressName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchRoomAddressName}
                changeSearchValue={setSearchRoomAddressName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.objectIntegrationNumber,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.objectIntegrationNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:objectIntegrationNumber")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchObjectIntegrationNumber}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchObjectIntegrationNumber}
                changeSearchValue={setSearchObjectIntegrationNumber}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: (props) => (
        <>
          <SimpleHeader name={t("tableHeader:system")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.equipmentKindId}`}

            sx={{ padding: "12px 0",width:"200px"}}
            input={(props) => (
              <EquipmentKindSelectGrouping
                itemoption={{
                  id: "все",
                  name: t("options_name:everything"),
                }}
                useShortTitle={true}
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                height={"24px"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.name,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.name],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "200px" }}>
          <SimpleHeader name={t("tableHeader:equip_name")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchName}
                changeSearchValue={setSearchName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.plume,
      numeric: false,
      enableSorting: false,
      size: "70px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.plume],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "70px" }}>
          <SimpleHeader name={t("tableHeader:plume")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.searchPlume}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchPlume}
                changeSearchValue={setSearchPlume}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },

    {
      id: formFields.lifeTimeDate,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.lifeTimeDate],
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:lifeTimeDate")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.explotationExceeded}`}
            label={t("options_name:everything")}
            options={lifeTimeDateOptions}
            sx={{ padding: "12px 0" }}
            width="150px"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />

        </>
      ),
    },

    {
      id: formFields.lastServiceDate,
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage[formFields.lastServiceDate],
      label: t("tableHeader:lastServiceDate"),
      size: 150,
      clickable: false,
      HeaderCell: () => (
        <>
          <SimpleHHeader name={t("tableHeader:lastServiceDate")} />
      
          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.lastServiceDateMin`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
             placeholder={"по"}
            name={`${formFields.searchParams}.lastServiceDateMax`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      enableSorting: false,
      mode: "diagram",
      label: t("tableHeader:tasks"),
      size: "78px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.tasks],
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:tasks")} />
          <Box sx={{ padding: "12px 0" }}></Box>
          <Box sx={{ padding: "12px 0" }}></Box>
        </>
      ),
    },
    {
      id: formFields.workingProperly,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:workingCorrectly"),
      size: 100,
      clickable: false,
      isVisible: columnsFromStorage[formFields.workingProperly],
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:workingCorrectly")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.workingCorrectly}`}
            label={t("options_name:everything")}
            options={statusOptions}
            sx={{ padding: "12px 0" }}
            width="150px"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />

        </>
      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      isVisible:true,
      size: 50,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);