import React from "react";

const ArrowPrevNext = ({ isActive }) => {
  return (
    <svg
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5251 3.25C12.9834 1.90833 10.9834 1.08333 8.7751 1.08333C4.9001 1.08333 1.6251 3.60833 0.475098 7.1L2.44176 7.75C3.31676 5.09167 5.81676 3.16667 8.7751 3.16667C10.4001 3.16667 11.8834 3.76667 13.0418 4.73333L10.0251 7.75H17.5251V0.25L14.5251 3.25Z"
        fill={isActive ? "var(--main)" : "var(--lightGreyText)"}
      />
    </svg>
  );
};

export default ArrowPrevNext;
