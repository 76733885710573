import InputText from "../../../../../components/InputText";
import { t } from "i18next";
import React from "react";
import {formFields} from "./formFields";
import { Box, Tooltip, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import InputTextarea from "../../../../../components/InputTextarea";
import EquipColumn from "../EquipColumn";
import {getEquipmentFormConfig} from "./equipments";
import CustomFormItem from "../CustomFormItem";
import {getMeasuringEquipmentFormConfig} from "./measuringEquipment";
import EquipColumnExist from "../EquipColumnExist";
const name = "automaticMonitoring";

export const alarmLoopBreakFormConfig = {
  name,
  
  items: [
    {
      name: formFields.number,
      type: "number",
      rules: {},
      disabled: true,
      value: 1,
      sx: {
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            overflow: "inherit",
          },
        },
      },
      input: (props) => <InputText {...props} />,
    },
    {
      name: formFields.testName,
      type: "text",
      rules: {},
      disabled: true,
      rows:8,
      value: t(`label:testNameItems:${name}`),
      variant:"standard",
    InputProps:{
      disableUnderline: true
    },
      sx: {
        "& .MuiOutlinedInput-root": {
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: (props) => (
        <Box sx={{ display: "flex" }}>
          <InputTextarea {...props} />
          <Tooltip title={t(`label:AFADocumentTooltips:${name}`)}>
            <IconButton className="icon-btn">
              <InfoIcon sx={{ color: "var(--main)" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      name: formFields.equipments,
      CustomFormItem: (props) => (
        !props.isActExist ?
          <EquipColumn
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
          :
          <EquipColumnExist
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
      )
    },
    {
      name: formFields.measuringEquipment,
      CustomFormItem: (props) => (
        <CustomFormItem
          value= {t("label:doesNotExist")}
          disabled={true}
          label={""}
          formConfig={getMeasuringEquipmentFormConfig(name)}
          {...props}
        />
      )
    },
    
  ],
};