import React,{useState,useMemo} from "react";
import { Typography,Box } from "@mui/material";
import { useGetResponsibleOrganizationsUsersQuery } from "../../../middleware/usersApi";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";

export const ClickableActionNameCell = ({ item }) => {
  const { name, action } = item || {};
  return (
    <Typography
      className={name ? "table-row-cell-link" : ""}
      onClick={action ? action : () => {}}
    >
      {name || ""}
    </Typography>);
};

export const ClickableActionListCell = ({ item }) => {
  return item?.map((e) => <ClickableActionNameCell key={e.id} item={e} />)
};

export const RatingCell = ({ item }) => <Typography>{`${Math.round(+item)} %`}</Typography>;

export const ExecutiveUserNameSelectSimple = (props) => {
  const {control,objectId} = props
  console.log("props",props)
  const [searchValue, setSearchValue] = useState("");
  const { data: executives } = useGetResponsibleOrganizationsUsersQuery({ searchValue:searchValue,filters:`&protectionObjectId=${objectId}` });
  
  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (
    <Box sx={{ }}>
      <SelectSimpleSearch
        {...props}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        options={options}
      />
    </Box>
  );
};