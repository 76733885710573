import React from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, OutlinedInput, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectSimpleColored = ({
  options,
  label,
  onChange,
  disabled = false,
  error,
  value,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  const getBackgroundColor = (option) => {
    switch (option) {
      case "К выполнению":
        return "rgba(222, 247, 236)";
      case "Выполнено":
        return "rgba(164, 168, 252, 0.5)";
      case "На проверке":
        return "rgba(245, 158, 11, 0.3)";
      default:
        return "rgba(255, 255, 255, 0.2)";
    }
  };

  return (
    <FormControl fullWidth error={!!error} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={value || ""}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        disabled={disabled}
        sx={{
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            height: 0,
          },
          backgroundColor: getBackgroundColor(value),
          color: "black",
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value || option || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimpleColored;
