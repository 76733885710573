import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";

import { dateFormat, dateFormatForSubmit } from "../../../constants";

const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (date) => {
    setStartDate(dayjs(date.$d).format(dateFormatForSubmit));
  };

  const handleEndDateChange = (date) => {
    setEndDate(dayjs(date.$d).format(dateFormatForSubmit));
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="Выберите начальную дату"
            value={startDate ? dayjs(startDate) : null}
            onChange={handleStartDateChange}
            format={dateFormat}
          />
          <DatePicker
            label="Выберите конечную дату"
            value={endDate ? dayjs(endDate) : null}
            onChange={handleEndDateChange}
            format={dateFormat}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangePicker;
