import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  formFields,
  formConfigBank,
} from "./form-config";

import FormItem from "../../components/FormItem";

const BankDetails = ({ control, errors, disabled }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{ background: "var(--bgLightGrey)" }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          svg: { color: "var(--black)" },
        }}
      >
        <Typography className="form-section-label">{t("label:bankRequisites")}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "16px", background: "var(--bgLightGrey)" }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {formConfigBank.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={`${[formFields.organizationResponsibleBankDetails]}.${name}`}
                label={label}
                input={input}
                disabled={disabled}
                {...rest}
              />
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default BankDetails;
