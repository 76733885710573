import React, { useEffect, useState } from "react";
import {
  Popper,
  Grow,
  MenuList,
  Paper,
  ClickAwayListener,
  Typography,
  Box,
  CircularProgress
} from "@mui/material";
import { Button, Badge } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NotificationItem } from "./NotificationItem";
import { useTranslation } from "react-i18next";
import {
  useMarkNotificationAsReadMutation,
  useLazyGetNotificationsQuery,
  useLazyGetNotificationsCountQuery
} from "../../../middleware/notificationApi";
import { useAction } from "../../../hooks/actions";
import "./style.css";

export const NotificationPopUp = () => {
  const [notifications, setNotifications] = useState({})
  const [notificationsCount, setnotificationsCount] = useState(0)
  const [open, setOpen] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const { setIsErrorSubmit } = useAction();


  const [getNotification, { error: errorNotif, refetch: refetchNotifications /* isLoading */ }] =
    useLazyGetNotificationsQuery();
  const [getNotificationcount, { error: errorNotifcount}] =
  useLazyGetNotificationsCountQuery();
    
  const [markNotificationAsRead, { error }] =
    useMarkNotificationAsReadMutation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getNotificationcount().then((data) => setnotificationsCount(data.data));
    }, 15000); // Вызов функции callApi каждые 30 секунд

    return () => clearInterval(interval);
  }, []); 
  const fetchData = async () => {
    try {

      const response = await getNotification({
        pageNumber: 0,
      });
      setNotifications({ ...response?.data, hasMore: response?.data?.data.length < response?.data.recordsCount ? true : false, })

    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    if(open)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const markNotification = (array) => {
    markNotificationAsRead(array).then(({ data, error }) => {
      if (!error) {
        fetchData();
        getNotificationcount().then((data) => setnotificationsCount(data.data));
      }
     // setOpen(false);
    });
  };
  const notiffetchData = () => {
    setLoading(true);
    setTimeout(() => {

      getNotification({
        pageNumber: pageNumber + 1,
      }).then(({ data, isSuccess }) => {
        changePageNumber(prev => prev + 1)
        setNotifications({...notifications, hasMore: data?.data.length + notifications?.data.length < data.recordsCount ? true : false, data: [...notifications?.data, ...data.data] });
        setLoading(false);
      })

    }, 1000);

  }
  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom >= 1 && bottom <= 10 && !loading && notifications?.hasMore) {
      notiffetchData()
    }
  };
  return (
    <Box>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={notificationsCount || 0} color="error">
          <NotificationsNoneIcon
            sx={{ height: "32px", width: "32px", color: "black" }}
          />
        </Badge>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="paper-wrapper">
              <Typography
                style={{
                  borderBottom: "1px solid #e2e8f0",
                  paddingBottom: "16px",
                }}
                className="big-title"
              >
                {t("label:notifications")}
              </Typography>
              {notifications?.recordsCount >0 ? (
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="text"
                    onClick={() =>
                      markNotification(
                        notifications.data.map((notification) => notification?.id)
                      )
                    }
                  >
                    <Typography className="mark-laber ">
                      {t("label:mark_all_as_read")}
                    </Typography>
                  </Button>
                </Box>
              ) : (
                ""
              )}
              <ClickAwayListener onClickAway={handleClose}>
                {notifications?.recordsCount >0 ? (
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    onScroll={handleScroll}
                    sx={{
                      height: "50vh",
                      overflow: "hidden",
                      overflowY: "auto",
                      paddingBottom: "0px",
                    }}
                  >
                    {notifications.data.map((item) => {
                      return (
                        <NotificationItem
                          key={item?.id}
                          markNotification={markNotification}
                          notification={item}
                        />
                      );
                    })}
                    {loading && (

                      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </MenuList>
                ) : (
                  <Box
                    sx={{
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography> {t("label:no_notifications")}</Typography>
                  </Box>
                )}
              </ClickAwayListener>
              <Box className="box">
                <img
                  style={{ height: "40px", marginTop: "12px" }}
                  src="/logo.svg"
                  alt="logo"
                />
                <Typography className="info">{t("label:info")}</Typography>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
