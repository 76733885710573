import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWatch } from "react-hook-form";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

import { PATHS } from "../../../constants";
import { useLazyGetAllMagazineEventsQuery } from "../../../middleware/magazineApi";
import { formFields } from "./form-config";

import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import { EmployeeDialog } from "../EmployeeList/EmployeeDialog";


export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const content = dayjs(item).format(dateFormat).split(" ");

  return content.map((e, i) => (
    <Typography key={i} className="table_row_text">
      {e}
    </Typography>
  ));
};

export const ClickableJustFullNameCell = ({ item }) => {
  const { userId, fullName } = item || {};
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };

  return (
    <>
      <Typography
        className="table-row-cell-link"
        onClick={() => handleOpenEmployeeDialogState(userId)}
      >
        {fullName || ""}
      </Typography>
      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}
    </>
  );
};

export const JustFullNameCell = ({ item }) => {
  const { fullName } = item || {};

  return <Typography className="table_row_text">{fullName || ""}</Typography>;
};

export const ClickableFullNameCell = ({ item }) => {
  const { userId, fullName, position, organization } = item || {};
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };
  return (
    <>
      <Typography
        className="table-row-cell-link"
        onClick={() => handleOpenEmployeeDialogState(userId)}
      >
        {fullName || ""}
      </Typography>
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "14px",
          color: "var(--additionalGrey)",
          fontFamily: "var(--IBM)",
        }}
      >
        {position || ""}
      </Typography>
      <Typography
        style={{
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "14px",
          color: "var(--additionalGrey)",
          fontFamily: "var(--IBM)",
        }}
      >
        {organization || ""}
      </Typography>
      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}
    </>
  );
};

export const SimpleTextCell = ({ item }) => (
  <Typography className="table_row_text">{item || ""}</Typography>
);

export const ClickableTaskTextCell = ({ item, row, pageNumber, control }) => {
  const { jobId } = row || {};
  const [getAllMagazineEvents] = useLazyGetAllMagazineEventsQuery();
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const [openTask, setOpenTask] = useState(false);

  const handleCloseDialog = () => {
    setOpenTask(true);
  };

  return (
    <>
      <Typography
        className="table-row-cell-link"
        onClick={() => setOpenTask(true)}
      >
        {item}
      </Typography>
      {openTask && (
        <TaskDialog
          open={openTask}
          setOpen={setOpenTask}
          taskId={jobId}
          refetch={
            getAllMagazineEvents.bind(null, {
              ...params,
              pageNumber,
            })
          }
        />
      )}
    </>
  );
};

export const ClickableEquipmentTextCell = ({ item, row }) => {
  const navigate = useNavigate();
  const { equipmentId } = row || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={() =>
        equipmentId && navigate(PATHS.GET_EQUIPMENT_DETAILS(equipmentId))
      }
    >
      {item}
    </Typography>
  );
};
