import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { PATHS } from '../../../constants';
import { useAction } from "../../../hooks/actions";


const Objects = ({ objects, organization }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setSortParamsResponsibleOrganization } = useAction();

  useEffect(() => {
    setSortParamsResponsibleOrganization && setSortParamsResponsibleOrganization(organization?.name || "")
  }, [organization, setSortParamsResponsibleOrganization]);

  return (
    <Box className="objects">
      <Box className="objects-btn-field">
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={navigate.bind(null, PATHS.TASKS)}
        >
          {t("button:tasks")}
        </Button>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => {
            navigate(PATHS.ADD_OBJECT);
          }}
        >
          {t("button:add_object")}
        </Button>
      </Box>

      {objects?.map(({ id, name, fireCompartments }) => (
        <Accordion key={id} square={true} disableGutters={true} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: "row-reverse" }}
          >
            <Typography className="objects-item">{name}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px 40px 10px" }}>
            {fireCompartments?.map(({ id, name }) => (
              <Typography
                className="objects-item"
                key={id}
                sx={{ marginBottom: "12px" }}
              >
                {name}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Objects;
