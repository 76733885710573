import { t } from "i18next";
import { ROLE_TYPES } from "../../../../constants";
import { STATUS_ID, fields, PRIORITY_ID } from "../constants";

export const formFields = {
    fireCompartmentId: "fireCompartmentId",
  facilityAndFireCompartment: "facilityAndFireCompartment",

  nameEquipment: "nameEquipment", // where is it in the data
  workingCorrectly: "workingCorrectly",
  installationDate: "installationDate",
  manufactureDate: "manufactureDate",
  equipmentPassportFileName: "equipmentPassportFileName",
  id: "id",
  regulations: "regulations",

  code: "code",
  name: "name",
  reactionDateTime: "reactionDateTime",
  messageDescription: "messageDescription",
  messageCode: "messageCode",
  equipmentNumber: "equipmentNumber",
  taskName: "taskName",
  fireCompartment: "fireCompartment",
  priority: "priority",
  status: "status",
  ProtectionObjectId:"ProtectionObjectId",
  FloorInfoId: "FloorInfoId",
  jobType:"jobType",
  isCompleted: "isCompleted",
  comments: "comments",
  description: "description",
  files: "files",
  gost: "gost",
  number: "number",

  responsibleOrganizationId: "responsibleOrganizationId",
  executiveUser: "executiveUser",
  executiveUserId: "executiveUserId",
  deadline: "deadline",
  initialEstimate: "initialEstimate",
  actualTimeSpent: "actualTimeSpent",
  organizations: "organizations",
  jobEquipments: "jobEquipments",
  jobStandards: "jobStandards",
  jobChecklist: "jobChecklist",
  jobFiles: "jobFiles",

  equipmentMessages: "equipmentMessages",
  triggerCause: "triggerCause",

  signatureDateTime: "signatureDateTime",
  signatureImage: "signatureImage",
};

export const statusOptions = ({ role }) => {
  let result = [];

  switch (role) {
    case ROLE_TYPES.DIGITIZER:
    case ROLE_TYPES.EMPLOYEE:
      result = [
        { value: STATUS_ID[fields.onreview], name: t(`${fields.onreview}`) },
      ]
      break;
  
    default:
      result = [
        { value: STATUS_ID[fields.onreview], name: t(`${fields.onreview}`) },
        { value: STATUS_ID[fields.completely], name: t(`${fields.completely}`) },
        { value: STATUS_ID[fields.inprogress], name: t(`${fields.inprogress}`) },
      ]
      break;
  }
  
  return result;
};
export const jobTypeOptions = [
  
  {
    value: 1,
    name: "Регламенты" ,
  },
  {
    value: 2,
    name: "Замена оборудования",
  },
  { value: 3, name: "Задача с шугарта" },
];
export const priorityOptions = [
  //{ value: t("options_name:everything"), name: t("options_name:everything") },
  {
    value: PRIORITY_ID[fields.highty],
    name: t(`${fields.highty}`),
  },
  {
    value: PRIORITY_ID[fields.middle],
    name: t(`${fields.middle}`),
  },
  { value: PRIORITY_ID[fields.lower], name: t(`${fields.lower}`) },
];

export const triggerCauseOptions = [
  { value: t(`tableHeader:${formFields.triggerCause}`), name: "" },
  {
    value: t(`options_name:${fields.alarm}`),
    name: t(`options_name:${fields.alarm}`),
  },
  {
    value: t(`options_name:${fields.malfunctions_IP}`),
    name: t(`options_name:${fields.malfunctions_IP}`),
  },
  {
    value: t(`options_name:${fields.maintenance}`),
    name: t(`options_name:${fields.maintenance}`),
  },
];

 const getIsDisabledField = ({ role, name }) => {
  let undisabledFields = [];

  switch (role) {
    case ROLE_TYPES.EMPLOYEE:
    case ROLE_TYPES.DIGITIZER:
      undisabledFields = [
        formFields.status,
        formFields.actualTimeSpent,
        formFields.comments,
      ]
      break;  
  
    default:
      undisabledFields = [
        formFields.status,
        formFields.priority,
        formFields.responsibleOrganizationId,
        formFields.executiveUserId,
        formFields.initialEstimate,
        formFields.actualTimeSpent,
        formFields.comments,
      ];
      break;
  }

  return !undisabledFields.find(fieldName => fieldName === name);
};