import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import { SimpleHeader, SimpleTextCell, DeadlineStatusTextCell, StatusCell, } from "../../TasksList/Components";
import { SearchInbuilt } from "../../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../../components/SelectSimpleInbuilt";
import FormItem from "../../../components/FormItem";
import { Box } from "@mui/material";
import { ClickableEquipCell } from "../Components";
import { ClickableNameCell } from "../../MyOrganizations/Components";
import {
    deadlineStatusOptions,
    statusOptions,
  } from "./options";


export const getColumns = ({
  control,
  columnsFromStorage,
  searchTaskName,
  setsearchTaskName,
  searchresUser,
  setSearchresUser,
  searchequip,
  setsearchequip,

}) =>
  [
    {
        id: formFields.equipName,
        numeric: false,
        enableSorting: false,
        size: "250px",
        borderHeader: true,
        isVisible: columnsFromStorage["jobEquipments"],
        RowCell: (props) => <ClickableEquipCell {...props} />,
        HeaderCell: () => (
            <Box sx={{ width: "250px" }}>
                <SimpleHeader name={t("tableHeader:equip")} />
                  <FormItem
                    control={control}
                    name={`${formFields.searchParams}.${formFields.EquipmentNumberAndName}`}
                    sx={{ padding: "12px 0" }}
                    input={(props) => (
                        <SearchInbuilt
                            className="select_in_built small-light"
                            bgcolor={"var(--bgLightGrey)"}
                            searchValue={searchequip}
                            changeSearchValue={setsearchequip}
                            {...props}
                        />
                    )}
                    />
            </Box>
        ),
    },
    {
        id: formFields.taskname,
        numeric: false,
        enableSorting: false,
        size: "100px",
        clickable: true,
        isSticky: true,
        borderHeader: true,
        isVisible: columnsFromStorage["taskName"],
        RowCell: (props) => <ClickableNameCell {...props} />,
        HeaderCell: () => (
            <Box sx={{ width: "100px" }}>
                <SimpleHeader name={t("tableHeader:group_task")} />
                  <FormItem
                    control={control}
                    name={`${formFields.searchParams}.${formFields.JobName}`}
                    sx={{ padding: "12px 0" }}
                    input={(props) => (
                        <SearchInbuilt
                            className="select_in_built small-light"
                            bgcolor={"var(--bgLightGrey)"}
                            searchValue={searchTaskName}
                            changeSearchValue={setsearchTaskName}
                            {...props}
                        />
                    )}
                    />
            </Box>
        ),
    },
    {
        id: formFields.status,
        numeric: false,
        enableSorting: false,
        size: "150px",
        clickable: false,
        isVisible: columnsFromStorage[formFields.status],
        RowCell: (props) => <StatusCell {...props} />,
        HeaderCell: () => (
          <>
            <SimpleHeader name={t("tableHeader:status")} />
            <FormItem
              control={control}
              name={`${formFields.searchParams}.${formFields.status}`}
              label={t("options_name:everything")}
              options={statusOptions}
              sx={{ padding: "12px 0" }}
              width="150px"
              input={(props) => (
                <SelectSimpleInbuilt
                  {...props}
                  className="select_in_built small-light"
                  bgcolor={"var(--bgLightGrey)"}
                />
              )}
            />
          </>
        ),
      },
      {
        id: formFields.deadlineStatus,
        numeric: false,
        enableSorting: false,
        size: "180px",
        clickable: false,
        isVisible: columnsFromStorage[formFields.deadlineStatus],
        RowCell: (props) => <DeadlineStatusTextCell {...props} />,
        HeaderCell: () => (
          <>
            <SimpleHeader name={t("tableHeader:state")} />
            <FormItem
              control={control}
              name={`${formFields.searchParams}.${formFields.deadlineStatus}`}
              label={t("options_name:everything")}
              options={deadlineStatusOptions}
              sx={{ padding: "12px 0" }}
              width="180px"
              input={(props) => (
                <SelectSimpleInbuilt
                  {...props}
                  className="select_in_built small-light"
                  bgcolor={"var(--bgLightGrey)"}
                />
              )}
            />
          </>
        ),
      },
    {
        id: formFields.responsibleCustomerUser,
        numeric: false,
        enableSorting: false,
        size: "225px",
        clickable: false,
        isSticky: true,
        borderHeader: true,
        isVisible: columnsFromStorage["executiveUser"],
        RowCell: (props) => <SimpleTextCell {...props} />,
        HeaderCell: () => (
            <Box sx={{ width: "110px" }}>
                <SimpleHeader name={t("tableHeader:resUser")} />
                { <FormItem
                    control={control}
                    name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
                    sx={{ padding: "12px 0" }}
                    input={(props) => (
                        <SearchInbuilt
                            className="select_in_built small-light"
                            bgcolor={"var(--bgLightGrey)"}
                            searchValue={searchresUser}
                            changeSearchValue={setSearchresUser}
                            {...props}
                        />
                    )}
                    />}
            </Box>
        ),
    },
    {
        id: formFields.statistics,
        numeric: false,
        enableSorting: false,
        size: "125px",
        clickable: false,
        isSticky: true,
        borderHeader: true,
        isVisible: columnsFromStorage["completionProgress"],
        RowCell: (props) => <SimpleTextCell {...props} />,
        HeaderCell: () => (
            <Box sx={{ width: "110px" }}>
                <SimpleHeader name={t("tableHeader:task_statistics")} />
                <Box sx={{padding:"24px 0px"}}></Box>
            </Box>
        ),
    },
  ].filter(({ isVisible }) => isVisible);
