import React, { useCallback, useState, useEffect } from "react";
import { Map, YMaps, SearchControl, Clusterer, ZoomControl } from "@pbe/react-yandex-maps";
import { Typography, Box, Button } from "@mui/material";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/styles.css";

import { YANDEX_MAPS_DATA } from "../MyOrganizations/constants";
import { useChartsIcons } from "../EquipmentDetails/hooks";
import { useLazyGetFacilitiesMapElementsInfoQuery } from "../../../middleware/facilitiesApi";

import CustomPlacemark from "../../components/DonurChartMarker/indxex";
import MapPopUp from "../../components/MapPopUp";
import { PATHS } from "../../../constants";
import DonutChart from "../../components/DonutChart";
import { RatingBox } from "./RatingBox";
import { useAppSelector } from "../../../hooks/redux";
import SetNewPasswordDialog from "../Profile/SetNewPasswordDialog";

const COLORS = ["#F8F9FD", "#F8F9FD", "#BFCCDB", "#9CA9BD", "#A4A8FC"];

const ObjectsOnMap = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mapRef = React.createRef(null);
  const [isMapPopUpOpen, setIsMapPopUpOpen] = useState(false);
  const [selectedMarkerID, setSelectedMarkerId] = useState("");
  const [facilitiesMapElements, setFacilitiesMapElements] = useState([]);
  const token = useAppSelector((state) => state.users.token);
  const isLoggedBefore = localStorage.getItem("isLoggedBefore");
  const [passwordDialog, setPasswordDialog] = useState(
    isLoggedBefore === "false" ? false : true
  );
  const handlePasswordDialog = () => setPasswordDialog(!passwordDialog);

  const [mapState, setMapState] = useState(YANDEX_MAPS_DATA.defaultState);
  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: facilitiesMapElements,
  });

  const [getFacilitiesMapElementsInfo, { isLoading: isLoadingFacilities }] =
    useLazyGetFacilitiesMapElementsInfoQuery();

  useEffect(() => {
    (async function () {
      const data = await getFacilitiesMapElementsInfo();
      const filterData = data?.data?.filter((item) => item?.address !== null);
      setFacilitiesMapElements(filterData);
    })();
  }, [getFacilitiesMapElementsInfo]);

  const popUpHandler = useCallback(
    (id) => {
      setSelectedMarkerId(id);
      setIsMapPopUpOpen(true);
    },
    [setSelectedMarkerId]
  );

  useEffect(() => {
    if (!isLoadingFacilities && facilitiesMapElements?.length > 0) {
      const bounds = getMapBounds();
      const newMapState = calculateMapState(bounds);
      setMapState((prev) => ({ ...prev, ...newMapState }));

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFacilities, facilitiesMapElements]);

  function getMapBounds() {
    const bounds =
      !isLoadingFacilities &&
      facilitiesMapElements?.reduce(
        (acc, obj) => {
          if (obj?.address) {
            return [
              [
                Math.min(acc[0][0], obj?.address?.coordinates?.latitude),
                Math.min(acc[0][1], obj?.address?.coordinates?.longitude),
              ],
              [
                Math.max(acc[1][0], obj?.address?.coordinates?.latitude),
                Math.max(acc[1][1], obj?.address?.coordinates?.longitude),
              ],
            ];
          } else {
            return acc;
          }
        },
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      );
    return bounds;
  }

  function calculateMapState(bounds) {
    const [[minLat, minLon], [maxLat, maxLon]] = bounds;

    const center = [(minLat + maxLat) / 2, (minLon + maxLon) / 2];
    const isSingleMarker = minLat === maxLat && minLon === maxLon;
    let zoom;
    if (isSingleMarker) {
      zoom = 12;
    } else {
      const deltaLat = Math.abs(maxLat - minLat);
      const deltaLon = Math.abs(maxLon - minLon);
      const maxDelta = Math.max(deltaLat, deltaLon);
      const coefficient = maxDelta <= 6 ? 0 : maxDelta > 11 ? 2 : 1;

      zoom = Math.floor(Math.log2(360 / maxDelta)) + coefficient;
    }
    return {
      center,
      zoom,
    };
  }

  function getRegions(ymaps) {
    if (mapRef && mapRef.current) {
      let objectManager = new ymaps.ObjectManager();
      ymaps.borders
        .load("RU", {
          lang: "ru",
          quality: 3,
        })
        .then(function (result) {
          let queue = [];
          let regions = result.features?.reduce(function (acc, feature) {
            let iso = feature.properties.iso3166;
            feature.id = iso;
            feature.options = {
              fillOpacity: 0.5,
              strokeColor: "#808080",
              strokeOpacity: 0.5,
            };
            acc[iso] = feature;
            return acc;
          }, {});
          function paint(iso) {
            let allowedColors = COLORS.slice();
            let region = regions[iso];
            let neighbors = region.properties.neighbors;
            if (region.options.fillColor) {
              return;
            }
            if (neighbors.length !== 0) {
              neighbors.forEach(function (neighbor) {
                const fillColor = regions[neighbor].options.fillColor;
                if (fillColor) {
                  let index = allowedColors.indexOf(fillColor);
                  if (index !== -1) {
                    allowedColors.splice(index, 1);
                  }
                } else if (queue.indexOf(neighbor) === -1) {
                  queue.push(neighbor);
                }
              });
            }
            region.options.fillColor = allowedColors[0];
          }
          for (let iso in regions) {
            if (!regions[iso].options.fillColor) {
              queue.push(iso);
            }
            while (queue.length > 0) {
              paint(queue.shift());
            }
          }
          result.features = [];
          for (let reg in regions) {
            result.features.push(regions[reg]);
          }
          objectManager.add(result);
          mapRef.current.geoObjects.add(objectManager);
        });
    }
  }
  const onMarkerClickk = (e) => {

    const newCenter = e.get('target').geometry.getCoordinates();
    console.log("newCenter", newCenter) // получить координаты кликнутой метки
    setMapState({ center: newCenter, zoom: 12 }); // изменить центр и масштаб карты при клике на метку
  };
  const onMarkerClick = (e) => {

    const newZoom = mapRef.current.getZoom() + 5;
    console.log("newCenter", newZoom)
    const currentPosition = e.get('target').geometry.getCoordinates();
    mapRef.current.setCenter(currentPosition, newZoom);
  };
  const onTaskStateClick = (id,props) => {

    navigate(`${PATHS.LIST_TASKS}?facilityName=${id}&status=${props}`);
  };
  return (
    <Box>
      {!passwordDialog && (
        <SetNewPasswordDialog
          token={token}
          open={!passwordDialog}
          firstEntered={true}
          onClose={handlePasswordDialog}
        />
      )}
      <ChartsList />
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {t("title_page:objects_on_map")}
        </Typography>
        <Button
          sx={{
            height: "100px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          onClick={() => navigate(PATHS.LIST_OBJECTS)}
        >
          <Typography
            variant="body1"
            sx={{
              color: "var(--additionalGrey)",
              marginRight: "8px",
            }}
          >
            {t("title_page:list_objects")}
          </Typography>
          <TableChartOutlinedIcon
            sx={{
              color: "var(--additionalGrey)",
              height: "50px",
              width: "35px",
            }}
          />
        </Button>
      </Box>
      <Box>
        <Box sx={{ boxSizing: "border-box" }}>
          <RatingBox />
        </Box>
        <Box sx={{ p: "20px 0", boxSizing: "border-box" }}>
          <YMaps query={YANDEX_MAPS_DATA.query}>
            <Map
              modules={YANDEX_MAPS_DATA.modules}
              defaultOptions={YANDEX_MAPS_DATA.defaultOptions}
              instanceRef={mapRef}
              state={mapState}
              onLoad={getRegions}
              width="100%"
              height="calc(100vh - 400px)"
            >
              <SearchControl
                options={{
                  float: "left",
                  placeholderContent: "Введите адрес объекта",
                }}
              />
              <ZoomControl options={{ float: "right" }} />
              <Clusterer
                instanceRef={ref => ref}
                options={{
                  iconImageSize: [40, 40],
                  clusterHideIconOnBalloonOpen: false,
                  geoObjectHideIconOnBalloonOpen: false,
                  //clusterDisableClickZoom: true,
                  //clusterOpenBalloonOnClick: true,
                  clusterBalloonPanelMaxMapArea: 0,
                  clusterBalloonContentLayoutWidth: 350,
                  clusterBalloonContentLayoutHeight: 150,
                }}
              >
                {!isLoadingFacilities &&
                  facilitiesMapElements?.map(
                    (item, index) =>
                      item?.address && (
                        <CustomPlacemark
                          key={item?.id}
                          id={item?.id}
                          geometry={[
                            item?.address?.coordinates?.latitude,
                            item?.address?.coordinates?.longitude,
                          ]}
                          name={item?.name}
                          jobChart={item?.jobChart}
                          onClick={onMarkerClick}//}
                          onClickPopUp={popUpHandler}
                          onTaskStateClick={onTaskStateClick}
                          linkChart={getSrcSVG(index)}
                        />
                      )
                  )}
              </Clusterer>
            </Map>
          </YMaps>
          {isMapPopUpOpen && (
            <MapPopUp
              selectedMarkerID={selectedMarkerID}
              setIsMapPopUpOpen={setIsMapPopUpOpen}
              setSelectedMarkerId={setSelectedMarkerId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ObjectsOnMap;
