import React, { useMemo, useEffect, useRef, useState } from "react";
import { Box, Typography, Tooltip, Button, Slider } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useWatch } from "react-hook-form";
import { useAction } from "../../../../hooks/actions";
import { useAppSelector } from "../../../../hooks/redux";
import { config, formFields } from "../form-config";
import { getRoomAddressForScheme } from "../../../../helpers/helper";
import { ArrowIconLeftBlue } from "../../../components/icons/ArrowIcon";
import { StagingToCanvas } from "../../../components/DrawCanvas/StagingToCanvas";
import { MissingFloorSchemaForm } from "./MissingFloorSchemaForm";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Input from '@mui/material/Input';
import dayjs from "dayjs";
import { ListEvents } from "./ListEvents";

import FormItem from "../../../components/FormItem";
import SelectSimple from "../../../components/SelectSimple";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from "react-i18next";

export const StagingTO = ({
    control,
    floorScheme,
    setValue,
    getValues,
    draggable = false,
    groupClick,
    handleListItemClick,
    selectedListItem,
    loadScheme,
    changeRoomAddresses,
    setzoomOut,
    zoomOut,
    logsRooms,
    openEventSide,
    equipGroup = false,
    setopenEventSide,
    logsMessagess,
    isLoading,
    scaleCanvas,
    setScale,
    stageposition,
    setstageposition,
    setopenEquipDialog,
    setFloorScheme,
    isChangefloorScheme,
    setSelectedEquipId,
    isListClick,
    stageRef,
    canvasRef,
    imageRef,
    setisListClick,
    handleAddPlume,
    handleEditPlume,
    handlestagingTO,
    handleTO,
    StartTO, roomsColors,
    searchObject,
    setsearchObject,
    lineColor
}) => {
    const { SCHEMA_CANVAS, openSidebar } = useAppSelector(state => state.settings);
    let wwwidth = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96

    const $container = useRef(null);
    const [fullscreen, setFullScreen] = useState(false);
    const [maxScale, setmaxScale] = useState(5);
    const [inputScale, setinputScale] = useState("");
    const [minScale, setminScale] = useState(0.5);
    const [markScale, setmarkScale] = useState([]);
    const [heightContainer, setheightContainer] = useState(wwwidth >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464);
    const [widthContainer, setwidthContainer] = useState(openSidebar ? window.innerWidth - 625 : window.innerWidth - 315);
    const [widthWorkplace, setwidthWorkplace] = useState(openSidebar ? window.innerWidth - 625 : window.innerWidth - 315);
    const [heightWorkplace, setheightWorkplace] = useState(wwwidth >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464);
    const { t } = useTranslation();
    const { floor, roomAddresses } = useWatch({
        control,
    });



    const { setCanvasSize } = useAction();



    useEffect(() => {

        let change = 0
        if (openEventSide)
            change += 220
        if (openSidebar)
            change += 317
        else
            change += 110
        change += 80

        setwidthContainer(() => window.innerWidth - change);
        setwidthWorkplace(() => window.innerWidth - change)
        window.addEventListener("resize", function () {
            let change = 0
            if (openEventSide)
                change += 220
            if (openSidebar)
                change += 317
            else
                change += 110
            change += 80
            let windowW = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96
            setwidthContainer(() => window.innerWidth - change);
            setwidthWorkplace(() => window.innerWidth - change)
            setheightContainer(() => windowW >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464)
            setheightWorkplace(() => windowW >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464)
        });
    }, [openSidebar, openEventSide]);

    const {
        width: screenRelatedWidth,
        height: screenRelatedHeight,
        scale,
    } = useMemo(() => {
        let width = 0
        let height = 0

        if (SCHEMA_CANVAS.HEIGHT > SCHEMA_CANVAS.WIDTH) {
            height = heightContainer
            width = heightContainer / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH)
        } else {
            width = widthContainer
            height = widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT)
            if ((height > window.innerHeight - 464 && wwwidth >= 1813) || (height > window.innerHeight - 394 && wwwidth < 1813)) {
                height = heightContainer
                width = heightContainer / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH)
            }
        }
        return SCHEMA_CANVAS.WIDTH
            ? {
                width,
                height,
                scale: {
                    x: width / SCHEMA_CANVAS.WIDTH,
                    y: height / SCHEMA_CANVAS.HEIGHT,
                },
            }
            : {};
    }, [SCHEMA_CANVAS, heightContainer, widthContainer]);

    const screenRelatedRoomAddresses = useMemo(
        () => getRoomAddressForScheme(roomAddresses, scale),
        [roomAddresses, scale]
    );

    const toggleList = () => {
        loadScheme(1, 0, 0, false, false)
        // setstageposition({ x: 0, y: 0 });
        //  setScale(1)

        /*if (getValues(formFields.object) == selectedListItem.protectedObjectId && getValues(formFields.floor) == selectedListItem.floorInfoId) {
            loadScheme(1, 0, 0, false)
        } else {
            setValue(formFields.object, selectedListItem.protectedObjectId)
            setmessageFloor(selectedListItem.floorInfoId)
        }*/
    }
    /* const offsetScalePosition = () => {
 
         setstageposition({ x: 0, y: 0 });
         //setScale((prev) => 1)
         const scaleAdresses = getRoomAddressForScheme(roomAddresses, scale).filter((item) => item.id == selectedListItem.roomAddressId)[0]?.coordinates
         const scaleAdressess = roomAddresses.filter((item) => item.id == selectedListItem.roomAddressId)[0]?.coordinates
         if (scaleAdressess && scaleAdresses) {
             const scalecoordinates = scaleAdressess.filter((item) => item.order == 0)[0]
             const oddIndexValues = scaleAdresses.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы
             const evenIndexValues = scaleAdresses.filter((_, index) => index % 2 === 0);
             const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
             const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов
 
             const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
             const maxOddIndex = (Math.max(...oddIndexValues));
             const diffX = (maxEvenIndex - minEvenIndex)
             const diffY = (maxOddIndex - minOddIndex)
 
             let scaleStageX = 2;
             let scaleStageY = 2;
             let scaleStage = 2;
             let coorX = 0;
             let coorY = 0;
             scaleStageY = (((screenRelatedHeight - 10) / (maxOddIndex - minOddIndex)));
             scaleStageX = ((screenRelatedWidth - 10) / (maxEvenIndex - minEvenIndex));
             if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= screenRelatedHeight / scaleStageY) || (diffX <= diffY && screenRelatedWidth < screenRelatedHeight)) {
                 scaleStage = scaleStageX
             } else {
                 scaleStage = scaleStageY
             }
 
             setScale(scaleStage)
             const diffWidth = (screenRelatedWidth - (diffX * scaleStage)) / 2
             const diffHeight = (screenRelatedHeight - (diffY * scaleStage)) / 2
             if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= screenRelatedHeight / scaleStageY) || (diffX <= diffY && screenRelatedWidth < screenRelatedHeight)) {
                 coorX = (minEvenIndex - (diffWidth / scaleStage)) / scale.x
                 coorY = (minOddIndex - (diffHeight / scaleStage)) / scale.x
             } else {
                 coorX = (minEvenIndex - (diffWidth / scaleStage)) / scale.x
                 coorY = (minOddIndex - (diffHeight / scaleStage)) / scale.x
             }
 
             const offsetX = coorX * scaleStage * scale.y
             const offsetY = coorY * scaleStage * scale.y
             console.log("ABOBA", minEvenIndex, diffWidth, scale.y)
             setstageposition((prev) => ({ x: 0 - offsetX, y: 0 - offsetY }));
             setisListClick(false)
         }
 
     } */
    const handleChangeSliderCommitted = (event, newValue) => {
        const stage = stageRef.current;
        const canvas = canvasRef.current;
        const x = stage.x()
        const y = stage.y()

        const pointTo = {
            x: widthWorkplace / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: heightWorkplace / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };

        if (newValue <= 1 && scaleCanvas > 1)
            loadScheme(1, 0, 0, false, false)
        if (newValue > minScale) {
            const coordinates = [-stage.x() / newValue, -stage.y() / newValue, (screenRelatedWidth - stage.x()) / newValue, (screenRelatedHeight - stage.y()) / newValue];
            groupClick(coordinates, null, scale, screenRelatedHeight, screenRelatedWidth, false);
        } else if (newValue <= minScale) {
            loadScheme()
        }
        setScale(newValue);
        setstageposition({
            x: -(pointTo.x - screenRelatedWidth / 2 / newValue) * newValue,
            y: -(pointTo.y - screenRelatedHeight / 2 / newValue) * newValue,
        });
        // stage.position({ x: -stage.x() * scaleCanvas,y: -stage.y() * scaleCanvas});

    };
    const handleChangeSlider = (event, newValue) => {
        setScale(newValue)
    };

    const zoomOutClick = () => {
        let scaleCanva = scaleCanvas - minScale;
        const canvas = canvasRef.current;

        if (scaleCanva > maxScale) scaleCanva = maxScale

        const pointTo = {
            x: screenRelatedWidth / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: screenRelatedHeight / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };
        console.log("stage", {
            x: -(pointTo.x - screenRelatedWidth / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / scaleCanva) * scaleCanva,
        })
        const ppoint = {
            x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
        }
        if (scaleCanva <= 1) {
            loadScheme(1, 0, 0, false, false)
        } else if (scaleCanva > 1 && scaleCanva < minScale) {
            loadScheme()
            setScale(scaleCanva)
        } else {
            setstageposition(ppoint);
            setScale(scaleCanva)
            const coordinates = [-ppoint.x / scaleCanva, -ppoint.y / scaleCanva, (widthWorkplace / scaleCanva - ppoint.x / scaleCanva), (heightWorkplace / scaleCanva - ppoint.y / scaleCanva)];
            // const coordinates = [-canvas.x() / scaleCanva, -canvas.y() / scaleCanva, (widthWorkplace *scaleCanva  - canvas.x()) / scaleCanva, (screenRelatedHeight *scaleCanva  - canvas.y()) / scaleCanva];
            groupClick(coordinates, null, scale, canvas.height(), canvas.width(), false);
            //setstageposition()
        }

    }
    const resetPosition = () => {
        const stage = stageRef.current;
        // setzoomOut(false);
        loadScheme(1, 0, 0, false, false)
        setScale(1)
        setstageposition({ x: 1, y: 1 })

    }
    const zoomInClick = () => {

        const canvas = canvasRef.current;
        const backImage = imageRef.current;
        let scaleCanva = scaleCanvas + 7;
        if (scaleCanva > maxScale) scaleCanva = maxScale + 0.1
        const workWidth = widthWorkplace > backImage.width() ? backImage.width() : widthWorkplace
        const workHeight = heightWorkplace > backImage.height() ? backImage.height() : heightWorkplace
        const pointTo = {
            x: workWidth / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: workHeight / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };
        const ppoint = {
            x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
        }
        if (scaleCanva >= minScale) {

            const coordinates = [-ppoint.x / scaleCanva, -ppoint.y / scaleCanva, (widthWorkplace / scaleCanva - ppoint.x / scaleCanva), (heightWorkplace / scaleCanva - ppoint.y / scaleCanva)];
            // const coordinates = [-canvas.x() / scaleCanva, -canvas.y() / scaleCanva, (widthWorkplace *scaleCanva  - canvas.x()) / scaleCanva, (screenRelatedHeight *scaleCanva  - canvas.y()) / scaleCanva];
            groupClick(coordinates, null, scale, canvas.height(), canvas.width(), false);
        } else if (scaleCanva > 1 && scaleCanva < minScale) {
            loadScheme()
        }
        setstageposition(ppoint);
        setScale(scaleCanva)
        // setstageposition(pointTo);
    }


    useEffect(() => {

        if (changeRoomAddresses && changeRoomAddresses?.length > 0) {
            let roomAddres = getRoomAddressForScheme(changeRoomAddresses, { x: 1, y: 1 })
            let scaless = []
            roomAddres.map((item) => {
                const evenIndexValues = item.coordinates.filter((_, index) => index % 2 === 0);  // Четные по индексу элементы
                const oddIndexValues = item.coordinates.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы

                const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
                const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов

                const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
                const maxOddIndex = (Math.max(...oddIndexValues));
                const diffX = (maxEvenIndex - minEvenIndex)
                const diffY = (maxOddIndex - minOddIndex)

                const scaleStageY = (((heightContainer - 10) / diffY));
                const scaleStageX = ((widthContainer - 10) / diffX);

                scaless.push(diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= heightContainer / scaleStageY) || (diffX <= diffY && widthContainer < heightContainer) ? scaleStageY : scaleStageX)
            }
            )
            const sum = scaless.reduce((acc, curr) => acc + curr, 0);
            const average = sum / scaless.length;
            const maxScaleChan = average;
            const minScaleChan = Math.min(...scaless) / 2;
            let mminScale = Math.min(minScaleChan, 5)
            console.log("scaless", minScaleChan)
            setmaxScale(Math.max(maxScaleChan, 7))
            //  setminScale(Math.max(mminScale, 2))
            setminScale(7)
            /* setmarkScale([{
                 value: Math.max(mminScale, 2),
                 label: `${Math.round(Math.max(mminScale, 2) * 100)}%`,
             }])*/

        } else {
            setmaxScale(7)
        }

    }, [changeRoomAddresses]);

    const handleBlur = () => {
        if (scaleCanvas < 0.5) {
            setValue(0.5);
        } else if (scaleCanvas > maxScale) {
            setValue(maxScale);
        }
    };

    /* useEffect(() => {
         if (isListClick) {
             offsetScalePosition()
         }
 
     }, [screenRelatedWidth]);*/
    const valuetext = (value) => {
        return `${Math.round(value * 100)}%`;
    }
    const getText = (value) => `${value}%`;
    if (isLoading) return;
    return <Box sx={{ display: "flex", maxHeight: window.innerHeight - 330 }}>
        {/*<Button
            onClick={toggleList}
            className="sidebar-arrow-button"
            sx={{
                transform: openSidebar ? "rotate(0deg)" : "rotate(-180deg)",
                minWidth: "0",
                borderRadius: "50% 50%",
                border: "1px solid #4339f270",
                left: openSidebar
                    ? "calc(var(--widthFullSideBar) - var(--arrowBtn) / 2)"
                    : "calc(var(--widthShortSideBar) - var(--arrowBtn) / 2)",
            }}
        >
            <ArrowIconLeftBlue />
        </Button>
        */}
        {logsMessagess && (openEventSide) &&
            <ListEvents
                toggleList={toggleList}
                handleListItemClick={handleListItemClick}
                selectedListItem={selectedListItem}
                logsMessagess={logsMessagess}
                width={screenRelatedWidth || 0}
                height={screenRelatedHeight || 0}
                scale={scale}
                StartTO={StartTO}
                handleTO={handleTO}
                openSidebar={openEventSide}
                setopenEventSide={setopenEventSide}
            />
        }

        {(window.innerWidth > 600 || (!openEventSide && window.innerWidth <= 600)) &&
            <Box ref={$container} sx={{ position: "relative  ", width: widthContainer - 300, }}>
                <Box className="plume-form_wrapper-select_container" sx={{ marginBottom: "15px" }}>
                    {!openEventSide &&
                        <Box className="full_screen_icon-wrapper">

                            <Box
                                className="list"
                                onClick={() => setopenEventSide(true)}
                            />
                        </Box>
                    }
                    {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
                        <FormItem
                            key={id}
                            name={name}
                            control={control}
                            label={label}
                            type={type}
                            searchObject={searchObject}
                            setsearchObject={setsearchObject}
                            input={(props) => <SelectSimple {...props} />}
                            {...rest}
                        />
                    ))}
                    <Button
                        className="btn btn-outlined-primary magazine_events-select_container-btn"
                        variant="outlined"
                        onClick={handlestagingTO}

                    >
                        {t("button:staging_to_TO")}
                    </Button>
                </Box>
                <Box sx={{ flexWrap: "wrap", display: "flex", width: widthContainer - 300 }}>
                    {floorScheme ? 
                        <Box>
                            <Box sx={{
                                display: "flex", height: "50px", marginTop: wwwidth < 1813 ? "20px" : 0
                            }}>
                                <Box
                                    className="less"
                                    onClick={() => { zoomOutClick() }}
                                />
                                <Slider
                                    value={scaleCanvas}
                                    step={0.1}
                                    min={0.5}
                                    marks={markScale}
                                    max={maxScale}
                                    onChangeCommitted={handleChangeSliderCommitted}
                                    sx={{ color: "#4339F2", marginLeft: "20px", marginTop: "12px", width: "200px" }}
                                />

                                <Box
                                    className="more"
                                    sx={{
                                        marginLeft: "20px"
                                    }}
                                    onClick={() => { zoomInClick() }}
                                />
                                <Tooltip title={"Исходный размер"}>
                                    <Box
                                        className="back"
                                        sx={{
                                            marginLeft: "20px"
                                        }}
                                        onClick={() => { resetPosition() }}
                                    />
                                </Tooltip>
                            </Box>

                        </Box>
                        : 
                             <Box></Box>

                    }
                </Box>




                {floorScheme ? (
                    <StagingToCanvas
                        name={`${formFields.equipmentsCoordinates}`}
                        width={screenRelatedWidth || 0}
                        height={screenRelatedHeight || 0}
                        schemaImg={floorScheme}
                        roomAddresses={screenRelatedRoomAddresses}
                        setValue={setValue}
                        formFields={formFields}
                        getValues={getValues}
                        loadScheme={loadScheme}
                        groupClick={groupClick}
                        roomsColors={roomsColors}
                        widthWorkplace={widthWorkplace}
                        heightWorkplace={heightWorkplace}
                        widthContainer={widthContainer}
                        setopenEquipDialog={setopenEquipDialog}
                        minScale={minScale}
                        setSelectedEquipId={setSelectedEquipId}
                        equipGroup={equipGroup}
                        logsRooms={logsRooms}
                        canvasRef={canvasRef}
                        imageRef={imageRef}
                        stageRef={stageRef}
                        setzoomOut={setzoomOut}
                        zoomOut={zoomOut}
                        selectedEvent={selectedListItem}
                        scale={scaleCanvas}
                        maxScale={maxScale}
                        scalecanvas={scale}
                        setScale={setScale}
                        stageposition={stageposition}
                        setstageposition={setstageposition}
                        control={control}
                        lineColor={lineColor}
                        draggable={draggable}
                        fullscreen={fullscreen}
                        setFullScreen={setFullScreen}

                    />
                ) : getValues(formFields.object) != "все" ? (
                    <Box className="plume-image_container-schema">
                        <MissingFloorSchemaForm floor={floor} setFloorScheme={setFloorScheme} />
                    </Box>
                ) :
                    (
                        <Box></Box>
                    )
                }
            </Box>
        }
    </Box >

};
