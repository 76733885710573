import { ClickableActionNameCell, RatingCell } from "./Components";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import {

  SimpleHeader,
} from "../TasksList/Components";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { t } from "i18next";
export const getColumns = ({
  control,
  columnsFromStorage,
  searchAddress,
  setsearchAddress,
  phone,
  setphone,
  searchObject,
  setsearchObject,
  searchintNumber,
  setsearchintNumber
}) =>
  [
    {
      id: "numberAndNameObjects",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityName"),
      size: "195px",
      isVisible: columnsFromStorage["name"],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <Box >
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`Name`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchObject}
                changeSearchValue={setsearchObject}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: "integrationNumber",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:integrationNumber"),
      size: "195px",
      isVisible: columnsFromStorage["integrationNumber"],
      //RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <Box >
          <SimpleHeader name={t("tableHeader:integrationNumber")} />
          <FormItem
            control={control}
            name={`Name`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchintNumber}
                changeSearchValue={setsearchintNumber}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: "facilityOverallRating",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityOverallRating"),
      size: "145px",
      isVisible: columnsFromStorage["facilityOverallRating"],
      RowCell: (props) => <RatingCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:facilityOverallRating")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </>

      ),
    },
    {
      id: "equipmentRegistry",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "195px",
      isVisible: columnsFromStorage["integrationNumber"],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:equipment")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </>

      ),
    },
    /*  {
       id: "fireCompartments",
       numeric: false,
       enableSorting: false,
       label: t("tableHeader:fireCompartments"),
       size: "200px",
       RowCell: (props) => <ClickableActionListCell {...props} />,
     }, */
    {
      id: "address",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:address"),
      isVisible: columnsFromStorage["address"],
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:address")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}>
            <AddressInputInbuilt
              className="select_in_built small-light"
              value={searchAddress}
              onChange={setsearchAddress}

              bgcolor={"var(--bgLightGrey)"}
            />
          </Box>
        </Box>

      ),
    },
    {
      id: "phone",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:object_phone"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage["phoneNumber"],
  
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:object_phone")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}>
            <SearchInbuilt
              className="select_in_built small-light"
              bgcolor={"var(--bgLightGrey)"}
              sx={{ padding: "12px 0px" }}
              searchValue={phone}
              changeSearchValue={setphone}

            />
          </Box>
        </Box>

      ),
    },
    {
      id: "tasks",
      numeric: false,
      enableSorting: false,
      mode: "diagram",
      label: t("tableHeader:tasks"),
      isVisible: columnsFromStorage["tasks"],
      size: "78px",
      clickable: false,
      HeaderCell: () => (
        <Box sx={{ width: "80px" }}>
          <SimpleHeader name={t("tableHeader:tasks")} />
          <Box sx={{ height: "24px", padding: "12px 0" }}></Box>
        </Box>

      ),
    },
    {
      id: "settings",
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
    },
    {
      id: "edit",
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);
