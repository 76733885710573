import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formConfigPerson } from "./form-config";

import FormItem from "../../components/FormItem";

export const ContactsForm = ({ control, errors }) => {
  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{
        "& .MuiAccordionSummary-expandIconWrapper svg": {
          color: "var(--black)",
        },
      }}
      className="contacts"
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography className="form-section-label">
          Контактные данные
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "16px" }}>
        <>
          <Box className="contacts_form_field">
            {formConfigPerson.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
};
