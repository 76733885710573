import React from "react";

export const ConsolesIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 10C14.2654 10 14.5198 10.1054 14.7073 10.2929C14.8949 10.4804 15.0002 10.7348 15.0002 11V20H13.0002V12H5.00022V20H3.00022V11C3.00022 10.7348 3.10558 10.4804 3.29312 10.2929C3.48065 10.1054 3.73501 10 4.00022 10H14.0002ZM9.00022 14V16H7.00022V14H9.00022ZM9.00022 4C10.1147 3.99967 11.2072 4.30972 12.1553 4.89541C13.1034 5.4811 13.8697 6.31928 14.3682 7.316L12.5782 8.211C12.2462 7.54616 11.7355 6.98697 11.1034 6.59613C10.4713 6.2053 9.74287 5.99827 8.99972 5.99827C8.25657 5.99827 7.52812 6.2053 6.89605 6.59613C6.26397 6.98697 5.75327 7.54616 5.42122 8.211L3.63222 7.316C4.13075 6.31928 4.89702 5.4811 5.84515 4.89541C6.79327 4.30972 7.88578 3.99967 9.00022 4ZM9.00022 4.99434e-07C10.8575 -0.000400691 12.6783 0.516465 14.2584 1.49267C15.8385 2.46888 17.1154 3.86584 17.9462 5.527L16.1572 6.422C15.4926 5.09297 14.471 3.97529 13.2069 3.19425C11.9428 2.41321 10.4862 1.99968 9.00022 2C7.51429 1.99968 6.05763 2.41321 4.79352 3.19425C3.52941 3.97529 2.50781 5.09297 1.84322 6.422L0.0532227 5.527C0.884079 3.86569 2.16123 2.46862 3.74151 1.49241C5.32178 0.516192 7.14273 -0.000586452 9.00022 4.99434e-07Z"
        fill="#64748B"
      />
    </svg>
  );
};
