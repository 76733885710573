import React from "react";

const LogoLabelIcon = () => {
  return (
    <svg
      width="1415"
      height="306"
      viewBox="0 0 1415 306"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M113.318 183.122L116.111 176.899C128.856 177.042 139.238 178.973 147.257 182.693C155.419 186.412 161.362 191.42 165.085 197.715C168.951 204.009 170.885 211.091 170.885 218.96C170.885 227.544 168.808 235.126 164.655 241.707C160.646 248.288 154.703 253.51 146.827 257.373C138.951 261.092 129.142 262.952 117.4 262.952H10V180.332V102.004H113.533C128.426 102.004 139.882 105.509 147.901 112.52C156.063 119.387 160.145 128.901 160.145 141.061C160.145 147.499 158.569 153.794 155.419 159.946C152.412 165.954 147.471 171.105 140.598 175.397C133.868 179.545 124.774 182.121 113.318 183.122ZM38.5683 255.012L27.6135 240.205H114.178C123.056 240.205 129.93 238.202 134.798 234.196C139.81 230.047 142.316 224.182 142.316 216.599C142.316 208.588 139.381 202.364 133.51 197.929C127.782 193.494 119.261 191.277 107.948 191.277H38.1387V169.388H105.156C112.746 169.388 119.046 167.313 124.058 163.164C129.07 158.873 131.576 152.792 131.576 144.924C131.576 139.058 129.5 134.265 125.347 130.546C121.194 126.683 115.18 124.752 107.304 124.752H27.6135L38.5683 109.944L38.1387 180.332L38.5683 255.012Z"
        fill="#4339F2"
      />
      <path
        d="M179.688 296V238.059H205.034L221.789 238.488H341.647L348.305 238.059H388.258V296H361.838V262.952H206.108V296H179.688ZM215.13 102.004H359.905V252.008H331.551V114.022L344.439 126.898H230.381L242.624 117.885C243.34 125.753 243.841 133.55 244.128 141.276C244.414 149.001 244.557 156.297 244.557 163.164C244.557 181.191 243.269 196.57 240.691 209.303C238.113 222.036 234.319 231.764 229.307 238.488C224.295 245.212 218.209 248.574 211.049 248.574L199.235 238.488C205.679 231.335 210.404 221.106 213.411 207.801C216.562 194.496 218.137 177.757 218.137 157.585C218.137 148.858 217.851 139.773 217.278 130.331C216.705 120.889 215.989 111.447 215.13 102.004Z"
        fill="#4339F2"
      />
      <path
        d="M570.143 102.004V262.952H542.004V114.236L554.892 127.112H427.086L439.974 114.236V262.952H411.621V102.004H570.143Z"
        fill="#4339F2"
      />
      <path
        d="M690.054 265.956C671.725 265.956 655.615 262.451 641.724 255.441C627.834 248.431 617.023 238.703 609.29 226.256C601.557 213.666 597.691 199.074 597.691 182.478C597.691 165.883 601.557 151.362 609.29 138.915C617.023 126.325 627.834 116.525 641.724 109.515C655.615 102.505 671.725 99 690.054 99C708.384 99 724.494 102.505 738.384 109.515C752.274 116.525 763.086 126.325 770.819 138.915C778.552 151.362 782.418 165.883 782.418 182.478C782.418 199.074 778.552 213.666 770.819 226.256C763.086 238.703 752.274 248.431 738.384 255.441C724.494 262.451 708.384 265.956 690.054 265.956ZM690.054 240.634C703.085 240.634 714.327 238.273 723.778 233.552C733.229 228.831 740.532 222.107 745.687 213.38C750.986 204.653 753.635 194.353 753.635 182.478C753.635 170.604 750.986 160.303 745.687 151.576C740.532 142.849 733.229 136.125 723.778 131.404C714.327 126.683 703.085 124.322 690.054 124.322C677.166 124.322 665.925 126.683 656.331 131.404C646.88 136.125 639.505 142.849 634.206 151.576C629.051 160.303 626.474 170.604 626.474 182.478C626.474 194.353 629.051 204.653 634.206 213.38C639.505 222.107 646.88 228.831 656.331 233.552C665.925 238.273 677.166 240.634 690.054 240.634Z"
        fill="#4339F2"
      />
      <path
        d="M1025.33 102.004V262.952H997.194V114.236L1010.08 127.112H882.276L895.164 114.236V262.952H866.81V102.004H1025.33Z"
        fill="#4339F2"
      />
      <path
        d="M1146.75 102.004C1158.78 102.004 1169.16 104.15 1177.89 108.442C1186.77 112.734 1193.57 118.743 1198.3 126.468C1203.17 134.051 1205.6 142.849 1205.6 152.864C1205.6 162.878 1203.17 171.748 1198.3 179.474C1193.57 187.056 1186.77 192.993 1177.89 197.285C1169.16 201.577 1158.78 203.723 1146.75 203.723H1074.36V179.259H1145.67C1155.41 179.259 1163 176.899 1168.44 172.178C1174.03 167.456 1176.82 161.019 1176.82 152.864C1176.82 144.709 1174.03 138.271 1168.44 133.55C1163 128.829 1155.41 126.468 1145.67 126.468H1077.15L1089.83 112.305V262.952H1061.47V102.004H1146.75Z"
        fill="#4339F2"
      />
      <path
        d="M1312.64 265.956C1294.31 265.956 1278.2 262.451 1264.31 255.441C1250.42 248.431 1239.6 238.703 1231.87 226.256C1224.14 213.666 1220.27 199.074 1220.27 182.478C1220.27 165.883 1224.14 151.362 1231.87 138.915C1239.6 126.325 1250.42 116.525 1264.31 109.515C1278.2 102.505 1294.31 99 1312.64 99C1330.97 99 1347.08 102.505 1360.97 109.515C1374.86 116.525 1385.67 126.325 1393.4 138.915C1401.13 151.362 1405 165.883 1405 182.478C1405 199.074 1401.13 213.666 1393.4 226.256C1385.67 238.703 1374.86 248.431 1360.97 255.441C1347.08 262.451 1330.97 265.956 1312.64 265.956ZM1312.64 240.634C1325.67 240.634 1336.91 238.273 1346.36 233.552C1355.81 228.831 1363.11 222.107 1368.27 213.38C1373.57 204.653 1376.22 194.353 1376.22 182.478C1376.22 170.604 1373.57 160.303 1368.27 151.576C1363.11 142.849 1355.81 136.125 1346.36 131.404C1336.91 126.683 1325.67 124.322 1312.64 124.322C1299.75 124.322 1288.51 126.683 1278.91 131.404C1269.46 136.125 1262.09 142.849 1256.79 151.576C1251.63 160.303 1249.06 170.604 1249.06 182.478C1249.06 194.353 1251.63 204.653 1256.79 213.38C1262.09 222.107 1269.46 228.831 1278.91 233.552C1288.51 238.273 1299.75 240.634 1312.64 240.634Z"
        fill="#4339F2"
      />
    </svg>
  );
};

export default LogoLabelIcon;
