import React from "react";
import { Box } from "@mui/material";
import TableBasic from "../../components/TableBasic";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { Scrollbars } from "react-custom-scrollbars";

export const PaymentHistory = ({ config, data }) => {
  const { pageNumber } = useAppSelector((state) => state?.settings);
  const { changePageNumber } = useAction();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Scrollbars style={{ width: "100%", height: 370 }}>
          <TableBasic
            columns={config}
            rows={data?.operations || []}
            handleClickCell={() => {}}
            withSearchField={false}
            isLoading={false}
            isPagination={false}
            pageNumber={pageNumber}
            changePageNumber={changePageNumber}
          />
        </Scrollbars>
      </Box>
    </Box>
  );
};
