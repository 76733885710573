import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAction } from "../../../hooks/actions";
import { useForm } from "react-hook-form";
import { historySections, paymentSection, sections } from "./form-config";
import "./styles/style.css";

import {
  useAddPaymentMutation,
  useLazyGetBillingInfoQuery,
  useEditBillingInfoMutation,
} from "../../../middleware/billingApi";
import { DEFAULT_VALUE1, DEFAULT_VALUE2 } from "./constant";

export const Billing = ({ organization, typeId }) => {
  const { t } = useTranslation();

  const [addPayment, { error: errorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAddPayment }] =
    useAddPaymentMutation();

  const [editPayment, { error: errorEdit, isSuccess: isSuccessEdit }] =
    useEditBillingInfoMutation();

  const isError = errorAdd || errorEdit;
  const isSuccess = isSuccessAdd && isSuccessEdit;

  const [billingInfo, setBillingInfo] = useState({});
  const { id } = organization || {};

  const [getBillingInfo] = useLazyGetBillingInfoQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBillingInfo({ id, typeId });
        setBillingInfo(data?.data);
      } catch (error) {
        console.error("Error fetching profile info:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control: control1,
    reset,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE1,
  });

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE2,
  });

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (!billingInfo) {
      return;
    }
    reset(billingInfo);
  }, [billingInfo, reset]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = async (data) => {
    const { isBlocked, billingRateId, billingPeriod } = data || {};
    const requestData = {
      organizationBillingId: billingInfo?.organizationBillingId,
      organizationId: id,
      isBlocked,
      billingRateId,
      organizationTypeId:typeId,
      billingPeriod,
    };
    const info = await editPayment(requestData);

    setBillingInfo(info?.data);
  };

  const onPaymentSubmit = async (data) => {
    const { amount } = data || {};
    const requestData = {
      organizationBillingId: billingInfo?.organizationBillingId,
      amount,
    };
    const info = await addPayment(requestData)
    resetField("amount");
    setBillingInfo(info?.data);
  };

  return (
    <Box sx={{ padding: "30px" }} className="template_page employee">
      <Box
        sx={{
          border: "1px solid #E2E8F0",
          padding: "20px",
          position: "relative",
        }}
      >
        <Button
          form="mainForm"
          className="btn btn-outlined-primary"
          type="submit"
          style={{ position: "absolute", top: 20, right: 20 }}
        >
          {t("button:apply")}
        </Button>

        <Typography className="big-title">
          Баланс: {billingInfo?.balance} р
        </Typography>

        <form
          className="scrolled-form"
          id="mainForm"
          onSubmit={handleSubmit1(onSubmit)}
        >
          {sections?.map(
            ({ id, title, Component, config }) =>
              Component && (
                <Component
                  key={id}
                  style={{ padding: 0 }}
                  control={control1}
                  errors={errors1}
                  data={billingInfo}
                  config={config}
                />
              )
          )}
        </form>
      </Box>

      <Box
        sx={{
          padding: "30px 0",
          display: "flex",
          flexDirection: "row",
          width: "700px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="template_page employee"
      >
        <form
          className="scrolled-form"
          id="paymentForm"
          onSubmit={handleSubmit2(onPaymentSubmit)}
        >
          {paymentSection?.map(
            ({ id, title, Component, config }) =>
              Component && (
                <Component
                  disabled={!billingInfo}
                  key={id}
                  style={{ padding: 0 }}
                  control={control2}
                  data={billingInfo}
                  errors={errors2}
                  config={config}
                />
              )
          )}
        </form>
        <Button
          form="paymentForm"
          className="btn btn-outlined-primary"
          type="submit"
          sx={{ height: "50px" }}
          disabled={!billingInfo || isLoadingAddPayment}
        >
          {t("button:make_the_payment")}
        </Button>
      </Box>

      {historySections?.map(
        ({ id, title, Component, config }) =>
          Component && (
            <Component
              key={id}
              style={{ padding: 0 }}
              control={control2}
              data={billingInfo}
              errors={errors2}
              config={config}
            />
          )
      )}
    </Box>
  );
};
