import React, { useState } from "react";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const InputHandleEditAvailability = ({
  label,
  disabled = false,
  onChange,
  error,
  value,
  Component,
  mask,
  ...rest
}) => {
  const { t } = useTranslation();

  const [isEditEnable, setIsEditEnable] = useState(false);

  const handleEditEnable = () => setIsEditEnable(!isEditEnable);

  const getValue = () => (mask ? mask(value) : value);

  return (
    <Box>
      <Typography className="read_only_personal_data_container-item-label">
        {t(label)}
      </Typography>
      {isEditEnable ? (
        <Component
          onChange={onChange}
          variant="outlined"
          error={!!error}
          helperText={
            error?.message ? t(`error_message:${error?.message}`) : ""
          }
          fullWidth
          value={value}
          disabled={!isEditEnable}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleEditEnable}>
                  <EditOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ".edit-icon": {
              opacity: 0,
            },
            "&:hover": {
              ".edit-icon": {
                opacity: 1,
                ml: "3px",
              },
            },
          }}
        >
          <Typography
            className="read_only_personal_data_container-item-content"
            sx={{ color: `var(--${error ? "red" : "black"})` }}
          >
            {getValue() || t("label:doesntHave")}
          </Typography>
          <IconButton className="edit-icon" onClick={handleEditEnable}>
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default InputHandleEditAvailability;
