import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  Select,
  Typography,
} from "@mui/material";

import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectGrouping = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  defaultValue = 0,
  bgcolor,
  height = "",
  setValue,
  disabled = false,
  isRegulation,
  ...rest
}) => {
  const { t } = useTranslation();

  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(defaultValue === 0 ? value : defaultValue);

  useEffect(() => {
    setUpdatedOptions(options);
    
    if (options?.length) {
      if (Number(value)) {
        
        const selectedItem = options
          ?.reduce((sum, { items }) => [...sum, ...items], [])
          ?.find(({ id }) => id === value);

        setSelectedValue(JSON.stringify(selectedItem) || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      } else {
        setSelectedValue(value || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      }
    }
  }, [options, value, defaultValue]);

 
  return (
    <FormControl fullWidth error={!!error && !disabled} sx={{ background: bgcolor ? bgcolor : "inherit", height: height }} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={selectedValue}
        
        disabled={disabled}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        onClose={() => setSearchValue("")}
        sx={{
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            height: 0,
          },
          maxHeight: "56px",
        }}
        {...rest}
      >
        <MyListSubheaderSearch
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
        {updatedOptions?.length ? (
          updatedOptions.map((option) => {
            const { open, items } = option || {};

            if (!items) {
              const { id, name } = option;
              return (
                <MenuItem key={id} value={id}>
                  <ListItemText primary={name || ""} />
                </MenuItem>
              );
            }
            if (items && items.length == 1) {
              const { id, name } = option;
              return (
                <MenuItem

                  key={id} value={JSON.stringify({parentId:option.id,id: items[0]?.id, name: `${name}, ${items[0]?.name}` }) || ""}>
                  <ListItemText sx={{
                    paddingLeft: "10px",
                  }} primary={`${name}, ${items[0]?.name}` || ""} />
                </MenuItem>
              );
            }

            const Items = items?.length
              ? items.map((item) => {
                const { id, name } = item;
                return (
                  <MenuItem
                    key={id}
                    sx={{
                      display: `${open ? "block" : "none"}`,
                      paddingLeft: "70px",
                    }}
                    value={JSON.stringify({...item,parentId: String(isRegulation ? option.id : id), id: String(isRegulation ? id : option.id), name: `${option.name}, ${name}` }) || ""}
                  >
                    <Typography>
                      {`${name}`}
                    </Typography>
                  </MenuItem>
                );
              })
              : null;

            return [
              <MyListSubheader
                key="MyListSubheader"
                setOptions={setUpdatedOptions}
                options={updatedOptions}
                itemOption={option}
              />,
              Items,
            ];
          })
        ) : (
          <MenuItem disabled={true}>
            <ListItemText primary={t("label:nothingFound")} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectGrouping;
