import React, {useEffect} from 'react';
import './styles/styles.css';
import {Box, Backdrop, CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ProfileMainDataForm from "./ProfileMainDataForm";
import {useGetProfileInfoQuery} from "../../../middleware/usersApi";
import {useAppSelector} from "../../../hooks/redux";
import ButtonsField from "./ButtonsField";
import {useNavigate} from "react-router-dom";
import {useAction} from "../../../hooks/actions";

const Profile = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setIsErrorRequest} = useAction();
    const {isSuccessSubmit} = useAppSelector(state => state.settings);

    const token = useAppSelector(state => state.users.token);

    const {
        data: profileData,
        isLoading: isLoadingProfileData,
        error: errorGetProfileData,
        refetch: refetchProfileData,
    } = useGetProfileInfoQuery(token, {skip: !token});

    useEffect(() => {
        setIsErrorRequest(errorGetProfileData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorGetProfileData]);

    useEffect(() => {
        if(isSuccessSubmit) {
            refetchProfileData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessSubmit]);

    if (isLoadingProfileData) {
        return (
            <Backdrop
                sx={{ color: "var(--white)", zIndex: 9999}}
                open={isLoadingProfileData}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
    return (
        <Box className="profile_container">
            <Box className="profile_container-header">
                <Typography className="title-page">
                    {t("title_page:profile")}
                </Typography>
                <ButtonsField
                  handleCancel={() =>  navigate(-1)}
                  token={token}
                />
            </Box>
            <Box className="scrolled-form">
                <ProfileMainDataForm
                    profileData={profileData}
                    token={token}
                />
            </Box>
        </Box>
    );
};

export default Profile;