import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const DetailsWithoutImage = ({ data }) => {
  const { t } = useTranslation();

  function getUniqueObjects(array) {
    const uniqueObject = {};
    array.forEach(function (currentObject) {
      let objectString = JSON.stringify(currentObject);
      uniqueObject[objectString] = currentObject;
    });

    return Object.keys(uniqueObject).map(function (objectString) {
      return uniqueObject[objectString];
    });
  }

  let counter;
  return (
    <Box>
      <Box
        sx={{
          color: "var(--additionalGrey)",
          fontFamily: "var(--IBM)",
          fontWeight: 400,
          fontSize: "var(--standart)",
          lineHeight: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "16px",
        }}
      >
        {t("label:image_missing")}
      </Box>
      <Box
        sx={{
          fontFamily: "var(--IBM)",
          fontWeight: 500,
          fontSize: "var(--standartLg)",
          lineHeight: "24px",
          color: "var(--black)",
        }}
      >
        Оборудование на этаже
      </Box>
      <Box
        sx={{
          marginBottom: "28px",
          border: "1px solid #E2E8F0",
          padding: "10px 0",
          borderRadius: "2px",
        }}
      >
        {data.map((item) => {
          return (
            <Accordion
              disableGutters={true}
              defaultExpanded
              sx={{
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  flexDirection: "row-reverse",
                  border: "none",
                  boxShadow: "none",
                  marginLeft: "62px",
                  padding: 0,
                }}
              >
                <Typography className="objects-item">
                  {`${item.fullTitle}: ${item.equipmentTypes.length}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0px 40px 10px",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                {getUniqueObjects(item.equipmentTypes).map((el) => {
                  counter = item.equipmentTypes.filter(
                    (num) => num.name === item.equipmentTypes[0].name
                  ).length;
                  return (
                    <Typography
                      className="objects-item"
                      sx={{ marginBottom: "12px", marginLeft: "62px" }}
                    >
                      {`${el.name}:
                      ${counter}`}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
      
    </Box>
  );
};

export default DetailsWithoutImage;
