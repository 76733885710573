import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Typography } from "@mui/material";
import { ratingConfig } from "./config";
import { useGetSystemOverallAnalyticsQuery } from "../../../middleware/analyticsApi";
import { useEffect } from "react";
import { useAction } from "../../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { LinarRatingBox } from "./LinarRatingBox";
import { PATHS } from "../../../constants";

export const RatingBox = () => {
  const {
    data: ratingData,
    isLoading,
    error: ratingError,
  } = useGetSystemOverallAnalyticsQuery();
  const { setIsErrorRequest } = useAction();
  const navigate = useNavigate();
  const getTextColor = (value) => {
    return value >= 0 ? "green" : "red";
  };
  const getStatus = (value) => {
    return value >= 0 ? "success" : "error";
  };
  const getTransform = (value) => {
    return value >= 0 ? "rotate(0deg)" : "rotate(180deg)";
  };
  const handleClick= (nameforLink) => {
    if(nameforLink == "totalJob") navigate(PATHS.LIST_TASKS+`?OnlyOpenTasks=${false}`)
    if(nameforLink != "totalJob" && nameforLink != "objects") navigate(PATHS.LIST_TASKS+`?status=${nameforLink}`)
  }
  const error = ratingError;

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {!isLoading && (
        <Box className="analytics_panel" >
          {ratingConfig(ratingData).map((item, index) => (
            <Box key={index} className="analytics_panel-block" sx={{cursor: item.nameforLink == "objects" ? "default":"pointer"}} onClick={() =>handleClick(item.nameforLink)}>
              <Typography className="analytics_panel-block-name">

                {item.name}
              </Typography>

              <Box className="analytics_panel-block-wrapper">
                <Typography
                  className="analytics_panel-block-amount"
                  sx={{ color: item?.color }}
                >
                  {item.amount}
                </Typography>

                {/*<Typography
                  className="analytics_panel-block-percentage"
                  sx={{ color: `${getTextColor(item.percentage)}` }}
                >
                  {Number.isInteger(item.percentage) ? item.percentage : item.percentage.toFixed(2)}%
                </Typography>
                <ArrowDropUpIcon
                  sx={{
                    transform: getTransform(item.percentage),
                  }}
                  fontSize={"large"}
                  color={getStatus(item.percentage)}
                />*/}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {/*<LinarRatingBox />*/}
    </>
  );
};
