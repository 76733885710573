import React, { useMemo,useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Tooltip,
  Box,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { useGetAllOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import {
  getEquipmentOptions,
  getUserRoleOptions,
  selectOrganization,
} from "../../ResponsibleOrganizationsList/helpers";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import { getAllOrganization } from "../helpers";
import { useGetFacFireCompSelectElementsQuery } from "../../../../middleware/facilitiesApi";
import { useGetEquipmentSystemsQuery } from "../../../../middleware/equipmentsApi";
import { useGetUserRolesSelectQuery } from "../../../../middleware/usersApi";
import { sideBarOptionsNewVersion } from "../../../../data";
import {SelectMultipleChipDeletableWithSearchField} from "../../../components/SelectMultipleChipDeletableWithSearchField";
import { PossibilityOfViewingForm } from "../AddEmployee/Sections/PossibilityOfViewingForm";
import SelectSimple from "../../../components/SelectSimple";
import SelectGroupingMultipleCheckmarks from "../../../components/SelectGroupingMultipleCheckmarks";
import InputMaskPhone from "../../../components/InputMaskPhone";
import InputPassword from "../../../components/InputPassword";
import InputText from "../../../components/InputText";
import { BasicFrom } from "../AddEmployee/Sections/BasicForm";
import { FunctionalForm } from "../AddEmployee/Sections/FunctiolForm";
import SelectMultipleChipDeletable from "../../../components/SelectMultipleChipDeletable";
import CheckboxSimple from "../../../components/CheckboxSimple";
import Switcher from "../../../components/Switcher";
export const formFields = {
  admin_employee: "admin_employee",

  fullName: "fullName",
  jobTitle: "jobTitle",
  phoneNumber: "phoneNumber",
  email: "email",
  login: "login",
  password: "password",
  roleId: "roleId",
  passwordRepeat:"passwordRepeat",
  organizationWorkingFor: "organizationWorkingFor",
  protectionObjectsIds: "protectionObjectsIds",
  equipmentKindsIds: "equipmentKindsIds",
  isContactPerson: "isContactPerson",
  objects_to_test: "Поставить на ТО",
  isActive:"isActive",
  organization_add_edit: "Создание/Редактирование организаций",
  all_organizations: "Чтение всех организаций",
  added_organizations: "Чтение добавленных организаций",
  availableOrganizations: "availableOrganizations",
  permissionsIds: "permissionsIds",
  sendMessagesByEmail: "sendMessagesByEmail",
  messageKindToSendByEmail: "messageKindToSendByEmail"
};

export const formFieldsTable = {
  name: "name",
  executiveUsers: "executiveUsers",
  organizations: "organizations",
  equipmentKindsAndTypes: "equipmentKindsAndTypes",
  tasks: "tasks",
  edit: "edit",
  avatarLink: "avatarLink",
  employeeEquipmentKinds: "employeeEquipmentKinds",
  employeeFacilities: "employeeFacilities",
  employeeFullName: "employeeFullName",
  employeePosition: "employeePosition",
  organizationType: "organizationType"
};

export const notificationByEmailOptions = [
  { status: true, name: t("label:yes") },
  { status: false, name: t("label:no") }
];

export const messageKindToSendByEmailOptions = [
  { value: -1, name: t("label:all") },
  { value: 1, name: t("label:alertNotifications") },
  { value: 2, name: t("label:mulfunctionNotifications") },
  { value: 3, name: t("label:automaticNotifications") }
];


const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SimpleSelect = ({
  options,
  label,
  onChange,
  error,
  value,
  disabled = false,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={value || ""}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          ".MuiSelect-select": { height: 0 },
          ".MuiListItemText-root": { m: 0 },
        }}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option?.value || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function getTopLevelTitles(arr) {
  return arr.map((item) => item.title);
}

const topLevelTitles = getTopLevelTitles(sideBarOptionsNewVersion).filter(
  (item) => item !== "Задачи" && item !== "Организации"
);
topLevelTitles.push("Создание/Редактирования оборудования")
topLevelTitles.push("Планирование")
const getTopLevelTooltip = (fieldTitle) => {
  return (
    sideBarOptionsNewVersion.find((item) => item.title === fieldTitle)?.name ||
    ""
  );
};

export const ObjectsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: facFireCompSelect = [] } =
    useGetFacFireCompSelectElementsQuery( searchValue );

  return <SelectMultipleChipDeletableWithSearchField
  setSearchValue={setSearchValue}
  isIds={true}
  isForEdit
  searchValue={searchValue} options={facFireCompSelect} {...props}  />;
};

export const EquipmentKindSelect = (props) => {
  const { data: equipmentSelectElements = [] } =
    useGetEquipmentSystemsQuery("");

  const selectedOptions = useMemo(
    () => getEquipmentOptions(equipmentSelectElements),
    [equipmentSelectElements]
  );

  return <SelectMultipleChipDeletable {...props} options={selectedOptions} />;
};

export const OrganizationsSelectMultipleChipDeletable = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } =
    useGetAllOrganizationsSelectQuery(searchValue);

  const selectedOptions = useMemo(
    () => getAllOrganization(organizationsSelect),
    [organizationsSelect]
  );

  return (
    <SelectGroupingMultipleCheckmarks
      {...props}
      error={null}
      value={props.value}
      onChange={props.onChange}
      options={selectedOptions}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
    />
  );
};

export const OrganizationsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } =
    useGetAllOrganizationsSelectQuery(searchValue);

  return (
    <SelectSimpleSearch
      {...props}
      sx={{ ".MuiTypography-root": { padding:0 } }}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={selectOrganization(organizationsSelect)}
    />
  );
};
export const workingCorrectlyOptions = [
  { status: true, name: t("label:unbanned") },
  { status: false, name: t("label:banned") },
];

const UserRolesSelect = (props) => {
  const { data: OrganizationsSelectOptions = [] } =
    useGetUserRolesSelectQuery();

  return (
    <SelectSimple
      {...props}
      options={getUserRoleOptions(OrganizationsSelectOptions)}
      sx={{
        ".MuiTypography-root": { maxWidth: "180px", overflowX: "hidden" },
      }}
    />
  );
};
export const formConfigSwitcher = [{
  name: formFields.isActive,
  label: "label:isActive",
  type: "switch",
  options: workingCorrectlyOptions,
  sx: {
    "& .MuiSwitch-root": {
      "& .MuiSwitch-track": {
        backgroundColor: "red"
      },
    }
  },
  rules: {},
  input: (props) => <Switcher {...props} />,
}];
export const basicInformationConfig = [
  {
    id: 1,
    name: formFields.organizationWorkingFor,
    label: "title_page:organizations",
    type: "select-text",
    flexBasis: "49%",
    rules: {
      required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
  {
    id: 2,
    name: formFields.isContactPerson,
    label: "label:isContactPerson",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 3,
    name: formFields.protectionObjectsIds,
    type: "select-multi",
    label: "label:objects",
    rules: {
      required: true,
    },
    input: (props) => <ObjectsSelect {...props} />,
  },
  {
    id: 4,
    name: formFields.equipmentKindsIds,
    label: "label:equipmentKindEployee",
    type: "select-multi",
    input: (props) => <EquipmentKindSelect {...props} />,
  },
  {
    id: 5,
    name: formFields.fullName,
    label: "label:fullName",
    type: "text",
    firstUpperCase: true,
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 6,
    name: formFields.jobTitle,
    label: "label:position",
    type: "text",
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 7,
    name: formFields.phoneNumber,
    label: "label:phone",
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
      validate: (value, formValues) =>
        value.replace(/[+() ]/g, "", "")?.length === 11,
    },
    input: (props) => <InputMaskPhone {...props} />,
  },
  {
    id: 8,
    name: formFields.email,
    label: "label:email",
    type: "text",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
      pattern:
        /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,}$/g,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 9,
    name: formFields.login,
    label: "label:loginOrMail",
    type: "text",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 10,
    name: formFields.password,
    label: "label:password",
    type: "password",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      
      validate: {
        lengthCheck: (value) => value == null || value?.length >= 6 ||  value?.length == 0 || value== "" || "password_length",
     
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
  {
    id: 11,
    name: formFields.passwordRepeat,
    label: "label:PasswordRepeat",
    type: "password",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      
      validate: {
        matchPassword: (value, formValues) =>
          value === formValues[formFields.password] || (formValues[formFields.password].length == 0 ) || formValues[formFields.password] == "" || "password_not_match",
     
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
  {
    id: 12,
    name: formFields.sendMessagesByEmail,
    label: "label:sendMessagesByEmail",
    type: "switch",
    options: notificationByEmailOptions,
    input: (props) => <Switcher {...props} />
  },
  {
    id: 13,
    name: formFields.messageKindToSendByEmail,
    label: "label:messageKindToSendByEmail",
    type: "select-number",
    flexBasis: "33%",
    width: "30%",
    options: messageKindToSendByEmailOptions,
    input: (props) => <SelectSimple {...props} />
  }
];

export const functionalFormConfig = topLevelTitles.map((item) => ({
  
  name: `${item}`,
  label: `${item}`,
  type: "checkbox",
  rules: {},
  flexBasis: "25%",
  input: (props) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "20px"
        }}
      >
        <Box sx={{ width: item != "Создание/Редактирования оборудования" ? "193px" : "400px"}}>
          <CheckboxSimple {...props} />
        </Box>
        <Tooltip
          title={item == "Создание/Редактирования оборудования" ? t(`tooltip:addEmployeeForm:addEditEquip`) : item == "Планирование" ? t(`tooltip:addEmployeeForm:planning`)  : t(`tooltip:addEmployeeForm:${getTopLevelTooltip(item)}`)  }
          componentsProps={{
            tooltip: {
              sx: {
                color: "var(--black)",
                backgroundColor: "var(--white)",
                borderRadius: 0,
                maxWidth: 300,
              },
            },
          }}
        >
          <IconButton
            className="icon-btn"
            sx={{ height: "24px", width: "24px", padding: "0" }}
          >
            <InfoIcon sx={{ color: "var(--main)" }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  },
}));

export const possibilityOfViewingFormConfig = [
  {
    name: formFields.organization_add_edit,
    label: "label:add_edit_organizations",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.availableOrganizations,
    type: "select-multi",
    label: "title_page:organizations",
    rules: {},
    width: "49%",
    input: (props) => <OrganizationsSelectMultipleChipDeletable {...props} />,
  },
];

export const objectsToTestConfig = [
  {
    id: 1,
    name: formFields.roleId,
    label: "label:role",
    type: "select-text",
    flexBasis: "49%",
    rules: {
      required: true,
    },
    input: (props) => <UserRolesSelect {...props} />,
  },
  {
    id: 2,
    name: formFields.objects_to_test,
    label: "label:objects_to_test",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    input: (props) => <CheckboxSimple {...props} />,
  },
];

export const sections = [
  {
    id: 1,
    title: "basic_information",
    Component: BasicFrom,
    config: basicInformationConfig,
  },
  {
    id: 2,
    title: "possibility_of_viewing",
    Component: PossibilityOfViewingForm,
    config: possibilityOfViewingFormConfig,
  },
  {
    id: 3,
    title: "role",
    Component: FunctionalForm,
    config: functionalFormConfig,
  },
];
