import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";

import {
  getDefaultValues,
  ProfileEmployeeFormFormConfig,
  ProfileMainDataFormFormConfig,
} from "./form-config.jsx";
import { formFields } from "./form-config";
import { useEditProfileInfoMutation } from "../../../../middleware/usersApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import {
  getRequestData,
  getRequestDataFilesForAdd,
} from "../ProfileContactData/helpers";
import { useAction } from "../../../../hooks/actions";

import FormItem from "../../../components/FormItem";
import InputBlockField from "../../../components/InputBlockField";
import FormItemCheckboxControlled from "../../../components/FormItem/FormItemCheckboxControlled";
import { WarningValidationForm } from "../../../components/Notifications/index.jsx";

const ProfileMainDataForm = ({ profileData, token, isEmpoyee = false }) => {
  const [
    editProfileInfo,
    { error: errorEditProfileInfo, isSuccess: isSuccessEditProfileInfo },
  ] = useEditProfileInfoMutation();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    handleSubmit,
    unregister,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(profileData),
  });

  const isWarningValidation = !!Object.keys(errors).length;
  const isError = errorEditProfileInfo || errorFile;
  const isSuccess = isSuccessEditProfileInfo && isSuccessFile;

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = async (data) => {
    const { entities, files } = getRequestData(data);
    const response = await editProfileInfo({ token, data: entities });
    if (!response.error) {
      if (profileData?.id) {
        const formData = getRequestDataFilesForAdd(profileData.id, files);
        manipulateFiles(formData);
      }
    }
  };

  return (
    <Box>
      <form
        id="updateProfileForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!isEmpoyee
          ? ProfileMainDataFormFormConfig.map(
              ({
                id,
                name,
                label,
                input,
                expended,
                block,
                title,
                CustomFormItem,
                Component,
                ...rest
              }) =>
                Component ? (
                  <CustomFormItem
                    key={name || id}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                ) : CustomFormItem ? (
                  <CustomFormItem
                    key={name || id}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                ) : block ? (
                  <InputBlockField
                    key={id}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    title={title}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    formFields={formFields}
                    {...rest}
                  />
                ) : expended ? (
                  <FormItemCheckboxControlled
                    key={id}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                ) : (
                  <FormItem
                    key={id}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                )
            )
          : ProfileEmployeeFormFormConfig.map(
              ({
                id,
                name,
                label,
                input,
                expended,
                block,
                title,
                CustomFormItem,
                Component,
                ...rest
              }) =>
                Component ? (
                  Component
                ) : CustomFormItem ? (
                  <CustomFormItem
                    key={name || id}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                ) : block ? (
                  <InputBlockField
                    key={id}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    title={title}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    formFields={formFields}
                    {...rest}
                  />
                ) : expended ? (
                  <FormItemCheckboxControlled
                    key={id}
                    control={control}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    getValues={getValues}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                ) : (
                  <FormItem
                    key={id}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    profileData={profileData}
                    {...rest}
                  />
                )
            )}
      </form>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default ProfileMainDataForm;
