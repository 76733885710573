import React, {useEffect, useMemo, useRef, useState} from "react";
import {COLORS_DONUT_CHART, globalFields} from "../../../constants";
import svg64 from "svg64";
import {Box} from "@mui/material";
import DonutChart from "../../components/DonutChart";

export const defineDonutChartConfig = (config,isIcons) => ({
    data: [
        
        {
            name: globalFields.completed_intime_jobs_amount,
            value: config?.completedInTimeJobsAmount || 0,
            color: COLORS_DONUT_CHART[globalFields.completed_intime_jobs_amount]
        },
        {
            name: globalFields.overdued_jobs_amount,
            value: config?.overduedJobsAmount || 0,
            color: COLORS_DONUT_CHART[globalFields.overdued_jobs_amount]
        },
        {
            name: globalFields.near_deadline_jobs_amount,
            value: config?.nearDeadlineJobsAmount || 0,
            color: COLORS_DONUT_CHART[globalFields.near_deadline_jobs_amount]
        },
        {
            name: globalFields.not_near_deadline_jobs_amount,
            value: config?.notNearDeadlineJobsAmount || 0,
            color: COLORS_DONUT_CHART[globalFields.not_near_deadline_jobs_amount]
        }

    ],
    isText: (isIcons ? config?.totalJobsAmount : ""),
    width: 20,
    height: 20,
    radius: 10,
    thickness: 2,
});

const defineDefaultConfig = () => ({
    data: [],
})

const formatProps = (Chart, config,isIcons) => {
    switch (Chart) {
        case DonutChart: return defineDonutChartConfig(config,isIcons);
        default: return defineDefaultConfig();
    }
}

export const useChartsList = ({Chart, canvas}) => {
    const chartListRef = useRef(null);
    const [chartsList, setChartsList] = useState([]);
    const context = useMemo(() => canvas?.getContext("2d"), [canvas]);

    useEffect(() => {
        if (!context) {
            return;
        }
        chartsList.forEach((item, index) => {
            const donutImg = new Image();
            const svgElement = chartListRef.current?.children[index];
            const base64fromSVG = svg64(svgElement);

            donutImg.setAttribute('src', base64fromSVG);
            donutImg.addEventListener("load", (e) => {
                context.drawImage(donutImg, item.coordinates.x, item.coordinates.y);
            })
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartsList])

    const updateChartsList = ({key, props, ...rest}) => {
        const donutComponentsFiltered = chartsList.filter(({key: id}) => id !== key);
        setChartsList([...donutComponentsFiltered, {
            key,
            props: formatProps(Chart, props,false),
            ...rest
        }]);
    }

    return [
        () => (
            <Box sx={{display: 'none'}} ref={chartListRef}>
                {chartsList.map(({key, props}) =>
                    <Chart
                        key={key}
                        {...props}
                    />
                )}
            </Box>
        ),
        updateChartsList
    ]
};

export const useChartsIcons = ({ Chart, equipments }) => {
  const chartListRef = useRef(null);

  const getSrcSVG = (index) => {
    const svgElement = chartListRef.current?.children[index]
    //console.log("svgElement",svgElement)
    return svg64(svgElement);
  }

  return [
    () => (
      <Box sx={{display: "none"}} ref={chartListRef}>
        {equipments?.map(({ id, jobChart}) =>
        
          <Chart
              key={id}
              id={id}
              {...formatProps(Chart, jobChart,true)}
          />
        )}
      </Box>
    ),
    getSrcSVG,
  ];
};