import React from "react";
import Box from "@mui/material/Box";

import "./styles/style.css";
import { sections } from "./documents-form-config";

import Header from "./Header";
import AccordionSection from "../../components/AccordionSection";

const ActsDocuments = () => {
  return (
    <Box className="acts-container">
      <Header nameForm="documents" />
      <Box className="scrolled-form scrolled-acts_panel">
        {
          sections.map(({ id, title, Component }) => (
            <AccordionSection key={id} title={title}>
              {Component && (
                <Component section={title} />
              )}
            </AccordionSection>
          ))
        }
      </Box>
    </Box>
  );
};

export default ActsDocuments;
