import React from "react";
import { Box } from "@mui/material";

import FormItem from "../../../../components/FormItem";
import { formFields } from "../../form-config";

export const PossibilityOfViewingForm = ({ control, errors, config }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "15px",
          width: "100%",
        }}
      >
        {config.map(({ id, name, label, input, ...rest }, i) => (
          <FormItem
            key={id || i}
            control={control}
            errors={errors}
            name={
              name === "availableOrganizations"
                ? name
                : `${[formFields.permissionsIds]}.${name}`
            }
            label={label}
            input={input}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
};
