import { Box } from "@mui/material";
import React from "react";
import FormItem from "../../components/FormItem";

export const Payment = ({ control, errors, config, disabled }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {config.map(({ id, name, label, input, ...rest }) => (
          <FormItem
            disabled={disabled}
            sx={{ width: "500px" }}
            key={id}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
};
