import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const InputMaskPhone = ({ label, error, onChange, value, disabled = false, InputProps = {} }) => {
  const { t } = useTranslation();

  return (
    <InputMask
        mask="+7 (999) 999 99 99"
        onChange={onChange}
        value={value}
        InputProps={InputProps}
        disabled={disabled}
    >
      {() => (
        <TextField
          type="tel"
          label={t(label)}
          onChange={onChange}
          variant="outlined"
          error={!!error}
          helperText={
            error?.message ? t(`error_message:${error?.message}`) : ""
          }
          fullWidth
          value={value}
          disabled={disabled}
          InputProps={InputProps}
        />
      )}
    </InputMask>
  );
};

export default InputMaskPhone;
