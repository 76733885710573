import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { PATHS } from "../../../constants";
import { ADDRESSES_TYPES } from "../MyOrganizations/constants";
import { getMaskPhone } from "../../../helpers/helper";

const Requisites = ({ organization, typeId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formattedOrganizationAddresses =
    // eslint-disable-next-line eqeqeq
    typeId == 2
      ? organization?.organizationResponsibleAddresses?.map((item) => {
          const { city, street, building, extension } = item?.address;
          const address = [city, street, building, extension]
            .filter((item) => item)
            .join(", ");
          return {
            ...item,
            address,
          };
        })
      : organization?.organizationAddresses?.map((item) => {
          const { city, street, building, extension } = item?.address;
          const address = [city, street, building, extension]
            .filter((item) => item)
            .join(", ");
          return {
            ...item,
            address,
          };
        });

  const ogranizationProps = {
    name: organization?.name,
    innNumber: organization?.innNumber,
    kppCode: organization?.kppCode,
    ogrnNumber: organization?.ogrnNumber,
    okpoCode: organization?.okpoCode,
    okvedCode: organization?.okvedCode,
  };

  return (
    <Box className="requisites">
      <Box className="requisites-btn-field">
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() =>
            navigate(
              PATHS.GET_EDIT_MY_ORGANIZATION(
                organization?.id,
                typeId
              )
            )
          }
        >
          {t("button:edit")}
        </Button>
      </Box>

      <Box className="requisites-content">
        {organization?.contactPersons && (
          <Box className="requisites-content-block">
            <Typography className="requisites-content-block-title">
              {t("title:contact_entities")}
            </Typography>
            {organization?.contactPersons?.map(
              ({ id, fullName, phone, email, position }) => (
                <Box key={id} className="requisites-content-block-textField">
                  <Typography
                    className="requisites-content-block-textField-text"
                    sx={{ flexBasis: "215px" }}
                  >
                    {fullName}
                  </Typography>
                  <Typography
                    className="requisites-content-block-textField-text"
                    sx={{ flexBasis: "215px" }}
                  >
                    {getMaskPhone(phone)}
                  </Typography>
                  <Typography
                    className="requisites-content-block-textField-text"
                    sx={{ flexBasis: "215px" }}
                  >
                    {email}
                  </Typography>
                  <Typography
                    className="requisites-content-block-textField-text"
                    sx={{ flexBasis: "215px" }}
                  >
                    {position}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        )}
        <Box className="requisites-content-block">
          <Typography className="requisites-content-block-title">
            {t("title:organization")}
          </Typography>
          {Object.keys(ogranizationProps).map((key, i) => (
            <Box key={i} className="requisites-content-block-textField">
              <Typography
                className="requisites-content-block-textField-label"
                sx={{ flexBasis: "50%" }}
              >
                {t(`label:${key}`)}
              </Typography>
              <Typography
                className="requisites-content-block-textField-text"
                sx={{ flexBasis: "50%" }}
              >
                {ogranizationProps[key]}
              </Typography>
            </Box>
          ))}
        </Box>

        {formattedOrganizationAddresses.map(
          ({ type, id, organizationAddressId, organizationResponsibleAddressId, ...rest }) => {
            const title =
              type === ADDRESSES_TYPES.UR ? "ur_address" : "fact_address";
            return (
              <Box
                key={organizationAddressId}
                className="requisites-content-block"
              >
                <Typography className="requisites-content-block-title">
                  {t(`title:${title}`)}
                </Typography>
                {Object.keys({ ...rest }).map((key, i) => (
                  <Box key={i} className="requisites-content-block-textField">
                    <Typography
                      className="requisites-content-block-textField-label"
                      sx={{ flexBasis: "50%" }}
                    >
                      {t(`label:${key}`)}
                    </Typography>
                    <Typography
                      className="requisites-content-block-textField-text"
                      sx={{ flexBasis: "50%" }}
                    >
                      {{ ...rest }[key]}
                    </Typography>
                  </Box>
                ))}
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};

export default Requisites;
