import { Avatar, Typography, Box, Button } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "./style.css";
import { PATHS } from "../../../constants";

import emptyAvatar from "../../../image/emptyAvatar.jpg"

export const NotificationItem = ({ notification, markNotification }) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const date = dayjs(notification?.createdAt).format(dateFormat);

  return (
    <>
      <Box className="notification-item">
        <Avatar
          alt="avatar"
          src={
            // eslint-disable-next-line eqeqeq
            notification?.notificationTypeId === 5
              ? "/notion.png"
              : notification?.assignerAvatarLink || emptyAvatar
          }
          sx={{
            backgroundColor: "white",
            marginRight: "16px",
            height: "48px",
            width: "48px",
          }}
        />
        <Box
          sx={{ cursor: "pointer" }}
          onClick={markNotification.bind(null, [notification?.id])}
        >
          <Typography className="notification-item-name">
            {notification?.assignerName}
          </Typography>
          <Box className="notification-item-date">{date}</Box>
          <Box className="notification-item-status">{notification?.text}</Box>
          <Box className="notification-item-detail">{notification?.details}</Box>
          {!!notification?.jobName && (
            <Button variant="text" >
              <Link to={PATHS.LIST_TASKS+`?notif=${notification?.jobId}`}>
                <Typography className="notification-item-link">
                  [{notification?.jobName}] - {notification?.jobNumber}
                </Typography>
              </Link>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
