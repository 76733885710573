import React, { useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import InputText from "../InputText";
import { useLazyGetAddressSelectQuery } from "../../../middleware/addressDataApi";
import { useAppSelector } from "../../../hooks/redux";

export const AddressInput = ({
  label,
  disabled = false,
  onChange,
  error,
  value,
  shouldSetCoordinates,
}) => {

  const [inputValue, setInputValue] = React.useState("");
  const [ getAddressSelect ] = useLazyGetAddressSelectQuery();
  const { addressData } = useAppSelector(state => state.addressData);
  const getRequestNewValue = (newValue) => {
    const { data } = newValue || {};
    const requestData = {
      city: data?.city || "",
      street: data?.street || "",
      building: data?.house || "",
    };
    if (shouldSetCoordinates) {
      requestData.coordinates = { 
        longitude: data?.geo_lon, 
        latitude: data?.geo_lat,
      };
    } 
    return requestData;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      getAddressSelect("");
      return undefined;
    }

    if (active) {
      getAddressSelect(inputValue)
    }

    return () => { 
      active = false;
    };
  }, [inputValue, getAddressSelect]);

  return (
    <Autocomplete
      id="address"
      getOptionLabel={(option) => option.value}
      options={addressData?.suggestions || []}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      defaultValue={() => (
        value?.city ? (
          {
            data: {},
            unrestricted_value: "",
            value: `${value.city} ${value.street} ${value.building}`
          }
        ) : null
      )}
      onChange={(event, newValue) => {
        onChange(getRequestNewValue(newValue));
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <InputText
          {...params}
          type={"text"}
          label={label}
          disabled={disabled}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};