import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  formConfigBasicInf,
  formConfigBank,
  formConfigOrganization,
  formFields,
  formConfigAddress,
  templateAddress,
  formConfigInn,
} from "./form-config";
import { ADDRESSES_TYPES } from "./constants";

import FormItem from "../../components/FormItem";
import { AddressInput } from "../../components/AddressInputOrg";

const BasicInformation = ({ okved, control, errors, disabled, indCheck, setValue, addressFromDadata, setaddressFromDadata }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: formFields.organizationAddresses,
  });
  const [setokved, setokvedCode] = useState(okved);
  const [checkedIp, setCheckedIp] = useState(fields.length === 1);
  const handleCheckbox = (e) => {
    console.log("1")
    const isChecked = e.target.checked;
    setCheckedIp(checkedIp)
    if (isChecked && fields.length === 2) remove(1)
    if (!isChecked && fields.length === 1) append(templateAddress)
    if (fields.length > 2) remove(1)
  };
  useEffect(() => {
    console.log("123")
    if (checkedIp && fields.length === 2) remove(1)
    if (!checkedIp && fields.length === 1) append(templateAddress)

  }, [checkedIp]);
  useEffect(() => {
    setokvedCode(okved)
  }, [okved]);
  useEffect(() => {
    console.log("2")
    if (fields.length > 2) remove(1)
    //setCheckedIp(fields.length === 1);
  }, [fields]);
  useEffect(() => {
    if (indCheck) {
      setCheckedIp(indCheck)
      if (indCheck && fields.length === 2) remove(1)
      if (!indCheck && fields.length === 1) append(templateAddress)
      if (fields.length > 2) remove(1)
    }
  }, [indCheck]);
  console.log("1-044525700", addressFromDadata)
  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{
        background: "var(--bgLightGrey)",
        "& .MuiAccordionSummary-expandIconWrapper svg": {
          color: "var(--black)",
        },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ flexDirection: "row-reverse" }}
      >
        <Typography className="form-section-label">
          {t("title:basic_information")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "16px", background: "var(--bgLightGrey)" }}
      >
        <>
          {formConfigInn.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "15px",
            }}
          >
            {formConfigOrganization.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                disabled={disabled}
                {...rest}
              />
            ))}
          </Box>

          {formConfigBasicInf.map(({ name, label, input, ...rest }) => (
            name == "okvedCode" ?
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}

                setokvedCode={setokvedCode}
                value={setokved}
                input={input}
                disabled={disabled}
                {...rest}
              />
              :
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}

                rules={{

                  maxLength: (v) => checkedIp ? v?.length <= 10 : v?.length <= 8,
                  validate: (value, formValues) => checkedIp ? value?.length === 10 : value?.length === 8,
                }}

                input={input}
                disabled={disabled}
                {...rest}
              />
          ))}

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setCheckedIp(prev => !prev)}
                  disabled={disabled}
                />
              }
              label={t("label:ip")}
              checked={checkedIp}
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "var(--IBM)",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "var(--standart)",
                  lineHeight: "24px",
                  color: "#212121",
                },
              }}
            />
            {fields.map((item, index) => (
              <Box key={item.id}>
                <Typography className="title-block" sx={{ margin: "15px 0" }}>
                  {t(
                    item.type === ADDRESSES_TYPES.UR
                      ? "label:ur_address"
                      : "label:fact_address"
                  )}
                </Typography>
                {formConfigAddress.map(
                  ({ name, type, label, input, rest, rules, dynamicRules }) => (
                    name == "address" && addressFromDadata ?

                      <FormItem
                        key={name}
                        control={control}
                        type={type}
                        errors={errors}
                        value={addressFromDadata[index]}
                        onChangeForOrg={setaddressFromDadata}
                        indexAdress={index}
                        label={label}
                        name={`${[
                          formFields.organizationAddresses,
                        ]}.${index}.${name}`}
                        input={(props) => (
                          <AddressInput {...props} />
                        )}
                        rules={dynamicRules ? dynamicRules[item.type] : { ...rules }}
                        disabled={disabled}
                        {...rest}
                      />
                      :
                      <FormItem
                        key={name}
                        control={control}
                        type={type}
                        errors={errors}
                        label={label}

                        name={`${[
                          formFields.organizationAddresses,
                        ]}.${index}.${name}`}
                        input={input}
                        rules={dynamicRules ? dynamicRules[item.type] : { ...rules }}
                        disabled={disabled}
                        {...rest}
                      />
                  )
                )}
              </Box>
            ))}
          </Box>

          <Box>
            <Typography className="form-section-label" sx={{ margin: "15px 0" }}>{t("label:bankRequisites")}</Typography>
            {formConfigBank.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={`${[formFields.bankDetails]}.${name}`}
                label={label}
                input={input}
                disabled={disabled}
                {...rest}
              />
            ))}
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicInformation;
