import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const equipmentsApi = createApi({
  reducerPath: "equipments/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getEquipmentSelectElements: build.query({
      query: ({ searchValue, objectId,equipmentKindId="" }) => ({
        url: `api/equipments/get-equipment-select-elements?searchValue=${searchValue}&protectionObjectId=${objectId}&equipmentKindId=${equipmentKindId}`,
      }),
    }),
    getEquipmentsForTree: build.query({
      query: (params) => ({
        url: `/api/equipments/get-equipments-by-groupid-for-tree`,
        params
      })
    }),
    getEquipmentKindSelectElements: build.query({
      query: ({params = "",useShortTitle,protectionObjectId="", hasEquipmentOnly = false}) => ({
        url: `api/equipments/get-all-equipment-kinds-and-types?searchValue=${params}&useShortTitle=${useShortTitle}&protectionObjectId=${protectionObjectId}&hasEquipmentOnly=${hasEquipmentOnly}`,
      }),
    }),
    getRoomsAddressSelection: build.query({
      query: ({floorInfoId,searchName}) => ({
        url: `api/equipments/get-rooom-address-selection?floorInfoId=${floorInfoId}&searchName=${searchName}&searchObjectIntegrationNumber=`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name,objectIntegrationNumber}) => ({
          value: id,
          name: name + (objectIntegrationNumber != "" ? `, ${objectIntegrationNumber}` : ""),
        })),
    }),
    getRoomsPlumes: build.query({
      query: ({roomAddressId,plume}) => ({
        url: `api/equipments/get-rooom-plumes?roomAddressId=${roomAddressId}&plume=${plume}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map((item) => ({
          value: item,
          name: item,
        })),
    }),
    getEquipmentReglaments: build.query({
      query: ({equipmentTypeId, equipmentKindId, fireCompartmentId, equipmentName}) => ({
        url: `api/equipments/get-equipment-regulations?equipmentTypeId=${equipmentTypeId}&equipmentKindId=${equipmentKindId}&fireCompartmentId=${fireCompartmentId}&equipmentName=${equipmentName}`,
      }),
    }),
    getEquipmentsCoordinates: build.query({
      query: (params = "") => ({
        url: `api/equipments/get-equipments-and-rooms-coordinates?${params}`,
      }),
    }),
    getEquipmentKindSelect: build.query({
      query: (params = "") => ({
        url: `api/equipments/get-equipment-kind-select-elements?${params}`,
      }),
    }),
    getEquipmentsCoordinatesPost: build.mutation({
      query: (data) => ({
        url: "/api/equipments/get-equipments-and-rooms-coordinates-post",
        method: "POST",
        body: data,
      }),
    }),
    deleteEquipment: build.mutation({
      query: (id) => ({
        url: `/api/equipments/delete/${id}`,
        method: "DELETE",
      }),
    }),
    addEquipment: build.mutation({
      query: (data) => ({
        url: "/api/equipments/add",
        method: "POST",
        body: data,
      }),
    }),
    editEquipment: build.mutation({
      query: (data) => ({
        url: "/api/equipments/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getEquipment: build.query({
      query: (id) => ({
        url: `/api/equipments/get/${id}`,
      }),
    }),
    getEquipmentNumbers: build.query({
      query: ({startNumber, count, protectionObjectId}) => ({
        url: `/api/equipments/get-equipment-numbers?startNumber=${startNumber}&count=${count}&protectionObjectId=${protectionObjectId}`,
      }),
    }),
    getEquipmentNames: build.query({
      query: ({searchValue,equipmentTypeId}) => ({
        url: `/api/equipments/get-equipment-names?searchValue=${searchValue}&equipmentTypeId=${equipmentTypeId}`,
      }),
    }),
    getLastNumberandPrefix: build.query({
      query: ({startNumber,count}) => ({
        url: `/api/equipments/get-last-number-and-prefix?startNumber=${startNumber}&count=${count}`,
      }),
    }),
    validateEquipmentNumbers: build.mutation({
      query: (data) => ({
        url: "/api/equipments/validate-equipment-number",
        method: "POST",
        body: data,
      }),
    }),
    updateEquipmentsCoordinates: build.mutation({
      query: (data) => ({
        url: "/api/equipments/update-equipments-coordinates",
        method: "POST",
        body: data,
      }),
    }),
    updateRoomAddressesCoordinates: build.mutation({
      query: (data) => ({
        url: "/api/equipments/update-room-addresses-coordinates",
        method: "POST",
        body: data,
      }),
    }),
    getEquipmentSelectElementsByFacilityId: build.query({
      query: ({ searchValue, facilityId }) => ({
        url: `api/equipments/get-equipment-select-elements-by-facility-id-groupped?actType=0&searchValue=${searchValue}&facilityId=${facilityId}`,
      }),
     
    }),
    getEquipmentSelectElementsByFacilityIdSNNME: build.query({
      query: ({ searchValue, facilityId }) => ({
        url: `api/equipments/get-equipment-select-elements-by-facility-id-groupped?actType=1&searchValue=${searchValue}&facilityId=${facilityId}`,
      }),
     
    }),
    getEquipmentRegistry: build.query({
      query: (protectionObjectId) => ({
        url: `/api/equipments/get-equipment-registry/${protectionObjectId}`,
      }),
    }),
    getRoomsColors: build.query({
      query: ({floorId}) => ({
        url: `/api/equipments/get-rooms-colors${floorId}`,
      }),
    }),
    getEquipmentList: build.query({
      query: ({ PageNumber, filters }) => ({
        url: `api/equipments/get-equipment-list?PageNumber=${PageNumber}${filters}`,
      }),
    }),
    getEquipmentSystems: build.query({
      query: () => ({
        url: `/api/equipments/get-equipment-systems`,
      }),
    }),
    getEquipmentGroups: build.query({
      query: (search) => ({
        url: `/api/equipments/get-equipment-groups?${search}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name,...rest }) => ({
          value: id,
          name: name,
          ...rest
        })),
    }),
    getEquipmentRegulations: build.query({
      query: (search) => ({
        url: `/api/equipments/get-equipment-regulations?${search}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ regulationId, name }) => ({
          value: regulationId,
          name: name,
        })),
    }),
    getEquipmentSystemsWithSearch: build.query({
      query: ({name}) => ({
        url: `/api/equipments/get-equipment-systems?name=${name}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name }) => ({
          value: id,
          name: name,
        })),
    }),
    distributeEquipmentsForPlan: build.mutation({
      query: (data) => ({
        url: "/api/equipments/distribute-equipments-for-plan-periods",
        method: "POST",
        body: data,
      }),
    }),
    getEquipForPlanPeriod: build.query({
      query: ({pageNumber,protectionObjectId,equipmentKindIds,planPeriodId,filters }) => ({
        url: `api/equipments/get-equipments-for-plan-period?pageNumber=${pageNumber}&protectionObjectId=${protectionObjectId}&equipmentKindIds=${equipmentKindIds}&planPeriodId=${planPeriodId}${filters}`,
      }),
     
    }),
    getAvailableEquipmentsForPlanning: build.query({
      query: ({pageNumber,protectionObjectId,equipmentKindIds,filters }) => ({
        url: `api/equipments/get-available-equipments-for-planning?pageNumber=${pageNumber}&protectionObjectId=${protectionObjectId}&equipmentKindIds=${equipmentKindIds}${filters}`,
      })
    }),
    printEquipmentList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/equipments/print-equipment-list?${stringParams}`,
        };
      }
    })
  })
});

export const {
  useLazyGetEquipmentRegulationsQuery,
  useGetEquipmentSelectElementsQuery,
  useLazyGetEquipmentSelectElementsQuery,
  useGetEquipmentKindSelectElementsQuery,
  useLazyGetRoomsAddressSelectionQuery,
  useLazyGetEquipmentKindSelectElementsQuery,
  useLazyGetRoomsPlumesQuery,
  useGetEquipmentKindSelectQuery,
  useGetEquipmentSystemsQuery,
  useGetEquipmentSystemsWithSearchQuery,
  useLazyGetEquipmentSystemsQuery,
  useLazyGetEquipmentReglamentsQuery,
  useGetEquipmentNumbersQuery,
  useLazyGetEquipmentsCoordinatesQuery,
  useLazyGetEquipmentGroupsQuery,
  useLazyGetRoomsColorsQuery,
  useDeleteEquipmentMutation,
  useLazyGetEquipmentListQuery,
  useGetEquipmentsCoordinatesPostMutation,
  useAddEquipmentMutation,
  useLazyGetLastNumberandPrefixQuery,
  useValidateEquipmentNumbersMutation,
  useLazyGetAvailableEquipmentsForPlanningQuery,
  useDistributeEquipmentsForPlanMutation,
  useLazyGetEquipForPlanPeriodQuery,
  useEditEquipmentMutation,
  useGetEquipmentQuery,
  useGetEquipmentNamesQuery,
  useLazyGetEquipmentNamesQuery,
  useUpdateEquipmentsCoordinatesMutation,
  useUpdateRoomAddressesCoordinatesMutation,
  useLazyGetEquipmentSelectElementsByFacilityIdQuery,
  useLazyGetEquipmentSelectElementsByFacilityIdSNNMEQuery,
  useGetEquipmentRegistryQuery,
  useLazyGetEquipmentsForTreeQuery,
  useLazyPrintEquipmentListQuery
} = equipmentsApi;
