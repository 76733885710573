import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import { SimpleHeader, SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { ClickableNameCell } from "../MyOrganizations/Components";
import { PeriodDateCell, SelectSimpleEquipKinds, ResponsibleTextCell, SelectPlanPeriods } from "./Components";
import { SelectSimpleObject } from "../EquipmentList/Components";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";

const systemOptions = [
  { value: "все", name: "все" }
]
export const getColumns = ({
  control,
  columnsFromStorage,
  searchTaskName,
  setsearchTaskName,
  searchresUser,
  setSearchresUser,
  searchresCreator,
  setSearchresCreator,

}) =>
  [
    {
      id: formFields.taskName,
      numeric: false,
      enableSorting: false,
      size: "125px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.taskName],
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:group_task")} />
          <FormItem
            control={control}

            name={`${formFields.searchParams}.${formFields.TaskName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchTaskName}
                disabled
                changeSearchValue={setsearchTaskName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.act,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.act],
      HeaderCell: () => (
        <Box sx={{ width: "200px" }}>
          <SimpleHeader name={t("tableHeader:act")} />
          <Box sx={{ padding: "12px 0" }}></Box>
          <Box sx={{ padding: "12px 0" }}></Box>
        </Box>
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:system"),
      size: 100,
      clickable: true,
      isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:system")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.EquipmentKindId}`}

            sx={{ padding: "12px 0", width: "200px" }}
            className="select_in_built small-light"
            bgcolor={"var(--bgLightGrey)"}
            input={(props) => (
              <SelectSimpleEquipKinds
                {...props}


              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.periodDate,
      numeric: false,
      enableSorting: false,
      size: "350px",
      clickable: false,
      isVisible: columnsFromStorage["planPeriodName"],
      RowCell: (props) => <PeriodDateCell {...props} />,
      HeaderCell: (props) => (
        <>
          <SimpleHeader name={t("tableHeader:planPeriodName")} />

          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.PlanPeriodId}`}

            sx={{ padding: "12px 0", width: "200px" }}
            className="select_in_built small-light"
            bgcolor={"var(--bgLightGrey)"}
            input={(props) => (
              <SelectPlanPeriods
                {...props}


              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:protectionObjectName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.protectionObjectId}`}

            sx={{ padding: "12px 0" }}
            width="200px"
            input={(props) => (
              <SelectSimpleObject
                {...props}

                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />

        </Box>
      ),
    },
    {
      id: formFields.status,
      numeric: false,
      enableSorting: false,
      size: "125px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage["completionProgress"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:task_statistics")} />
          <Box sx={{ padding: "12px 0" }}></Box>
          <Box sx={{ padding: "12px 0" }}></Box>
        </Box>
      ),
    },

    {
      id: formFields.executiveUsers,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage["executiveUsers"],
      RowCell: (props) => <ResponsibleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:executiveUsers")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
            disabled
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchresUser}
                changeSearchValue={setSearchresUser}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.responsibleCustomerUsers,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage["responsibleCustomerUsers"],
      RowCell: (props) => <ResponsibleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:responsibleCustomerUsers")} />
          <FormItem
            control={control}
            disabled
            name={`${formFields.searchParams}.${formFields.ResponsionCustomerUserName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchresCreator}
                changeSearchValue={setSearchresCreator}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
