export const getParamsText = ({ coordinates, text="", line }) => {
  const allX = coordinates.filter((e, i) => i % 2 === 0 && e);
  const allY = coordinates.filter((e, i) => i % 2 !== 0 && e);
  const width = Math.max(...allX) - Math.min(...allX);
  const height = 16;
  const positionY = ((Math.max(...allY) - Math.min(...allY)) / 10);
  const x = Math.min(...allX);
  const y = Math.min(...allY) + positionY + height * line;

  return {
    x,
    y,
    width,
    height,
    fontSize: 16,
    fill: "black",
    align: "center",
    fontStyle: "bold",
    text,
  };
};
/*export const getParamsTextWithScale = ({ coordinates, text="", line,scale =1 }) => {
  const allX = coordinates.filter((e, i) => i % 2 === 0 && e);
  const allY = coordinates.filter((e, i) => i % 2 !== 0 && e);
  const width = Math.max(...allX) - Math.min(...allX);
  const HHeight = Math.max(...allY) - Math.min(...allY);
  const height = 16;

  const positionY = ((Math.max(...allY) - Math.min(...allY)) / 2) - height;
  const fontSize =   Math.max(10, Math.min(50, Math.floor(Math.min(width, HHeight) * 0.1)));
  const x = Math.min(...allX);
  const y = Math.min(...allY) + positionY + fontSize * line;
  if(scale>1)
  //console.log("COCAO",width,height)
  return {
    x,
    y,
    width,
    height,
    fontSize: 16,
    fill: "black",
    align: "center",
    fontStyle: "bold",
    text,
  };
};*/
export const getParamsTextWithScale = ({ coordinates, text="", line,scale =1 }) => {
  const allX = coordinates.filter((e, i) => i % 2 === 0 && e);
  const allY = coordinates.filter((e, i) => i % 2 !== 0 && e);
  const width = Math.max(...allX) - Math.min(...allX);
  const height = 16;
  const positionY = ((Math.max(...allY) - Math.min(...allY)) / 10);
  const x = Math.min(...allX);
  const y = Math.min(...allY) + positionY + height * line;

  return {
    x,
    y,
    width,
    height,
    fontSize: scale<1 ? 16 : 16/scale,
    fill: "black",
    align: "center",
    fontStyle: "bold",
    text,
  };
};
