import { Box, CircularProgress } from '@mui/material';
import { SimpleTreeView, TreeItem2 } from '@mui/x-tree-view';
import { Typography, Button, Slider } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ImageSchemaSimple from '../ImageSchema/ImageSchemeSimple';
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  DragOverlay,
} from "@dnd-kit/core";
import Droppable from "../ImageSchema/Droppable";
import {
  useGetEquipmentKindSelectElementsQuery,
  useLazyGetEquipmentGroupsQuery, useLazyGetEquipmentsCoordinatesQuery,
  useLazyGetEquipmentKindSelectElementsQuery, useDeleteEquipmentMutation, useUpdateEquipmentsCoordinatesMutation, useLazyGetEquipmentsForTreeQuery
} from '../../../../middleware/equipmentsApi';
import {
  useLazyGetFacIntNumbersQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery
} from "../../../../middleware/facilitiesApi";
import CustomTreeItem from './CustomTreeItem';
import { formFields } from "../form-config";
import ArrowCancelBack from "../../../components/icons/ArrowCancelBack";
import ArrowPrevNext from "../../../components/icons/ArrowPrevNext";
import PoligonIcon from "../../../components/icons/PoligonIcon";
import FormItem from "../../../components/FormItem";
import Editpen from "../../../components/icons/Editpen";
import { ModalConfirmDialog } from "../../../components/Modals/ModalConfirmDialog";
import CloseRedIconRemove from "../../../components/icons/CloseRedIconRemove";
import { SelectSimpleObject, SelectSimpleFloor } from "./Components";
import SelectSimple from "../../../components/SelectSimple";
import { useTranslation } from "react-i18next";
import {
  getRoomAddressForRequest,
  getEquipmentsCoordinatesWithRoomId,
  getRoomAddressForScheme
} from "../../../../helpers/helper";
import { useWatch } from "react-hook-form";
import { getEquipmentIcon } from '../../../../helpers/getEquipmentIcon'
import { ICON_SIZE, PATHS } from '../../../../constants'
import { useAppSelector } from "../../../../hooks/redux";

import EditEquipmentModal from '../AddEquipmentPage/EditEquipmentModal'
import AddEquipmentModal from '../AddEquipmentPage/AddEquipmentModal'
import useContextMenu from '../../../../hooks/useContextMenu'
import ContextMenu from '../../../components/ContextMenu'
import { useAction } from "../../../../hooks/actions";
import { useNavigate } from "react-router-dom";


export const config = [
  {
    id: 1,
    name: formFields.object,
    label: `label:${formFields.object}`,
    type: "select-text",
    rules: {},
    width: "320px",
    sx: { padding: "0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleObject {...props} />,
  },

  {
    id: 2,
    name: formFields.floor,
    label: `label:${formFields.floor}`,
    type: "select-number",
    rules: {},
    width: "250px",
    sx: { padding: "0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleFloor {...props} />,
  },
];



const AddEquipmentLiteTree = ({ register, setdeletedAddress, unregister, xyGroup, setxyGroup, handleSubmitLite, handleSubmit, addplume, setaddplume, trigger, isActive, regulations, isSuccess, setError, formConfig, control, errors, setValue, getValues, watch, resetField, onOpenEditReglament, turnOnEdit, setTurnOnEdit }) => {
  const { t } = useTranslation();
  const {
    roomAddresses,
    workingCorrectly,
    floor: floor,
    equipmentsCoordinates
  } = useWatch({
    control,
  });
  const { setIsErrorSubmit, setIsSuccessSubmit } =
    useAction();
  const [treeItems, setTreeItems] = useState([]);
  const [searchObject, setsearchObject] = useState("");
  const [runOnce, setrunOnce] = useState(true);
  const [floorScheme, setFloorScheme] = useState("");
  const [searchIntNum, setsearchIntNum] = useState("");
  const [removedAddress, setRemovedAddress] = useState([]);
  const [newRoomAddresses, setNewRoomAddresses] = useState([]);
  const [isDrawPoligon, setIsDrawPoligon] = useState(false);
  const [scaleAddEquip, setscaleAddEquip] = useState(1);
  const [offsetAddEquip, setoffsetAddEquip] = useState({ x: 0, y: 0 });
  const [maxScale, setmaxScale] = useState(5);
  const [minScale, setminScale] = useState(0.5);
  const [selectedSystem, setselectedSystem] = useState(null);
  const navigate = useNavigate();
  const [selectedKind, setselectedKind] = useState(null);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedGroupId, setselectedGroupId] = useState(null);
  const [fullscreen, setFullScreen] = useState(false);
  const [PageNumber, setPageNumber] = useState([]);
  const [loading, setLoading] = useState([]);
  const [focusId, setFocusId] = useState('');
  const [placedItems, setPlacedItems] = useState([])
  const [selectedFloor, setselectedFloor] = useState()
  const [isFocusID, setisFocusID] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isEditShape, setisEditShape] = useState(true);
  const [showHeader, setshowHeader] = useState(true);
  const [selectedId, selectShape] = useState(null);
  const [selectedTreeItemId, setselectedTreeItemId] = useState(null);
  const [selectedequipId, setselectedequipId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [facilityId]: itemIds
    }));
    localStorage.setItem("currentAddEquipTreePosition", JSON.stringify(expandedItems))
  };

  const facilityId = watch(formFields.object);
  const FloorId = watch(formFields.floor);

  const [getEquipmentsCoordinates, { isFetching: isEquipmentsCoordinatesLoading }] = useLazyGetEquipmentsCoordinatesQuery();
  const [updateEquipmentCoordinates, {
    isSuccess: isSuccessUpdateEquipment,
    isLoading: isLoadingUpdateEquipment,
    isError: isErrorUpdateEquipment
  }] = useUpdateEquipmentsCoordinatesMutation();
  const [
    getfacIntNum,
    { isError: isErrorgetfacIntNum, isLoading: isLoadinggetfacIntNum },
  ] = useLazyGetFacIntNumbersQuery();
  const [getEquipmentKinds] = useLazyGetEquipmentKindSelectElementsQuery();
  const [deleteEquipment,
    {
      error: errorDelete,
      isSuccess: isSuccessDelete,
      isLoading: loadingDelete,
    },] = useDeleteEquipmentMutation();
  const [getEquipmentGroups] = useLazyGetEquipmentGroupsQuery();
  const [getEquipmentsForTree] = useLazyGetEquipmentsForTreeQuery();
  const [
    getFacilitySelectElems,
    { isError: isErrorgetFacilitySelectElems, isLoading: isLoadinggetFacilitySelectElems },
  ] = useLazyGetFacilitySelectElementsWithSearchQuery();

  const [
    getFloorOptions,
    { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions },
  ] = useLazyGetFloorOptionsSelectByFacilityQuery();

  const onAddEquipment = (systemId, kindId, groupName, groupId) => {
    setselectedKind(systemId)
    //setValue(formFields.equipmentTypeId, JSON.stringify({ id: kindId }))
    setselectedSystem(kindId)
    setselectedGroupId(groupId)
    setselectedGroup(groupName)
    setIsModalOpen(true)
  }
  useEffect(() => {
    setIsErrorSubmit(errorDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDelete]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete]);
  const onChangeName = (e, treeIdx, groupId, equipmentIdx) => {
    setTreeItems(prev => {
      const newResult = [...prev]

      newResult[treeIdx].equipments[groupId][equipmentIdx].name = e.target.value

      return newResult
    })
  }

  const onToggleTree = (id, itemType, rawId) => {

    if (itemType === 'system') {
      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );
    }
    if (itemType === 'kind') {
      const systemId = rawId.split('_')[3];

      getEquipmentGroups(`protectionObjectId=${facilityId}&equipmentKindId=${systemId}&equipmentTypeId=${id}&calcCount=${true}`).then(res => {
        if (res.data) {
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => String(eq.id) === String(id) ? ({
                ...eq,
                groups: res.data
              }) : eq)
            } : item)
          }));
        }
      });
    }
    if (itemType === 'group') {
      const systemId = rawId.split('_')[5];
      const kindId = rawId.split('_')[3];
      getEquipmentsForTree({
        equipmentGroupId: id,
        pageNumber: 0,
      }).then(res => {
        if (res.data?.data) {

          const findIndex = PageNumber.findIndex(obj => obj.id === id)
          let buffArr = PageNumber
          if (findIndex !== -1) {
            buffArr[findIndex].pageNumber = 0;
            setPageNumber(prev => buffArr)
          } else {
            setPageNumber(prev => [...prev, { id: id, pageNumber: 0 }])
          }
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => {
                return String(eq.id) === String(kindId) ? ({
                  ...eq,
                  equipments: {
                    ...eq.equipments,
                    [id]: { equipData: res.data.data, hasMore: res.data.hasMoreData }
                  }
                }) : eq
              })
            } : item)
          }))
        }
      });
    }

  };

  const handleItemExpansionToggle = (event, id, isSelected) => {
    console.log("333", id, isSelected)
    if (id.includes('equipment')) {
      setFocusId(id.split('_')[3])

      console.log("ddd", id.split('_')[1])
      if (id.split('_')[1] != null && id.split('_')[1] != "null") {
        setisFocusID(true)
        if (id.split('_')[1] != FloorId) {

          setValue(formFields.floor, id.split('_')[1])
        }
      }

    }
    if (isSelected && !id.includes('equipment') && !id.split('_')[0].includes('system')) {
      setFocusId('')

      const itemType = id.includes('kind') ? !id.includes('group') ? 'kind' : 'group' : "system";
      onToggleTree(id.split('_')[1], itemType, id);
    }
  };

  useEffect(() => {
    getFacilitySelectElems({ name: searchObject }).then(
      ({ data, isSuccess }) => {
        if (!!data?.length) {
          if (runOnce) {
            if (localStorage.getItem("currentAddEquipObject")) {

              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentAddEquipObject"))
              setValue(formFields.object, parsedCurrPlan.object)
              setselectedFloor(parsedCurrPlan.floor)
            } else {
              setValue(formFields.object, data[0].id)
            }
            setrunOnce(false)
          }

        }
      }
    );
  }, [searchObject])

  useEffect(() => {
    if (facilityId) {
      getfacIntNum({ protectionObjectId: facilityId, number: searchIntNum })
    }
  }, [searchIntNum])
  useEffect(() => {

    if (roomAddresses && roomAddresses?.length > 0) {
      let roomAddres = getRoomAddressForScheme(roomAddresses, { x: 1, y: 1 })
      let scaless = []
      roomAddres.map((item) => {
        const evenIndexValues = item.coordinates.filter((_, index) => index % 2 === 0);  // Четные по индексу элементы
        const oddIndexValues = item.coordinates.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы

        const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
        const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов

        const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
        const maxOddIndex = (Math.max(...oddIndexValues));
        const diffX = (maxEvenIndex - minEvenIndex)
        const diffY = (maxOddIndex - minOddIndex)

        const scaleStageY = (((heightContainer - 10) / diffY));
        const scaleStageX = ((widthContainer - 10) / diffX);

        scaless.push(diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= heightContainer / scaleStageY) || (diffX <= diffY && widthContainer < heightContainer) ? scaleStageY : scaleStageX)
      }
      )
      const sum = scaless.reduce((acc, curr) => acc + curr, 0);
      const average = sum / scaless.length;
      const maxScaleChan = average;
      const minScaleChan = Math.min(...scaless) / 2;
      let mminScale = Math.min(minScaleChan, 5)
      console.log("scaless", minScaleChan)
      setmaxScale(Math.max(maxScaleChan, 7))
      //  setminScale(Math.max(mminScale, 2))
      setminScale(7)
      /* setmarkScale([{
           value: Math.max(mminScale, 2),
           label: `${Math.round(Math.max(mminScale, 2) * 100)}%`,
       }])*/

    } else {
      setmaxScale(7)
    }

  }, [roomAddresses]);

  const zoomOutClick = () => {
    let scaleCanva = scaleAddEquip - minScale;
    const canvas = canvasRef.current;

    //if (scaleCanva > maxScale) scaleCanva = maxScale
    if (scaleCanva < 1) scaleCanva = 1

    const pointTo = {
      x: screenRelatedWidth / 2 / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: screenRelatedHeight / 2 / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    console.log("stage", {
      x: -(pointTo.x - screenRelatedWidth / scaleCanva) * scaleCanva,
      y: -(pointTo.y - screenRelatedHeight / scaleCanva) * scaleCanva,
    })
    const ppoint = {
      x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
      y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
    }
    setoffsetAddEquip(ppoint);
    setscaleAddEquip(scaleCanva)

  }
  const resetPosition = () => {
    setscaleAddEquip(1)
    setoffsetAddEquip({ x: 1, y: 1 })

  }
  const zoomInClick = () => {

    const canvas = canvasRef.current;
    const backImage = imageRef.current;
    let scaleCanva = scaleAddEquip + 7;
    if (scaleCanva > maxScale) scaleCanva = maxScale
    const workWidth = widthWorkplace > backImage.width() ? backImage.width() : widthWorkplace
    const workHeight = heightWorkplace > backImage.height() ? backImage.height() : heightWorkplace
    const pointTo = {
      x: workWidth / 2 / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: workHeight / 2 / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    const ppoint = {
      x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
      y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
    }
    setoffsetAddEquip(ppoint);
    setscaleAddEquip(scaleCanva)
    // setstageposition(pointTo);
  }

  useEffect(() => {
    if (facilityId) {

      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );

      getfacIntNum({ protectionObjectId: facilityId, number: searchIntNum })
      getFloorOptions(facilityId).then(
        ({ data, isSuccess }) => {
          if (!!data?.length) {
            if (localStorage.getItem("currentAddEquipObject") && runOnce) {
              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentAddEquipObject"))
              setselectedFloor(parsedCurrPlan.floor)
            } else {
              setselectedFloor(data[0].value)
            }
            if (localStorage.getItem("currentAddEquipTreePosition")) {
              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentAddEquipTreePosition"))
              setExpandedItems(parsedCurrPlan)
              const expandedLocal = JSON.parse(localStorage.getItem("currentAddEquipTreePosition"))
              expandedLocal[facilityId]?.map((item) =>
                handleItemExpansionToggle(false, item, true)
              )

            } else {
              setExpandedItems([])
            }

          }

        }
      );
    }

  }, [facilityId, getFloorOptions, setValue]);

  useEffect(() => {
    if (selectedFloor) {
      setValue(formFields.floor, selectedFloor)
    }

  }, [selectedFloor]);

  const handleAddPlume = () => {
    setshowHeader(false)
    setaddplume(true);
  };
  const handleEditPlume = () => {
    setshowHeader(false)
    selectShape(null)
    setTurnOnEdit(true);
  };
  const handleArrowBack = () => {
    setRemovedAddress((prev) => [...prev, ...newRoomAddresses.slice(-1)]);
    setNewRoomAddresses((prev) => prev.slice(0, -1));
  };

  const handleArrowForward = () => {
    setNewRoomAddresses((prev) => [...prev, ...removedAddress.slice(-1)]);
    setRemovedAddress((prev) => prev.slice(0, -1));
  };
  const handleDeleteRoom = () => {

    let adressLength = getValues(formFields.roomAddresses).length
    selectShape(null)
    if (selectedId + 1 > adressLength) {
      let arr = newRoomAddresses.filter((item, index) => index - getValues(formFields.roomAddresses).length !== selectedId);

      setNewRoomAddresses(arr);
    } else {
      let deleter = getValues(formFields.roomAddresses).filter((item, index) => index == selectedId)
      setdeletedAddress(prev => [...prev, ...deleter])
      let arr = getValues(formFields.roomAddresses).filter((item, index) => index != selectedId);
      setValue(formFields.roomAddresses, arr);


    }
  };
  const handleBackToManageEquipments = () => {
    setTurnOnEdit(false);
    setaddplume(false);
    selectShape(null)
    //setisDragabble(true)
    const prevRoomAddresses = roomAddresses?.filter(({ id }) => id);
    const newRoomAddressesRequest = getRoomAddressForRequest(newRoomAddresses);
    const addresses = prevRoomAddresses
      ? [...prevRoomAddresses, ...newRoomAddressesRequest]
      : newRoomAddressesRequest;
    console.log("dddd", addresses)
    setValue(formFields.roomAddresses, addresses);
    setshowHeader(true)
  };

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const $container = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const { SCHEMA_CANVAS1, openSidebar } = useAppSelector(state => state.settings);
  let wwwidth = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96
  const [activeId, setActiveId] = useState('');
  const { clicked, setClicked, points, setPoints } = useContextMenu()
  const [contextItem, setContextItem] = useState('')
  const [heightContainer, setheightContainer] = useState(wwwidth >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464);
  const treeWidth = $container.current?.offsetWidth * 0.35 > 450 ? $container.current?.offsetWidth * 0.35 : 450
  const [widthContainer, setwidthContainer] = useState(openSidebar ? window.innerWidth - 300 - treeWidth : window.innerWidth - treeWidth);
  const [widthWorkplace, setwidthWorkplace] = useState(openSidebar ? window.innerWidth - 625 : window.innerWidth - 315);
  const [heightWorkplace, setheightWorkplace] = useState(wwwidth >= 1813 ? window.innerHeight - 370 : window.innerHeight - 370);

  const refreshTree = async (isSystem, refreshScheme) => {
    const expandedItemsCopy = expandedItems[facilityId]

    if (isSystem) {
      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );
    }
    expandedItemsCopy?.map((item) =>
      handleItemExpansionToggle(false, item, true)
    )
    if (refreshScheme) {
      const res = await getEquipmentsCoordinates(`id=${floor}&returnEquipments=${true}&returnRooms=${true}&returnJobCharts=${false}`);
      if (res?.data) {
        const { equipmentCoordinates } =
          res.data;
        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: floor,
        }));

        setValue(formFields.equipmentsCoordinates, coordinates);
        setTurnOnEdit(false);
        setIsDrawPoligon(false);
        setNewRoomAddresses([]);
        setRemovedAddress([]);
      }
    }


  }
  const onDelete = (e) => {
    var equipmentsCoordinateFound = equipmentsCoordinates.find(el => el.id === contextItem.id);
    if (equipmentsCoordinateFound !== null) {
      equipmentsCoordinateFound.floorInfoId = null;
      equipmentsCoordinateFound.floorSchemeCoordinateX = null;
      equipmentsCoordinateFound.floorSchemeCoordinateY = null;
      equipmentsCoordinateFound.roomAddressId = null;
    }
    setValue(formFields.equipmentsCoordinates, equipmentsCoordinates);
    setPlacedItems(prev => prev.filter(el => el !== contextItem.id));
    setContextItem('');
    setClicked(false);
    const requestData = getEquipmentsCoordinatesWithRoomId({
      equipmentsCoordinates,
      rooms: roomAddresses,
    });
    console.log("event", e)
    const data = requestData.map(
      ({
        id,
        floorSchemeCoordinateX,
        floorSchemeCoordinateY,
        roomAddressId,
        floorInfoId,
        typeId,
      }) => ({
        id,
        floorSchemeCoordinateX,
        floorSchemeCoordinateY,
        roomAddressId,
        floorInfoId,
        typeId,
      })
    );
    let arr = []
    arr.push(equipmentsCoordinateFound)
    updateEquipmentCoordinates(arr).then(() => {

      refreshTree(false, false)
      setscaleAddEquip(1)
      setoffsetAddEquip({ x: 0, y: 0 })
    });

  }

  const contextMenuData = [
    {
      label: t("title:deleteEquipmentFromScheme"),
      onClick: onDelete,
      id: 1
    }
  ]

  const handleContext = (e, { id }) => {
    setContextItem({ id })
    setPoints({
      x: e.evt.pageX,
      y: e.evt.pageY
    })
    setClicked(true)

  }

  useEffect(() => {

    const treeWidth = $container.current?.offsetWidth * 0.35 > 450 ? $container.current?.offsetWidth * 0.35 * window.devicePixelRatio : 450
    console.log("$container.current?.offsetWidth * 0.35 ", treeWidth, window.innerWidth)
    const containerWidth = $container.current?.offsetWidth;
    let change = 0
    if (openSidebar)
      change += 317
    else
      change += 110
    change += treeWidth + 100 * window.devicePixelRatio

    setwidthContainer(() => (window.innerWidth - change) * window.devicePixelRatio);
    setwidthWorkplace(() => (window.innerWidth - change) * window.devicePixelRatio)
    window.addEventListener("resize", function () {
      let change = 0
      if (openSidebar)
        change += 317
      else
        change += 110
      change += treeWidth + 100 * window.devicePixelRatio
      let windowW = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96
      setwidthContainer(() => (window.innerWidth - change) * window.devicePixelRatio);
      setwidthWorkplace(() => (window.innerWidth - change) * window.devicePixelRatio)
      setheightContainer(() => windowW >= 1813 ? window.innerHeight - 464 : window.innerHeight - 464)
      setheightWorkplace(() => windowW >= 1813 ? window.innerHeight - 464 : window.innerHeight - 464)
    });
  }, [openSidebar, window.devicePixelRatio]);

  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    let width = 0
    let height = 0

    if (SCHEMA_CANVAS1.HEIGHT > SCHEMA_CANVAS1.WIDTH) {
      height = heightContainer
      width = heightContainer / (SCHEMA_CANVAS1.HEIGHT / SCHEMA_CANVAS1.WIDTH)
    } else {
      width = widthContainer
      height = widthContainer / (SCHEMA_CANVAS1.WIDTH / SCHEMA_CANVAS1.HEIGHT)
      if ((height > window.innerHeight - 464 && wwwidth >= 1813) || (height > window.innerHeight - 394 && wwwidth < 1813)) {
        height = heightContainer
        width = heightContainer / (SCHEMA_CANVAS1.HEIGHT / SCHEMA_CANVAS1.WIDTH)
      }
    }
    return SCHEMA_CANVAS1.WIDTH
      ? {
        width,
        height,
        scale: {
          x: width / SCHEMA_CANVAS1.WIDTH,
          y: height / SCHEMA_CANVAS1.HEIGHT,
        },
      }
      : {};
  }, [SCHEMA_CANVAS1, heightContainer, widthContainer]);

  const toggleHasRoom = (event) => {
    const dataFromId = event.active.id.split('_')
    const [sysIdx, kindIdx, gIdx, eqId, eqIdx] = [dataFromId[5], dataFromId[7], dataFromId[9], dataFromId[1], dataFromId[11]]

    setTreeItems(prev => {
      const newItems = [...prev];
      if (newItems) {
        if (newItems[sysIdx]?.equipmentTypes) {
          if (
            newItems?.[sysIdx]?.equipmentTypes?.[kindIdx]?.equipments
          ) {
            if (newItems[sysIdx]?.equipmentTypes[kindIdx]?.groups[gIdx]){
              const groupId = newItems[sysIdx]?.equipmentTypes[kindIdx]?.groups[gIdx]?.value;
            if (
              newItems[sysIdx].equipmentTypes[kindIdx]?.equipments[groupId] &&
              newItems[sysIdx].equipmentTypes[kindIdx]?.equipments[groupId][eqIdx]
            ) {
              const updatedEquipment = {
                ...newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId][eqIdx],
                hasRoom: true
              };
              newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId] = [
                ...newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId]
              ];
              newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId][eqIdx] = updatedEquipment;
            }
          }
        }
      }

    }

      return newItems;
  })
}
const handleChangeSliderCommitted = (event, newValue) => {
  const canvas = canvasRef.current;


  const pointTo = {
    x: widthWorkplace / 2 / scaleAddEquip - canvas.x() / scaleAddEquip,
    y: heightWorkplace / 2 / scaleAddEquip - canvas.y() / scaleAddEquip,
  };

  setscaleAddEquip(newValue);
  setoffsetAddEquip({
    x: -(pointTo.x - screenRelatedWidth / 2 / newValue) * newValue,
    y: -(pointTo.y - screenRelatedHeight / 2 / newValue) * newValue,
  });
  // stage.position({ x: -stage.x() * scaleCanvas,y: -stage.y() * scaleCanvas});

};
function handleDragEnd(event) {
  if (turnOnEdit) return;

  if (event.over && event.over.id === "droppable") {
    if (event.activatorEvent?.changedTouches) {
      const { clientX, clientY } = event.activatorEvent.changedTouches[0] || {};
      const { x, y } = event.delta || {};
      const { left, top } = event.over.rect || {};
      const xPositionIcon = ((clientX + x) - left) / scale.x - ICON_SIZE.WIDTH / 2;
      const yPositionIcon = ((clientY + y) - top) / scale.y - ICON_SIZE.HEIGHT / 2;
      const newEquipCoords = [
        ...equipmentsCoordinates,
        {
          id: event.active.id.split('_')[3],
          floorInfoId: floor,
          floorSchemeCoordinateX: xPositionIcon,
          floorSchemeCoordinateY: yPositionIcon,
          workingCorrectly: true,
          roomAddressId: null,
          roomAddressName: null,
          typeId: event.active.id.split('_')[5],
        },
      ]
      setValue(formFields.equipmentsCoordinates, newEquipCoords);
      const newItem = [{
        id: event.active.id.split('_')[3],
        floorInfoId: floor,
        floorSchemeCoordinateX: xPositionIcon,
        floorSchemeCoordinateY: yPositionIcon,
        workingCorrectly: true,
        roomAddressId: null,
        roomAddressName: null,
        typeId: event.active.id.split('_')[5],
      }]
      console.log("event.active.id", event.active.id)
      setValue(formFields.equipmentsCoordinates, newEquipCoords);
      const requestData = getEquipmentsCoordinatesWithRoomId({
        equipmentsCoordinates: newItem,
        rooms: roomAddresses,
      });
      updateEquipmentCoordinates(requestData).then(() => refreshTree(false, false))
      return;
    };

    window.onmouseup = function (e) {
      const { left, top } = event.over.rect || {};
      const offsetX = offsetAddEquip.x / scaleAddEquip
      const offsetY = offsetAddEquip.y / scaleAddEquip
      const clientX = (e.clientX - left) / scaleAddEquip
      const clientY = (e.clientY - top) / scaleAddEquip
      const xPositionIcon =
        (clientX - offsetX) / scale.x - ICON_SIZE.WIDTH / 2;
      const yPositionIcon =
        (clientY - offsetY) / scale.y - ICON_SIZE.HEIGHT / 2;
      const newEquipCoords = [
        ...equipmentsCoordinates,
        {
          id: event.active.id.split('_')[3],
          floorInfoId: floor,
          floorSchemeCoordinateX: xPositionIcon,
          floorSchemeCoordinateY: yPositionIcon,
          workingCorrectly: true,
          roomAddressId: null,
          roomAddressName: null,
          typeId: event.active.id.split('_')[5],
        },
      ]
      const newItem = [{
        id: event.active.id.split('_')[3],
        floorInfoId: floor,
        floorSchemeCoordinateX: xPositionIcon,
        floorSchemeCoordinateY: yPositionIcon,
        workingCorrectly: true,
        roomAddressId: null,
        roomAddressName: null,
        typeId: event.active.id.split('_')[5],
      }]
      console.log("event.active.id", event.active.id)
      setValue(formFields.equipmentsCoordinates, newEquipCoords);
      const requestData = getEquipmentsCoordinatesWithRoomId({
        equipmentsCoordinates: newItem,
        rooms: roomAddresses,
      });
      updateEquipmentCoordinates(requestData).then(() => refreshTree(false, false))

      window.onmouseup = false;
    };
    const eqId = event.active.id.split('_')[1]
    setPlacedItems(prev => [...new Set([...prev, eqId])])
    toggleHasRoom(event)
  }
}
const TreeItemsfetchData = (groupId, systemId, kindId, foundEquipment) => {
  if (loading.find((obj) => obj.id == groupId)) {
    const findObj = loading.map(item => {
      if (item.id === groupId) {
        return { ...item, loading: true };
      } else {
        return item;
      }
    });
    setLoading(prev => findObj)
  }
  setLoading(prev => [...prev, { id: groupId, loading: true }])

  setTimeout(() => {
    getEquipmentsForTree({
      equipmentGroupId: groupId,
      pageNumber: PageNumber.find((item) => item.id == groupId).pageNumber + 1,
    }).then(res => {
      if (res.data?.data) {
        const findObj = PageNumber.map(item => {
          if (item.id === groupId) {
            return { ...item, pageNumber: item.pageNumber + 1 };
          } else {
            return item;
          }
        });
        setPageNumber(prev => findObj)
        setTreeItems(prev => prev.map(item => {
          return (String(item.id) === String(systemId) ? {
            ...item,
            equipmentTypes: item.equipmentTypes.map(eq => {
              return String(eq.id) === String(kindId) ? ({
                ...eq,
                equipments: {
                  ...eq.equipments,
                  [groupId]: { hasMore: res.data.hasMoreData, equipData: [...foundEquipment.equipData, ...res.data.data] }
                }
              }) : eq
            })
          } : item)
        }))
        const findObjLoad = loading.map(item => {
          if (item.id === groupId) {
            return { ...item, loading: false };
          } else {
            return item;
          }
        });
        setLoading(prev => findObjLoad)
      }
    });
  }, 1000);

}
const handleScroll = (e, groupId, systemId, kindId) => {
  const foundSystem = treeItems.find((item) => item.id == systemId)

  const foundKind = foundSystem != null ? foundSystem.equipmentTypes.find((item) => item.id == kindId) : null

  const foundEquipment = foundKind != null ? foundKind.equipments[groupId] : null
  const bottomScrollRange = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
  if ((bottomScrollRange > -1 && bottomScrollRange < 10) &&
    (!loading.find((item) => item.id == groupId) ||
      !loading.find((item) => item.id == groupId).loading) && foundEquipment?.hasMore) {
    TreeItemsfetchData(groupId, systemId, kindId, foundEquipment)
  }
};
const deleteEquip = (equipID) => {
  // console.log("selectedTreeItemId",selectedTreeItemId.split('_'))
  const dataFromId = selectedTreeItemId.split('_')
  const [sysIdx, kindIdx, gIdx, eqId, eqIdx] = [dataFromId[5], dataFromId[7], dataFromId[9], dataFromId[1], dataFromId[11]]


  deleteEquipment(equipID).then(() => {
    //onToggleTree(selectedTreeItemId.split('_')[3], "kind", selectedTreeItemId);
    setOpenModal(false)
    refreshTree(false, false)
    setselectedequipId(null)
    setselectedTreeItemId(null)
  });

}
const openConfirmToDeleteEquip = (equipID, equipItemId) => {
  setOpenModal(true)
  setselectedequipId(equipID)
  setselectedTreeItemId(equipItemId)
}
const habdleDisagreeReplace = () => {
  setOpenModal(false)
  setselectedequipId(null)
}
const openEditModal = (equipID) => {
  setIsModalEditOpen(true)
  setselectedequipId(equipID)
}

return (

  <Box ref={$container} sx={{ height: "calc( 100vh - 300px)" }}>
    {clicked &&
      <ContextMenu
        data={contextMenuData}
        position={points}
      />
    }
    <form id="myform" onSubmit={handleSubmit(handleSubmitLite)}>
      <DndContext
        onDragStart={(event) => {
          setActiveId(event.active.id)
        }}

        onDragEnd={handleDragEnd}
        className="schema"
        sensors={sensors}
      >
        <Box>
          <Box className={`addEquipment-wrapper-buttons ${turnOnEdit ? "column" : ""}`} sx={{ justifyContent: "flex-start" }}>
            {!turnOnEdit && !addplume ? (
              <>

                {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
                  <FormItem
                    key={id}
                    name={name}
                    control={control}
                    label={label}
                    type={type}

                    searchObject={searchObject}
                    setsearchObject={setsearchObject}
                    input={(props) => <SelectSimple sx={{ marginRight: "20px" }} {...props} />}
                    {...rest}
                  />
                ))}


                {showHeader && <Box >
                  <Box sx={{
                    display: "flex", height: "50px",
                  }}>
                    <Box
                      className="less"
                      onClick={() => { zoomOutClick() }}
                    />
                    <Slider
                      aria-label="Volume"
                      value={scaleAddEquip}
                      step={0.1}
                      min={0.5}
                      max={maxScale}
                      onChangeCommitted={handleChangeSliderCommitted}
                      sx={{ color: "#4339F2", marginLeft: "20px", marginTop: "12px", width: "200px" }}
                    />
                    <Typography sx={{ marginTop: "15px", marginLeft: "20px", fontWeight: 600, fontSize: 16 }}>{`${Math.round(scaleAddEquip * 100)}%`}</Typography>
                    <Box
                      className="more"
                      sx={{
                        marginLeft: "20px"
                      }}
                      onClick={() => { zoomInClick() }}
                    />
                    <Box
                      className="back"
                      sx={{
                        marginLeft: "20px"
                      }}
                      onClick={() => { resetPosition() }}
                    />
                  </Box>

                </Box>
                }
                <Button
                  className="btn btn-outlined-primary magazine_events-select_container-btn"
                  variant="outlined"
                  onClick={handleAddPlume}
                  disabled={turnOnEdit || !floorScheme || addplume}
                >
                  {t("button:add_zone")}
                </Button>
                <Button
                  className="btn btn-outlined-primary magazine_events-select_container-btn"
                  variant="outlined"
                  onClick={handleEditPlume}
                  disabled={turnOnEdit || !floorScheme || addplume}
                >
                  {t("button:edit_zone")}
                </Button>

              </>
            ) : (
              <Button
                className="btn btn-outlined-primary back_manage_equipment"
                onClick={handleBackToManageEquipments}
              >
                {t("button:back_manage_equipment")}
              </Button>
            )}
            {addplume && (
              <Box className="addEquipment-wrapper-buttons-manage">
                <Button onClick={() => setIsDrawPoligon((prev) => !prev)}>
                  <PoligonIcon isActive={isDrawPoligon} />
                </Button>
                <Button
                  onClick={handleArrowBack}
                  disabled={!newRoomAddresses?.length}
                >
                  <ArrowCancelBack isActive={newRoomAddresses?.length} />
                </Button>
                <Button
                  onClick={handleArrowForward}
                  disabled={!removedAddress?.length}
                >
                  <ArrowPrevNext isActive={removedAddress?.length} />
                </Button>
              </Box>
            )}
            {turnOnEdit && (
              <Box className="plume-form_wrapper-buttons-manage">
                <Button onClick={() => setisEditShape((prev) => !prev)}>
                  <Editpen isActive={isEditShape} />
                </Button>
                <Button onClick={handleDeleteRoom}>
                  <CloseRedIconRemove isActive={isEditShape && selectedId != null} />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', }}>
          <Box sx={{ flexBasis: '35%', maxHeight: 'calc(100vh - 320px)', minWidth: "450px", overflowY: 'scroll' }}>
            <SimpleTreeView
              expandedItems={expandedItems[facilityId]}
              onExpandedItemsChange={handleExpandedItemsChange}
              onItemExpansionToggle={handleItemExpansionToggle}
              onItemSelectionToggle={handleItemExpansionToggle}
            >
              {
                !!treeItems?.length && treeItems.map((system, sysIdx) => (
                  <CustomTreeItem
                    key={`system_${system.id}`}
                    label={system.title}
                    itemId={`system_${system.id}`}
                    onAddEquipment={() => onAddEquipment(system.id, null, null, null)}
                  >

                    {
                      !!system?.equipmentTypes?.length && system.equipmentTypes.map((el, index) => (
                        <CustomTreeItem
                          isKind
                          key={`kind_${el.id}_system_${system.id}`}
                          label={el.name}
                          itemId={`kind_${el.id}_system_${system.id}`}
                          onAddEquipment={() => onAddEquipment(system.id, el.id, null, null)}
                        >
                          {
                            el.groups && el.groups.map((g, gIdx) => (
                              <CustomTreeItem
                                onOpenEditReglament={() => onOpenEditReglament(g.value)}
                                onAddEquipment={() => onAddEquipment(system.id, el.id, g.name, g.value)}
                                isGroup
                                key={g.value}
                                countPlaced={g.countPlaced}
                                countNonPlaced={g.countNonPlaced}
                                label={g.name}
                                itemId={`group_${g.value}_kindId_${el.id}_system_${system.id}`}
                              >
                                <Box sx={{ maxHeight: "200px", overflowY: "auto" }} onScroll={(e) => handleScroll(e, g.value, system.id, el.id)}>
                                  {el.equipments && el.equipments[g.value] && el.equipments[g.value].equipData.map((e, eqIdx) => (
                                    <React.Fragment key={`equipment_${e.id}_eqTypeId_${e.typeId}`}>
                                      <CustomTreeItem equipmentId={e.id} equipIndex={eqIdx + 1} canDelete={e.enableForDelete} canEdit={e.enableForEdit} editEquip={() => openEditModal(e.id)} deleteEquip={() => openConfirmToDeleteEquip(e.id, `floor_${e.floorInfoId}_equipment_${e.id}_eqTypeId_${e.typeId}_sysIdx_${sysIdx}_kindIdx_${index}_gIdx_${gIdx}_eqIdx_${eqIdx}`)} placedItems={placedItems} item={e} hasPlacement={e.hasPlacement} equipmentTypeId={e.typeId} onChangeName={(e) => onChangeName(e, index, g.value, eqIdx)} isForEdit={e.isForEdit} key={e.id} label={e.name} itemId={`floor_${e.floorInfoId}_equipment_${e.id}_eqTypeId_${e.typeId}_sysIdx_${sysIdx}_kindIdx_${index}_gIdx_${gIdx}_eqIdx_${eqIdx}`} />
                                      <DragOverlay>
                                        {activeId ? (
                                          <img src={getEquipmentIcon(e.typeId)} />
                                        ) : null}
                                      </DragOverlay>
                                    </React.Fragment>
                                  )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />}
                                  {loading.find((item) => item.id == g.value)?.loading && (
                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <CircularProgress />
                                    </Box>
                                  )}
                                </Box>
                              </CustomTreeItem>
                            )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />
                          }
                        </CustomTreeItem>
                      ))
                    }
                  </CustomTreeItem>
                ))
              }
            </SimpleTreeView>
          </Box>
          <Box className="addEquipment-image_container" sx={{}}>
            <Droppable>
              <ImageSchemaSimple
                focusId={focusId}
                customNewRoomAddresses={newRoomAddresses}
                setCustomNewRoomAddresses={setNewRoomAddresses}
                isLite
                onRightClick={handleContext}
                setFocusId={setFocusId}
                control={control}
                openEditModal={openEditModal}
                errors={errors}
                setValue={setValue}
                addplume={addplume}
                setisFocusID={setisFocusID}
                isFocusID={isFocusID}
                isDrawPoligon={isDrawPoligon}
                setIsDrawPoligon={setIsDrawPoligon}
                scaleAddEquip={scaleAddEquip}
                setscaleAddEquip={setscaleAddEquip}
                screenRelatedWidth={screenRelatedWidth}
                screenRelatedHeight={screenRelatedHeight}
                scale={scale}
                isEditShape={isEditShape}
                searchIntNum={searchIntNum}
                setsearchIntNum={setsearchIntNum}
                selectedId={selectedId}
                selectShape={selectShape}
                imageRef={imageRef}
                canvasRef={canvasRef}
                xyGroup={xyGroup}
                widthWorkplace={widthWorkplace}
                heightWorkplace={heightWorkplace}
                setxyGroup={setxyGroup}
                turnOnEdit={turnOnEdit}
                setTurnOnEdit={setTurnOnEdit}
                setFloorScheme={setFloorScheme}
                floorScheme={floorScheme}
                offsetAddEquip={offsetAddEquip}
                setoffsetAddEquip={setoffsetAddEquip}
                getValues={getValues}
                resetField={resetField}
                objectId={facilityId}
              />
            </Droppable>
          </Box>
        </Box>
      </DndContext>
    </form>
    <ModalConfirmDialog
      open={openModal}
      handleAgreeButton={() => deleteEquip(selectedequipId)}
      handleDisagreeButton={habdleDisagreeReplace}
      contentText={t("modal_content_text:delete_equipment")}
    />
    <AddEquipmentModal
      register={register}
      unregister={unregister}
      handleSubmit={handleSubmit}
      trigger={trigger}
      formFields={formFields}
      isActive={isActive}
      setTreeItems={setTreeItems}
      setselectedGroup={setselectedGroup}
      setselectedGroupId={setselectedGroupId}
      selectedGroupId={selectedGroupId}
      selectedequipId={selectedequipId}
      setselectedequipId={setselectedequipId}
      handleItemExpansionToggle={handleItemExpansionToggle}
      selectedGroup={selectedGroup}
      setExpandedItems={setExpandedItems}
      expandedItems={expandedItems}
      refreshTree={refreshTree}
      setselectedSystem={setselectedSystem}
      selectedSystem={selectedSystem}
      setselectedKind={setselectedKind}
      selectedKind={selectedKind}
      regulations={regulations}
      isSuccess={isSuccess}
      watch={watch}
      objectId={facilityId}
      FloorId={FloorId}
      setError={setError}
      setOpen={setIsModalOpen}
      resetField={resetField}
      isOpen={isModalOpen}
    />
    <EditEquipmentModal
      equipId={selectedequipId}
      refreshTree={refreshTree}
      setselectedequipId={setselectedequipId}
      setOpen={setIsModalEditOpen}
      isOpen={isModalEditOpen}
    />
  </Box>
);
};

export default AddEquipmentLiteTree;
