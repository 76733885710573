import { t } from "i18next";
import React from "react";
import { formFields } from "./formFields";
import CustomFormItem from "../CustomFormItem";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputTextarea from "../../../../../components/InputTextarea";
import InputText from "../../../../../components/InputText";
import EquipColumn from "../EquipColumn";
import EquipColumnExist from "../EquipColumnExist";
import {getEquipmentFormConfig} from "./equipments"
const name = "alertMeasurement";

export const measuringEquipmentFileds = {
  name: "name",
  manufacturer: "manufacturer",
  serialNumber: "serialNumber",
};

export const measuringEquipmentConfig = [
  {
    name: measuringEquipmentFileds.name,
    label: "label:measuringEquipmentName",
    type: "text",
    disabled: true,
    value: "Шумометр",
    rules: {},
    input: (props) => <InputText {...props} />,
  },
  {
    name: measuringEquipmentFileds.manufacturer,
    label: "label:manufacturer",
    type: "text",
    rules: {},
    input: (props) => <InputText {...props} />,
  },
  {
    name: measuringEquipmentFileds.serialNumber,
    label: "label:serialNumber",
    type: "text",
    rules: {},
    input: (props) => <InputText {...props} />,
  },
];

export const flameDetectorsFormConfig = {
  name,
  items: [
    {
      name: formFields.number,
      type: "number",
      rules: {},
      disabled: true,
      value: 7,
      sx: {
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: (props) => <InputText {...props} />,
    },
    {
      name: formFields.testName,
      type: "text",
      rules: {},
      disabled: true,
      variant:"standard",
      rows:2,
    InputProps:{
      disableUnderline: true
    },
      value: t(`label:testNameItems:${name}`),
      sx: {
        "& .MuiOutlinedInput-root": {
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: (props) => (
        <Box sx={{ display: "flex" }}>
          <InputTextarea {...props} />
          <Tooltip title={t(`label:AFADocumentTooltips:${name}`)}>
            <IconButton className="icon-btn">
              <InfoIcon sx={{ color: "var(--main)" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      name: formFields.equipments,
      CustomFormItem: (props) => (
        !props.isActExist ?
          <EquipColumn
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
          :
          <EquipColumnExist
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
      )
    },
    {
      name: formFields.measuringEquipment,
      CustomFormItem: (props) => (
        <CustomFormItem formConfig={measuringEquipmentConfig} {...props} />
      ),
    },
    
  ],
};
