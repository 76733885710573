import React from "react";

const PlumeIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fillOpacity="0.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 16C16.4477 16 16 16.4477 16 17V19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19V17C18 16.4477 17.5523 16 17 16ZM14 17C14 15.3431 15.3431 14 17 14C18.6569 14 20 15.3431 20 17V19C20 20.6569 18.6569 22 17 22C15.3431 22 14 20.6569 14 19V17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C8.44772 6 8 6.44772 8 7V9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9V7C10 6.44772 9.55228 6 9 6ZM6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7V9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9V7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5C14 4.44772 14.4477 4 15 4H17C17.5523 4 18 4.44772 18 5V10H19C19.5523 10 20 10.4477 20 11C20 11.5523 19.5523 12 19 12H15C14.4477 12 14 11.5523 14 11C14 10.4477 14.4477 10 15 10H16V6H15C14.4477 6 14 5.55228 14 5ZM6 15C6 14.4477 6.44772 14 7 14H9C9.55228 14 10 14.4477 10 15V20H11C11.5523 20 12 20.4477 12 21C12 21.5523 11.5523 22 11 22H7C6.44772 22 6 21.5523 6 21C6 20.4477 6.44772 20 7 20H8V16H7C6.44772 16 6 15.5523 6 15Z"
      />
    </svg>
  );
};

export default PlumeIcon;
