import useScreenSize from "../../../../hooks/useScreenSize";
import { screenSizes } from "../../../../constants";
import { Box, IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { menuItems } from "./constants";
import InfoIcon from '@mui/icons-material/Info';

const sx = {
  fontFamily: "var(--IBM)",
  fontWeight: 500,
  fontSize: "var(--standartLg)",
  lineHeight: "24px",
  color: "var(--black)",
  marginRight: "10px",
  cursor: "pointer",
  textDecoration: "none",
}

const AdditionalInfo = () => {
  
  const {width} = useScreenSize();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (width <= screenSizes.tablet) {
    return (
      <>
        <IconButton
          aria-expanded={anchorEl}
          onClick={handleClick}
          sx={{color: "var(--main)"}}
        >
          <InfoIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {menuItems.map(({ id, Component, ...props }) => (
            <MenuItem key={id}>
              <Component sx={sx} {...props} />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  return (
    <Box sx={{ display: "flex" }}>
      {menuItems.map(({id, Component, ...props}) => (
        <Box key={id} sx={sx}>
          <Component sx={sx} {...props}/>
        </Box>
      ))}
    </Box>
  )
}

export default AdditionalInfo;