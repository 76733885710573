import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BasicInformationForm } from "./BasicInformationForm.jsx";
import { AdditionalInformationForm } from "./AdditionalInformationForm.jsx";
import { DocumentationsForm } from "./DocumentationsForm";
import { formFields } from "./form-config.jsx";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const BasicTabs = ({ equipmentSystems,methods, value, setValue,readonly }) => {
  const { t } = useTranslation();
  const [expandedAccordions, setExpandedAccordions] = useState(['baseAcc0', 'baseAcc1', 'baseAcc2', 'baseAcc3', 0, 1, 2]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedAccordions([...expandedAccordions, panel]);
    } else {
      setExpandedAccordions(expandedAccordions.filter((item) => item !== panel));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} >
          <Tab className="label" label={t("tabs:basic_information")} />
          <Tab className="label" label={t("tabs:additional_information")} />
          <Tab className="label" label={t("tabs:documentations")} />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={0}
        className="scrolled-form scrolled-tab_panel"
      >
        <BasicInformationForm readonly={readonly} handleAccordionChange={handleAccordionChange} expandedAccordions={expandedAccordions} equipmentSystems={equipmentSystems} />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className="scrolled-form scrolled-tab_panel"
      >
        <AdditionalInformationForm readonly={readonly} handleAccordionChange={handleAccordionChange} expandedAccordions={expandedAccordions} />
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className="scrolled-form scrolled-tab_panel"
      >
        <DocumentationsForm readonly={readonly} />
      </TabPanel>
    </Box>
  );
};
