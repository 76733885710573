import React from "react";
import { useTranslation } from "react-i18next";
import {
  ListItemText,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectSimple = ({
  options,
  label,
  onChange,
  error,
  value,
  itemValue,
  disabled = false,
  displayEmpty = false,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error && !disabled} {...rest}>
      {displayEmpty ?
        <InputLabel shrink={true}>{t(label)}</InputLabel>
        :
        <InputLabel >{t(label)}</InputLabel>

      }
      <Select
        value={(value || "")}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          ".MuiSelect-select": { height: 0 },
          ".MuiListItemText-root": { m: 0 },
        }}
        disabled={disabled}
        displayEmpty={displayEmpty}
      >
        {displayEmpty &&
          <MenuItem key={"none"} value="">
            Нет
          </MenuItem>

        }
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option?.value || value?.name || option?.name || option?.[itemValue] || option || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <Tooltip key={i} title={option?.hint || ""}>
              <ListItemText
                primary={
                  <Typography>
                    {option?.name || option?.regulationName || option || ""}
                  </Typography>
                }
              />
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimple;
