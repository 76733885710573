import React,{useState,useMemo,useEffect} from "react";
import { useAppSelector } from "../../../hooks/redux";
import SelectSimple from "../../components/SelectSimple";
import SelectGrouping from "../../components/SelectGrouping";
import {
  useGetFacilitiesForPlaningQuery,
} from "../../../middleware/facilitiesApi";
import {
  getFacilitiesOptionsGroup,

} from "./helpers";

export const SelectSimpleObject = (props) => {
 /* const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );*/
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetFacilitiesForPlaningQuery({searchValue:searchValue});

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch])

  const options = useMemo(() => {
    return data
      ? data//getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SelectGrouping
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      defaultValue={0}
      //bgcolor={bgcolor}
    />
  );
  //return <SelectSimple options={facilitySelectElements || []} {...props} />;
};

export const SelectSimpleEquipKinds = (props) => {
  const { equipmentKindsFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={equipmentKindsFacility} {...props} />;
};