import React from "react";
import dayjs from "dayjs";
import { Box, Chip, Typography } from "@mui/material";

import { PRIORITY, STATUS, DEADLINE_STATUS_COLOR } from "./form-config";
import { dateFormat,dateFormatWithTimeWithoutA } from "../../../constants";

import { PATHS } from "../../../constants";

export const PriorityCell = ({ item }) => {
  const { color } = PRIORITY[item] || {};

  return (
    <Box className="point_and_text_cell">
      <Box
        className="point_and_text_cell-point"
        sx={{ backgroundColor: color }}
      ></Box>
      <Typography className="point_and_text_cell-text" sx={{ color: color }}>
        {item}
      </Typography>
    </Box>
  );
};

export const StatusCell = ({ item }) => {
  const { color } = STATUS[item] || {};

  return <Chip className="chip" sx={{ backgroundColor: color }} label={item} />;
};

export const SimpleTextCell = ({ item, type }) => {
  const content = type === "date" ? dayjs(item).format(dateFormat) : type === "dateTime" ? dayjs(item).format(dateFormatWithTimeWithoutA) : item;
  
  return <Typography>{content}</Typography>;
};

export const DeadlineStatusTextCell = ({ item }) => (
  <Box className="point_and_text_cell">
    <Box
      className="point_and_text_cell-point"
      sx={{ backgroundColor: DEADLINE_STATUS_COLOR[item] }}
    ></Box>
    <Typography className="point_and_text_cell-text">
      {item}
    </Typography>
  </Box>
);
export const EquipmentsCell = ({ item, navigate }) => {
  console.log("item",item)
  return item?.map(({ id, name }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
    >
      {`${name}`}
    </Typography>
  ));
};
export const JobEquipmentsCell = ({ item, navigate }) => {
  console.log("item",item)
  return item?.map(({ id, integrationNumber, name }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
    >
      {`${integrationNumber ? `${integrationNumber},` : ""} ${name}`}
    </Typography>
  ));
};

export const ClickableJobNameCell = ({ item }) => {
  const { taskName, action } = item || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {taskName || ""}
    </Typography>);
};

export const SimpleHHeader = ({ name }) => (
  <Box sx={{paddingTop:"2px"}} className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
