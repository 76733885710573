import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useGetResponsibleOrganizationsUsersQuery } from "../../../../middleware/usersApi";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import { useWatch } from "react-hook-form";
import { formFields } from "./options";
import { Tooltip } from "@mui/material";
export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY, HH:mm";
  const content = dayjs(item).format(dateFormat);

  return <Typography className="standart_lg_text">{content}</Typography>;
};

export const SimpleTextCell = ({ item }) => {
  const content = item;

  return <Typography className="standart_lg_text">{content}</Typography>;
};

export const TechCardCell = ({ item }) => {

  const { action } = item || {};


  return (
    <Typography
      className="table-row-cell-link"

      onClick={action ? action : () => { }}

    >
      {"ТК"}
    </Typography>);
};
export const SimpleTextWithTooltipCell = ({ item }) => {
  const content = item;

  return <Tooltip title={content} ><Typography sx={{
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  }} className="standart_lg_text">{content}</Typography></Tooltip>;
};
export const InformationIcontWithTooltipCell = ({ item }) => {
  const content = item;

  return <Tooltip title={content} >
    <img
      alt={"name"}
      height={"25px"}
      width={"25px"}
      src={require("../../../../image/informationBtn.png")}
    />
  </Tooltip>;
};

export const AboutTaskTyp = (props) => {

  const dateFormat = "DD.MM.YYYY HH:mm";
  const date = props.value ? dayjs(props.value).format(dateFormat) : ""
  const label = props.label ? props.label : ""

  return (
    <Box sx={{ display: "flex" }}>

      <Box >
        <Typography sx={{fontWeight:"bold !important", fontSize:"16px !important",width:"160px !important"}}>
          {label}
        </Typography>
      </Box>
      <Box >
        <Typography>
          {date}
        </Typography>
      </Box>
    </Box>
  );
};

export const ExecutiveUserNameSelectSimple = (props) => {
  const {control} = props
  const facility = useWatch({
    control,
    name: formFields.organizationId,
  });
  const [searchValue, setSearchValue] = useState("");
  const { data: executives } = useGetResponsibleOrganizationsUsersQuery({ searchValue:searchValue,filters:facility ?`&organizationId=${facility}` : "" });

  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (
    <Box className="medium_container">
      <SelectSimpleSearch
        {...props}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        options={options}
      />
    </Box>
  );
};
