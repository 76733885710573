import React, { useEffect } from 'react';
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { formFields } from "./form-config";
import { IntegrationNumbersConfig, FloorsConfig, templateIntnumber } from "./form-config.jsx";

import FormItem from "../../../components/FormItem";
import { useTranslation } from 'react-i18next';

const IntNumbersFields = ({ control, errors, rules, setValue }) => {
  const { t } = useTranslation();

  const { fields: intNumFields, append, remove } = useFieldArray({
    control,
    name: `buildingPassport.${formFields.protectionObjectIntegrNumbersAlts}`,
  });


  return (
    <Box>
      <Typography sx={{marginBottom:"20px"}}>Интеграционные номера</Typography>

      {intNumFields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            flexBasis: "80%",
            display: "grid",
            gridTemplateColumns: "9fr 1fr",
            gridColumnGap: "20px",
            alignItems: "flex-start",
            borderBottom: "1px solid var(--borderLightGrey)",
            ".error-text": {
              whiteSpace: "normal"
            }
          }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}>
            {IntegrationNumbersConfig.map(({ name, label, input, type, ...rest }) => (
              <FormItem
                key={`buildingPassport.${[formFields.protectionObjectIntegrNumbersAlts]}.${index}.${name}`}
                control={control}
                errors={errors}
                name={`buildingPassport.${[formFields.protectionObjectIntegrNumbersAlts]}.${index}.${name}`}
                label={label}
                input={input}
                rules={rules}
                type={type}
                setValue={setValue}
                {...rest}
              />
            ))}
          </Box>

          <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            
              <Button
                className="btn btn-remove btn-basket-small"
                onClick={() => remove(index)}
              >
                <DeleteForeverIcon />
              </Button>
           
          </Box>

        </Box>
      ))}

      <Box sx={{ alignSelf: "center" }}>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => append(templateIntnumber)}
        >
          {t("button:add")}
        </Button>
      </Box>
    </Box>
  );
};

export default IntNumbersFields;