import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { TextField } from "@mui/material";

import { dateFormatForSubmit } from "../../../constants";

export const YearPickerField = ({
  label,
  type,
  onChange,
  error,
  value,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const style = useMemo(() => {
    return error
      ? {
          width: "100%",
          "& .MuiInputLabel-root": {
            color: "#d32f2f",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#d32f2f",
            },
            "&:hover fieldset": {
              borderColor: "#d32f2f",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#d32f2f",
            },
          },
          "& .css-1xhypcz-MuiStack-root": {
            padding: 0,
          },
        }
      : {
          width: "100%",
          "& .css-1xhypcz-MuiStack-root": {
            padding: 0,
          },
        };
  }, [error]);

  const handleChange = (e) => {
    if (!e) {
      onChange(null);
      return;
    }
    const date = e.$d;
    onChange(dayjs(date).format(dateFormatForSubmit));
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ru"
      localeText={
        ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          openTo="year"
          views={["year"]}
          className="date-picker"
          type={type}
          sx={{ ...style, padding: 0 }}
          label={t(label)}
          format={"YYYY"}
          onChange={handleChange}
          value={value ? dayjs(value) : null}
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              sx={{ padding: 0 }}
              {...params}
              helperText={
                error?.message ? t(`error_message:${error?.message}`) : ""
              }
              {...rest}
            />
          )}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
