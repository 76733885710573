// libs imports
import React from "react";
import InputText from "../../../components/InputText";
import { AFATypeOptions } from "./constants";
import SelectSimple from "../../../components/SelectSimple";
import DatePickerField from "../../../components/DatePickerField";
import { SelectMultipleComposedCommission, SelectSimpleObjectNameAndAddress } from "./Components";
import AfaActWorkingCorrectlyCheckForm from "./AfaActWorkingCorrectlyCheckForm";

export const formFields = {
  comission: "comission",
  protectionObjectId: "protectionObjectId",
  workingProject: "workingProject",
  afaSystemKind: "afaSystemKind",
  cableBrand: "cableBrand",
  afaSystemManufacturer: "afaSystemManufacturer",
  installationDate: "installationDate",
  organizationType: "organizationType",
  organizationName: "organizationName",
  autoFireAlarmSysType:"autoFireAlarmSysType",
 // ogrnNumber: "ogrnNumber",
 AutoFireAlarmSysInstallationDate:"AutoFireAlarmSysInstallationDate",
  objectNameAndAddress: "objectNameAndAddress",
  systemProject: "systemProject",
  AFAType: "AFAType",
  AFAInstallationDate: "AFAInstallationDate",
  AFAManufacturer: "AFAManufacturer",
  cableMark: "cableMark",
  installationOrganization: "installationOrganization",
  performanceTests: "performanceTests",
};

export const createAfaActFormSections = [
  {
    id: 1,
    label: "label:afa_act_header_accordion",
    items: [
      {
        name: formFields.comission,
        label: "label:composedCommission",
        type: "select-multi",
        rules: {
            required: true,
            validate: (value, formValues) => value?.length >= 3,
        },
        input: (props) => <SelectMultipleComposedCommission {...props} />,
      },
      {
        name: formFields.protectionObjectId,
        label: "label:objectNameAndAddress",
        type: "select-text",
        rules: { required: true },
        input: (props) => <SelectSimpleObjectNameAndAddress {...props} />,
      },
      {
        name: formFields.workingProject,
        label: "label:systemProject",
        type: "text",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.autoFireAlarmSysType,
        label: "label:AFAType",
        type: "select-text",
        flexBasis: "25%",
        options: AFATypeOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.cableBrand,
        label: "label:cableMark",
        type: "text",
        flexBasis: "74%",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.afaSystemManufacturer,
        label: "label:AFAManufacturer",
        type: "text",
        flexBasis: "74%",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.AutoFireAlarmSysInstallationDate ,
        label: "label:AFAInstallationDate",
        type: "date",
        flexBasis: "25%",
        sx:{padding:"2px 0 0  0 "},
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.organizationName,
        label: "label:installationOrganization",
        type: "text",
       
        input: (props) => <InputText {...props} />,
      },
      /*{
        name: formFields.ogrnNumber,
        label: "label:ogrnNumber",
        type: "number",
        flexBasis: "26%",
        rules: {
          required: false,
          maxLength: (v) => v?.length <= 13,
          validate: (value, formValues) => value?.length === 13 || !value,
        },
        input: (props) => <InputText {...props} />,
      },*/
    ],
  },
  {
    id: 2,
    label: "label:afa_act_working_сorrectly_check_accordion",
    items: [
      {
        id: 5,
        name: formFields.performanceTests,
        CustomFormItem: AfaActWorkingCorrectlyCheckForm,
      },
    ],
  },
];
